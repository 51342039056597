import {validateUpdateForm} from "../lib/validation";
import {strapi} from "../lib/auth";
import {Service} from "../lib/strapi";

const entity = "forms";

const service = new Service(entity);

export const findAndCount = service.findAndCountServerSide;

export const find = (filters = {}, start = 0, limit = 20, sortField = "id", sortDir = "desc") => {

    const withSort = Object.assign(filters, {
        "_sort":`${sortField}:${sortDir.toUpperCase()}`,
        "_limit": limit,
        "_start": start
    });
    return service.getEntries(withSort)
};

export const create = async (data) => {
    const validationError = validateUpdateForm(data.label, data.key, data.project);
    if (validationError) return Promise.reject(validationError);
    return service.createEntry(data);
};

export const read = async id => strapi.getEntry("forms", id);
export const getEntry = service.getEntry;

export const update = async (id, data) => {
    const validationError = validateUpdateForm(data.label, data.key, data.project);
    if (validationError) return Promise.reject(validationError);
    if(!data.sitekey && !data.secretkey) {
        data.sitekey = null;
        data.secretkey = null;
    }
    return service.updateEntry(id, data);
};

export const createBackup = async (_id, data) => {
    return await strapi.request("post", `/${entity}/createBackup/${_id}`, {
        data
    });
};

export default {createBackup, create, read, update, getEntry};