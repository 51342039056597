import React from 'react';
import '../statistics/statistics.css';
import {Nav, NavItem, NavLink} from "reactstrap";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import Card from "../../generic/Card";
import Table from './Table'

const SeamlessValidationLogs = () => {
    const location = useLocation();
    const history = useHistory();
    const activeTab = /\/(\w+)\/?$/.exec(location.pathname)[1];

    return <Card title={"Validation logs"}>
        <Nav tabs>
            <NavItem>
                <NavLink className={activeTab === 'validationlogs' ? "active" : ""}
                         onClick={() => history.push(`/seamless/validationlogs`)}
                         href="#">
                    Orders
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={activeTab === 'accounts' ? "active" : ""}
                         onClick={() => history.push(`/seamless/validationlogs/accounts`)}
                         href="#">
                    Accounts
                </NavLink>
            </NavItem>
        </Nav>
        <Switch>
            <Route path="/seamless/validationlogs" component={() => <Table type="orders" />} exact/>
            <Route path="/seamless/validationlogs/accounts" component={() => <Table type="accounts" />}/>
        </Switch>
    </Card>
};

export default SeamlessValidationLogs;