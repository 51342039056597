import React from "react";
import DataTable, {field} from "../../generic/DataTable";
import {getTerms, deleteTerms} from "../../../service/terms";
import format from "../../../lib/format";

export default function Table(props) {
    const {openPopup, setEditMode, setEditPopupData, setReload, reload, filters} = props;

    return (
        <DataTable
            filters={filters}
            onDelete={response => deleteTerms(response.id).then(() => setReload())}
            onChange={response => {
                openPopup();
                setEditMode(true);
                setEditPopupData(response)
            }}
            reload={reload}
            findAndCount={getTerms}
            fields={[
                field("Code", false, "code"),
                field("Version", false, "version"),
                field("Date", false, "createdAt", res => format.datetime(res.createdAt).slice(0, 10))
            ]}>
            name={"terms"}
        </DataTable>
    );
}
