import {Service, apiUrl} from '../lib/strapi';
import {strapi} from "../lib/auth";

const entity = "upload/files";
const service = new Service(entity);
export const getData = service.findAndCount;

export const deleteEntry = service.deleteEntry;

export const upload = (file) => {
    const form = new FormData();
    form.append('files', file);
    return strapi.upload(form);
}

export const downloadLink = url => `${apiUrl}${url}`;