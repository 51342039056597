import React, {useEffect, useState} from "react";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {insertNewSMSTemplate, changeTemplateOrType} from "../../../service/smsTemplate";
import {Config} from 'lead-generation-common';
const {communication: {smsGate}} = Config;
const defaultGate = smsGate.SMSTraffic;

export default function CEComponent(props) {
    const { isOpen, closePopup, setReload, isEditMode, editPopupData } = props;
    const [templateCode, setTemplateCode] = useState(undefined);
    const [templateText, setTemplateText] = useState(undefined);
    const [label, setLabel] = useState(undefined);
    const [gate, setGate] = useState(undefined);

    useEffect(() => {
        if(editPopupData) {
            const { smsTemplateCode, smsTemplateText, gate } = editPopupData;

            setTemplateCode(smsTemplateCode);
            setTemplateText(smsTemplateText);
            setGate(gate);
        }
        if(!isEditMode) {
            deleteData();
        }
    }, [editPopupData])

    useEffect(() => setGate(defaultGate), [])

    const handleTemplateTextChange = e => setTemplateText(e.target.value);
    const handleTemplateCodeChange = e => setTemplateCode(e.target.value);
    const handleLabelChange = e => setLabel(e.target.value);
    const handleGateChange = gate => setGate(gate);

    const onSave = async () => {
        if(isEditMode) {
            await changeTemplateOrType(editPopupData.id, templateCode, templateText, gate, label);
        } else {
            await insertNewSMSTemplate(templateCode, templateText, gate, label);
        }

        deleteData();
        setReload();
        closePopup();
    }

    const deleteData = () => {
        setTemplateText(undefined);
        setTemplateCode(undefined);
        setLabel(undefined);
        setGate(defaultGate);
    }

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader>{!isEditMode ? "Create new" : "Update existing"}</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                      <Label for="gate">Gate</Label>
                      <Input type="select" name="gate" className="mb-2" id="gate" value={gate} onChange={e => handleGateChange(e.target.value)}>
                          <option value={smsGate.SMSTraffic}>SMS Traffic (uCRM CC)</option>
                          <option value={smsGate.SMSC}>SMSC (RRP)</option>
                      </Input>
                    </FormGroup>
                    <FormGroup>
                        <Label for="smsTemplateCode">Label</Label>
                        <Input type="text" name="label" id="label" className="mb-2" placeholder="Enter label..." value={label} onChange={handleLabelChange}/>
                    </FormGroup>
                    <FormGroup>
                      {
                        (gate === 'SMSTraffic') && <div>
                          <Label for="smsTemplateCode">SMS Template Code</Label>
                          <Input type="textarea" rows="2" name="smsTemplateCode" id="smsTemplateCode" className="mb-2" placeholder="Enter SMS template code..." value={templateCode} onChange={handleTemplateCodeChange}/>
                          </div>
                      }
                      {
                        (gate === 'SMSC') && <div>
                          <Label for="smsTemplateText">SMS Template Text</Label>
                          <Input type="textarea" rows="7" name="smsTemplateText" id="smsTemplateText" className="mb-2" placeholder="Enter SMS template text..." value={templateText} onChange={handleTemplateTextChange}/>
                          </div>
                      }
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => {
                    onSave();
                }}>Save</Button>
                <Button color="secondary" onClick={() => {
                    deleteData()
                    closePopup();
                }}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
}