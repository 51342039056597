export const validateCredentials = (email, password) => {
    if (!email || !password) {
        return ("Email and password fields are required");
    }

    if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return "Email does not have right format.";
    }

    return null;
};

export const validatePasswordChange = (password, confirm) => {

    if (password === "" || !password) return "Please enter password";
    if (password !== confirm) return "Password mismatch";

    return null;

};


export const validateCreateUser = (username, email, password, confirmPassword, roleId) => {
    const emptyError = [];
    if(!username || username === "") emptyError.push("username");
    if(!email || email === "") emptyError.push("email");
    if(!password || password === "") emptyError.push("password");
    if(!roleId || roleId === 'choose') emptyError.push("role");
    if(emptyError.length > 0) return "Please enter " + emptyError.join(", ");
    return validatePasswordChange(password, confirmPassword);
};

export const validateUpdateUser = (username, email, password, confirmPassword, roleId) => {
    const emptyError = [];
    if(!username || username === "") emptyError.push("username");
    if(!email || email === "") emptyError.push("email");
    if(!roleId || roleId === 'choose') emptyError.push("role");
    if(emptyError.length > 0) return "Please enter " + emptyError.join(", ");
    if(password && password !== "") validatePasswordChange(password, confirmPassword);
    else return null;
};

export const validateUpdateForm = (label, key, project)=>{
    const emptyError = [];
    if(!label || label === "") emptyError.push("label");
    if(!key || key === "") emptyError.push("key");
    if(!project || project === "") emptyError.push("project");
    if(emptyError.length > 0) return "Please enter " + emptyError.join(", ");
    else return null;
};

export const validateUploadCities = (file) => {
    const emptyError = [];
    if(!file) emptyError.push("file");
    if(emptyError.length > 0) return "Please choose " + emptyError.join(", ");
    else return null;
};

export const validateUploadWeekends = (file) => {
    const emptyError = [];
    if(!file) emptyError.push("file");
    if(emptyError.length > 0) return "Please choose " + emptyError.join(", ");
    else return null;
};

export const validateProject = project => {
    const emptyError = [];
    const {label, key} = project;
    if(!label || label === "") emptyError.push("label");
    if(!key || key === "") emptyError.push("key");
    if(emptyError.length > 0) return "Please enter " + emptyError.join(", ");
    else return null;
};

export const validatePage = page => {
    const emptyError = [];
    const {label, key, project} = page;
    if(!label || label === "") emptyError.push("label");
    if(!key || key === "") emptyError.push("key");
    if(!project || project === "") emptyError.push("project");
    if(emptyError.length > 0) return "Please enter " + emptyError.join(", ");
    else return null;
};

export const validateFunction = page => {
    const emptyError = [];
    const {name, type, content} = page;
    if(!name) emptyError.push("name");
    if(!type) emptyError.push("type");
    if(!content) emptyError.push("content");
    if(emptyError.length > 0) return "Please enter " + emptyError.join(", ");
    else return null;
};

export const validatePasswordDifficulty = (password) => {
    return new RegExp("(?=.{9,})((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\\d)(?=.*[a-zA-Z])(?=.*[\\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W_])).*").test(password);
};