import React from "react";
import PrivateRoute from '../../../../route/PrivateRoute';
import {SubMenuLink} from '../../../../components/generic/links';
import {Switch} from "react-router-dom";
import APIKeysUser from "./APIKeysUser";
import EditUser from './Edit'
import {getUserFromSession} from "../../../../lib/auth";

class Index extends React.Component {
    render() {
        const role = getUserFromSession().role;
        return (
            <div>
                <ul className="nav nav-tabs">
                    <SubMenuLink to={(this.props.match.params.id) ? `/administration/user/edit/${this.props.match.params.id}` : '/administration/user/edit'} {...this.props}>User</SubMenuLink>
                    { (this.props.match.params.id && ((role === 'Administrator') || (role === 'Profile manager'))) && <SubMenuLink to={`/administration/user/api/${this.props.match.params.id}`} {...this.props}>API keys</SubMenuLink> }
                </ul>
                <Switch>
                    <PrivateRoute exact path="/administration/user/edit" component={EditUser}/>
                    <PrivateRoute path="/administration/user/edit/:id" component={EditUser}/>
                    <PrivateRoute path={`/administration/user/api/:id`} component={APIKeysUser} />
                </Switch>
            </div>
        )
    }
}

export default Index;