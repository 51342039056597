import React, {useState} from 'react'
import Table from "./Table";
import TableCard from "../../generic/TableCard";
import Upload from "./Upload";
import TextSearch from "../../generic/field/TextSearch";
import ClearButton from "../../generic/field/ClearButton";
import {StringParam, useQueryParam} from "use-query-params";

export default function AdministrationWhitelist(props) {

    const [popupState, setPopupState] = useState(false);
    const [reload, setReload] = useState(undefined);
    const [name, setName] = useQueryParam('name', StringParam);
    return (
        <TableCard title={"File upload"}>
            <Upload isOpen={popupState} onSave={() => {
                setPopupState(false);
                setReload(!reload);
            }} onClose={() => {
                setPopupState(false);
            }}/>
            <div className="form-inline mt-2">
                <button className="btn btn-primary mr-2 " onClick={() => {
                    setPopupState(true)
                }}>
                    <i className="fa fa-plus"/>
                    &nbsp; Upload
                </button>
                <TextSearch value={name} className="mr-2 form-control" onSearch={setName}
                            placeholder="Search..."/>
                <ClearButton className="btn" onClick={() => {
                    setName(undefined);
                }}/>
            </div>
            <div className="row">
                <div className={"pt-3 col"}>
                    <Table reload={reload} name={name}/>
                </div>
            </div>
        </TableCard>
    );
};
