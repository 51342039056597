import React from 'react'
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/theme-xcode";
import * as PropTypes from "prop-types";


export default function Forms({state, dispatch, readOnly}) {
    return (
        <div style={{minHeight: "400px"}}>
            <h4 className="card-title"> Template </h4>
            <AceEditor
                width="100%"
                mode="html"
                theme="xcode"
                name="template"
                onChange={val => dispatch({
                    type:'setTemplate',
                    payload: {
                        value: val
                    }
                })}
                value={state.template}
                editorProps={{$blockScrolling: true}}
                readOnly={readOnly || false}
                setOptions={{useWorker: false}}
                tabSize={2}
            />
        </div>
    )
}

Forms.propTypes = {
    state: PropTypes.object,
    dispatch: PropTypes.func,
    readOnly: PropTypes.bool
};
