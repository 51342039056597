import React from 'react'
import Card from "../../generic/Card";
import {clearQueue, syncQueue} from "../../../service/queue";
import {Col, Row} from "reactstrap";
import {Bar} from 'react-chartjs-2';
import {chartOptions} from "./charts";
import format from "../../../lib/format";
import {Link} from "react-router-dom";
import './style.css'
import ButtonWithConfirm from "../../generic/ButtonWithConfirm";
import {showDangerToast, showSuccessToast} from "../../../lib/notify";

const QueueCard = ({queue, logsLink, tableReload}) => {
    return !queue
        ? <></>
        : <Card title={queue.label} className={"border border-secondary mt-3 mb-3"}>
            <p style={{color: 'grey'}}>{queue.description}</p>
            <Row>
                <Col sm={12} md={12} lg={2}>
                    <h4 className="font-weight-medium">Size <span className="text-primary">{queue.size}</span></h4>
                    <h4 className="font-weight-medium">Threads <span className="text-primary">{queue.threadCount}</span></h4>
                    <Link className="btn btn-primary btn-xs mr-2 mb-2"
                          to={logsLink}>
                        <i className="fa fa-book-open"/>
                        &nbsp; Logs
                    </Link>
                    <ButtonWithConfirm
                        onClick={() => clearQueue(queue.queue).then(queue => showSuccessToast(queue.message), showDangerToast)}
                        className="btn-xs mb-2 mr-2"
                        color="danger"
                        modalText="Do you want to clear queue?">
                        <i className={'fa fa-ban'}/>&nbsp;Clear
                    </ButtonWithConfirm>
                    <ButtonWithConfirm
                        onClick={() => syncQueue(queue.queue).then(queue => {
                            showSuccessToast(queue.message)
                            tableReload(reload => !reload);
                        }, showDangerToast)}
                        className="btn-xs mb-2"
                        color="success"
                        modalText="Do you want to refresh queue size?">
                        <i className={'fa fa-sync'}/>&nbsp;Refresh
                    </ButtonWithConfirm>
                </Col>
                <Col sm={12} md={6} lg={2}>
                    <p className="text-center text-secondary">Daily size</p>
                    <Bar
                        data={{
                            datasets: [{
                                label: "",
                                backgroundColor: 'rgba(0, 119, 204, 0.8)',
                                data: queue.dailySize.map(x => x.value)
                            }], labels: queue.dailySize.map(x => x.label)
                        }}
                        width={200}
                        height={100}
                        options={chartOptions}
                    />
                </Col>
                <Col sm={12} md={6} lg={2}>
                    <p className="text-center text-secondary">Daily dynamic</p>
                    <Bar
                        data={{
                            datasets: [{
                                label: "",
                                backgroundColor: 'rgba(0, 160, 0, 0.8)',
                                data: queue.dailyDynamic.map(x => x.success)
                            }, {
                                label: "",
                                backgroundColor: 'rgba(250, 0, 0, 0.8)',
                                data: queue.dailyDynamic.map(x => -x.failure)
                            }
                            ],
                            labels: queue.dailyDynamic.map(x => x.label)
                        }}
                        width={200}
                        height={100}
                        options={chartOptions}
                    />
                </Col>
                <Col sm={12} md={6} lg={2}>
                    <p className="text-center text-secondary">Hourly size</p>
                    <Bar
                        data={{
                            datasets: [{
                                label: "",
                                backgroundColor: 'rgba(0, 119, 204, 0.8)',
                                data: queue.hourlySize.map(x => x.value)
                            }], labels: queue.hourlySize.map(x => x.label)
                        }}
                        width={200}
                        height={100}
                        options={chartOptions}
                    />
                </Col>
                <Col sm={12} md={6} lg={2}>
                    <p className="text-center text-secondary">Hourly dynamic</p>
                    <Bar
                        data={{
                            datasets: [{
                                label: "",
                                backgroundColor: 'rgba(0, 160, 0, 0.8)',
                                data: queue.hourlyDynamic.map(x => x.success)
                            }, {
                                label: "",
                                backgroundColor: 'rgba(250, 0, 0, 0.8)',
                                data: queue.hourlyDynamic.map(x => -x.failure)
                            }
                            ],
                            labels: queue.hourlyDynamic.map(x => x.label)
                        }}
                        width={200}
                        height={100}
                        options={chartOptions}
                    />
                </Col>
                <Col sm={12} md={12} lg={2}>
                    <h4 className="font-weight-medium">Last launch</h4>
                    <h5 className="font-weight-light text-primary">{format.datetimeFull(queue.lastLaunch)}</h5>
                    <h4 className="font-weight-medium">Last success</h4>
                    <h5 className="font-weight-light text-primary">{format.datetimeFull(queue.lastSuccess)}</h5>
                </Col>
            </Row>
        </Card>
};

export default QueueCard;