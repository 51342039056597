import React from "react";
import PropTypes from 'prop-types';

class Card extends React.Component {
    render() {
        const {children, title} = this.props;
        return (
            <div className="">
                <div className={'card ' + this.props.className}>
                    <div className="card-body">
                        {title && <h3 className="card-title">{title}</h3>}
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

Card.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
};

Card.defaultProps = {
  className: ""
};

export default Card;