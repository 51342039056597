import React, {useEffect, useState} from "react";
import {createEntry, getEntry, updateEntry} from "../../service/page";
import * as notify from "../../lib/notify";
import {Redirect} from "react-router-dom";
import {validatePage} from '../../lib/validation';
import LoaderOverlay from "../generic/LoaderOverlay";
import Card from "../generic/Card";
import ProjectInput from "../generic/field/ProjectInput";
import {apiUrl} from "../../lib/strapi";
import {Col, Nav, NavItem, NavLink, Row, TabPane} from "reactstrap";
import EditorPane from "../generic/EditorPane";
import Tags from "../generic/field/Tags";
import {Config} from "lead-generation-common";
import makeCancelable from "../../lib/makeCancelable";
import RedirectConfig from "./features/Redirect";
import NoSharing from "./features/NoSharing";
import random from "../../lib/random";
import CopyLink from "../generic/CopyLink";
import util from "./util/page";
import Copy from "../generic/Copy";

const {page: {tag, tags}} = Config;

/**
 *
 * @type {({exclude?: [string], name: string, render: (function(*, *, *): Node), tags: [string]})[]}
 */
const tabsTagsConfig = [
    {
        name: 'HTML',
        tags: [tag.HTML],
        exclude: [tag.Redirect],
        render: (item, handleFieldChange) =>
            <EditorPane html={item.htmlTemplate} css={item.cssTemplate} js={item.jsTemplate} onChange={(mode, value) => handleFieldChange(mode+'Template', value)}/>
    },
    {
        name: 'Redirect',
        tags: [tag.Redirect],
        render: (item, handleFieldChange, handleInputChange) =>
            <RedirectConfig redirectUrl={item.redirectUrl} onChange={handleInputChange}/>
    },
    {
        name: 'No Sharing',
        tags: [tag.NoSharing],
        render: (item, handleFieldChange, handleInputChange) =>
            <NoSharing html={item.htmlScanTemplate} css={item.cssScanTemplate} js={item.jsScanTemplate} onChange={handleFieldChange}/>
    }
];

function Edit(props) {
    const [item, setItem] = useState({
        key: '',
        label: '',
        htmlTemplate: '',
        cssTemplate: '',
        jsTemplate: '',
        htmlScanTemplate: '',
        cssScanTemplate: '',
        jsScanTemplate: '',
        project: null,
        tags: [tag.HTML],
        redirectUrl: '',
        sharingCode: ''
    });

    const [redirectToTable, setRedirectToTable] = useState(false);
    const [activeTab, setActiveTab] = useState('');
    const [loading, setLoading] = useState(false);

    const itemId = props.match.params.id;

    useEffect(()=>{
        const cancellable = makeCancelable(loadData());
        return () => cancellable.cancel();
    }, [itemId]);

    async function loadData() {
        if(!itemId) return;
        setLoading(true);
        return getEntry(itemId).then(item => setItem(item))
            .catch(e => {
                notify.showDangerToast("Error loading page id: " + itemId);
                setRedirectToTable(true);
            })
            .finally(() => setLoading(false));
    }

    //Calculate sharingCode if empty
    useEffect(()=>{
        if(hasTag(tag.NoSharing) && !item.sharingCode) handleFieldChange('sharingCode', random.string(6));
    }, [item]);

    function hasTag(tag) {
        return item.tags && item.tags.includes(tag);
    }

    const targetUrl = util.url(item);

    function onSave(isClose = true) {
        return () => {
            const validation = validatePage(item);
            if (validation) return notify.showDangerToast(validation);

            setLoading(true);

            (itemId ? updateEntry(itemId, item) : createEntry(item)).then(() => {
                setRedirectToTable(isClose);
                notify.showSuccessToast(`Page '${item.label}' ${itemId ? 'updated' : 'created'}`);
            }, (e) => {
                if (e.message !== "An internal server error occurred")
                    notify.showDangerToast(e.message || e);
                else
                    notify.showDangerToast("Probably, form with same key already exists")
            }).finally(() => setLoading(false));
        }
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        handleFieldChange(name, value);
    }

    function handleFieldChange(name, value) {
        item[name] = value;
        setItem({...item});
    }

    const isUpdate = !!itemId;

    const tabs = tabsTagsConfig.filter(tab => tab.tags.some(x => hasTag(x))).filter(tab => !tab.exclude || !tab.exclude.some(x => hasTag(x)));

    const activeTabOrFirst = activeTab || (tabs[0] && tabs[0].name);

    if (redirectToTable) return <Redirect push to='/pages'/>;

    return <Card title={isUpdate ? 'Edit page' : 'Create page'}>
        <LoaderOverlay isVisible={loading}>
            <Row>
                <Col sm={12} md={12} lg={5} xl={2}>
                    <div className="form-group">
                        <label htmlFor="Project">Project</label>
                        <ProjectInput selectedItemId={item.project && item.project._id}
                                      onSelect={value => handleInputChange({target: {name: "project", value}})}/>
                    </div>
                </Col>
                <Col sm={12} md={12} lg={7} xl={3}>
                    <div className="form-group">
                        <label>Label</label>
                        <input
                            type="text"
                            className="form-control"
                            name="label"
                            placeholder="Label"
                            value={item.label}
                            onChange={handleInputChange}
                            autoComplete={"false"}
                        />
                    </div>
                </Col>
                <Col sm={12} md={12} lg={5} xl={2}>
                    <div className="form-group">
                        <label htmlFor="Key">URL Key</label>
                        <input
                            id="Key"
                            type="key"
                            className="form-control"
                            name="key"
                            placeholder="Key"
                            value={item.key}
                            onChange={handleInputChange}
                            autoComplete="false"
                        />
                    </div>
                </Col>
                <Col sm={12} md={7} lg={7} xl={5}>
                    <div className="form-group">
                        <label htmlFor="Key">
                            <CopyLink href={targetUrl} text={"URL"}/></label>
                        <input
                            id="Key"
                            type="key"
                            className="form-control"
                            name="key"
                            placeholder="Key"
                            value={targetUrl}
                            disabled={true}
                            autoComplete="false"
                        />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col md={12} lg={9}>
                    <Nav tabs className="mb-3">
                        {tabs.map(tab => <NavItem key={`field-tabs-${tab.name}`}>
                            <NavLink className={activeTabOrFirst === tab.name && "active" ? "active" : ""}
                                     onClick={() => setActiveTab(tab.name)}>
                                {tab.name}
                            </NavLink>
                        </NavItem>)}
                    </Nav>

                        {tabs.filter(tab => tab.name === activeTabOrFirst).map(tab =>
                            <TabPane key={tab.name} tabId={tab.name}>
                                {tab.render(item, handleFieldChange, handleInputChange)}
                            </TabPane>
                        )}
                </Col>
                <Col md={12} lg={3} className={"jumbotron"}>
                    <h4 className="card-title">Information</h4>
                    <p className="card-description">Parameters available</p>
                    <ul className="list-arrow">
                        <li><b><Copy>%%API_URL%%</Copy></b><br/>server address. {apiUrl}</li>
                        <li><b><Copy>%%PAGE_URL%%</Copy></b><br/>current page url</li>
                        {(hasTag(tag.DTERegistration) || hasTag(tag.Statistics)) &&
                          <li><b><Copy>%%VISITOR_ID%%</Copy></b><br/>unique visitor id in cookie to distinguish visits/users</li>
                        }
                        {(hasTag(tag.DTEIframe) || hasTag(tag.DTERegistration)) &&
                          <li><b><Copy>%%CONTACT_ID%%</Copy></b><br/>DTE ContactID - seller code</li>
                        }
                    </ul>
                </Col>
            </Row>

            <div className="form-group">
                <label>Tags</label>
                <Tags value={item.tags} onChange={v => handleFieldChange('tags', v)} options={tags}/>
            </div>

            {isUpdate && <button className="btn btn-primary mr-2" onClick={onSave(false)}
                                 type="button">Update</button>}

            <button className="btn btn-primary mr-2" onClick={onSave()}
                    type="button">{isUpdate ? 'Update, close' : 'Create'}</button>

            <button className="btn btn-light mr-2" onClick={() => setRedirectToTable(true)}
                    type="button">Cancel
            </button>

            {isUpdate && <a className="btn btn-outline-primary"  rel="noopener noreferrer"  target="_blank" href={targetUrl}>View</a>}
        </LoaderOverlay>
    </Card>
}

export default Edit;