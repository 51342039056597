import React from 'react';
import {useParams} from "react-router";
import Card from "../../../generic/Card";
import {Nav, NavItem, NavLink} from "reactstrap";
import {Route, useHistory, useLocation, Switch} from "react-router-dom";
import Logs from "./Logs";
import Data from "./Data";

function Order() {
    const {orderId} = useParams()
    const location = useLocation();
    const history = useHistory();
    const activeTab = /\/(\w+)\/?$/.exec(location.pathname)[1];

    return (
        <Card title={'Order: ' + orderId}>
            <Nav tabs>
                <NavItem>
                    <NavLink className={ activeTab === orderId ? "active" : ""}
                             onClick={() => history.push(`/seamless/orders/${orderId}`)}
                             href="#">
                        Customer
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={ activeTab === 'logs' ? "active" : ""}
                             onClick={() => history.push(`/seamless/orders/${orderId}/logs`)}
                             href="#">
                        Queue Logs
                    </NavLink>
                </NavItem>
            </Nav>
            <Switch>
                <Route path={`/seamless/orders/:orderId`} component={Data} exact/>
                <Route path={`/seamless/orders/:orderId/logs`} component={Logs}/>
            </Switch>
        </Card>
    )
};

export default Order;