
const chartOptions = {
    bezierCurve:false,
    scaleShowLabels : false,
    tooltipEvents:[],
    pointDot : false,
    scaleShowGridLines: false,
    legend: false,
    scales: {
        yAxes: [{
            ticks: {
                fontSize: 8,
                maxTicksLimit:5,
            },
            stacked: true

        }],
        xAxes: [{
            ticks: {
                fontSize: 8,
                autoSkip: true,
                maxRotation: 0,
                minRotation: 0,
                maxTicksLimit:12,
            },
            stacked: true

        }]
    },
    tooltips: {
        enabled: false
    }
};
export {chartOptions}