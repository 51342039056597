import React from 'react'
import {Route, Switch,} from 'react-router-dom';
import Index from "./Index";
import Edit from "./Edit/Route"

export default class IndexRoute extends React.Component {
    render() {
        return (<Switch>
            <Route exact path="/administration/user" component={Index} />
            <Route path="/administration/user/edit/:id" component={Edit} />
            <Route exact path="/administration/user/edit" component={Edit} />
            <Route path="/administration/user/api/:id" component={Edit} />
        </Switch>);
    }
};