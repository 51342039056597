import React, {Fragment} from 'react';
import {apiUrl} from "../../../lib/strapi";
import PropTypes from "prop-types";

export default function Sidebar(props) {

    const key = props.formKey || "<enter key>";

    return <Fragment>
        <h4 className="card-title">Form links</h4>
        <ul className="list-arrow">
            <li>JSON form description<br/>
                <a href={`${apiUrl}/main/${key}`} rel="noopener noreferrer"
                   target="_blank">{`/main/${key}`}</a>
            </li>
            <li>HTML form template<br/>
                <a href={`${apiUrl}/template/${key}`} rel="noopener noreferrer"
                   target="_blank">{`/template/${key}`}</a>
            </li>
        </ul>
        {props.activeTab === 'Integrate' && (
            <div>
                <h4 className="card-title">Integration</h4>
                <ul className="list-arrow">
                    <li>Mapper Function (JS)<br/>
                        <p>
                            Function <i>params</i> - <b>data: {'{}'}</b>
                            <br/>data.keys are field keys in origin form
                            <br/>data.values are field values from submission
                            <br/><i>e.g. to access firstName - data.firstName</i>
                            <br/>
                            <br/>Function <b><i>returns</i></b>: <b>mapper: {'{}'}</b>
                            <br/> <b>mapper</b>.keys are field keys in <b>destination</b>
                            <br/> <b>mapper</b>.values are values to pass
                            <br/> mapper <b>must be assigned</b> manually
                            <br/><i>e.g. mapper = {'{newKey: val}'}</i>
                            <br/> Function code <b>should not</b> contain implicit return

                        </p>
                    </li>
                    <li>Form Fields<br/>
                        {props.state.fields.map(field => <p key={field.key}>Key: {field.key}</p>)}
                    </li>
                </ul>
            </div>
        )}
    </Fragment>
}

Sidebar.propTypes = {
    formKey: PropTypes.string,
    activeTab: PropTypes.string
};