import React, {useState, useEffect} from "react";
import DataTable, {field} from "../../generic/DataTable";
import {getFraudConfig, deleteEntry} from "../../../service/anti-fraud-config";
import PropTypes from 'prop-types';
import { pickBy } from "lodash";

function Table(props) {
    const [reload, setReload] = useState(false);
    useEffect(() => setReload(!reload), [props.reload]);
    let fields = [];

    if (props.name === 'config') {
        fields = [
            field("FormKey", false, "formKey"),
            field("CellId", false, "cellId"),
            field("Type", false, "type"),
            field("Face Log", false, "verifyFace")
        ]
    } else {
        fields = [
            field("Project", false, "project"),
            field("Type", false, "type")
        ]
    }

    return (
        <DataTable
            onDelete={(res) => deleteEntry(res.id).then(() => setReload(!reload))}
            onChange={(res) => {
                props.onChange(res);
            }}
            reload={reload}
            findAndCount={getFraudConfig}
            fields={fields}
            filters={pickBy({
                kind: props.name
            })}
            name={props.name}
        >
        </DataTable>
    );
}


Table.propTypes = {
    onChange: PropTypes.func.isRequired,
    reload: PropTypes.any
};

export default Table;