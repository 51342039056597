import React from 'react'
import TableCard from "../generic/TableCard";
import Table from "./Table";
import {Redirect} from "react-router-dom";
import {deleteEntry} from "../../service/page";
import * as notify from "../../lib/notify";
import ProjectInput from "../generic/field/ProjectInput";
import ClearButton from "../generic/field/ClearButton";

export default class IndexRoute extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectToCreate: false,
            redirectToEditItemId: null,
            reload: false,
            filterProjectId: null
        };

        this.onChange = this.onChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
    };

    onChange(res) {
        this.setState({redirectToEditItemId: res.id})
    }

    onDelete(deleteItem) {
        const {reload} = this.state;
        deleteEntry(deleteItem.id)
            .then(() => {
                this.setState({reload: !reload});
                notify.showSuccessToast(`Page '${deleteItem.label}' was deleted`)
            })
            .catch(notify.showDangerToast);
    }

    render() {
        const {reload, redirectToCreate, redirectToEditItemId, filterProjectId} = this.state;

        if (redirectToCreate) return <Redirect push to='/pages/edit'/>;
        if (redirectToEditItemId) return <Redirect push to={'/pages/edit/' + redirectToEditItemId}/>;

        const filters = {};
        if(filterProjectId) filters.project = filterProjectId;

        return <TableCard title={"Pages"}>
            <form className="form-inline">
                <button className="btn btn-primary mb-2 mr-2" onClick={() => this.setState({redirectToCreate: true})}>
                    <i className="fa fa-plus"/>
                    &nbsp; Add Page
                </button>

                <ProjectInput className="mb-2 mr-2" selectedItemId={filterProjectId} onSelect={filterProjectId => this.setState({filterProjectId})}/>

                <ClearButton className="mb-2" onClick={() => this.setState({filterProjectId: null})}/>
            </form>

            <Table reload={reload} onDelete={this.onDelete} onChange={this.onChange} filters={filters}/>
        </TableCard>
    }
};