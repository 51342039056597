import React, {Fragment, useState} from "react";
import PropTypes from "prop-types";
import {Button, Col, FormGroup, Label, Row} from "reactstrap";
import {Config} from "lead-generation-common";

function Deeplink({enabled, form: {register, errors, setValue, watch}}) {

    const createWatch = watch("deeplink.create");
    const createLinkCodeWatch = watch("deeplink.createLinkCode");
    const [editCode, setEditCode] = useState(false);
    const esbProjectWatch = watch("esbProject");

    const isEditCode = editCode || !!createLinkCodeWatch;
    const defaultLinkCode = esbProjectWatch && Config.esb.projectToAutologinLinkCode[esbProjectWatch];

    return <Fragment>
        <Row>
            <Col md={12} lg={9}>
                <p>Deeplink (hashlink, autologin link) allows consumer to login into a website using a unique personal link. A deeplink has limited time and usage.</p>
                <FormGroup check={true}>
                    <Label check={true}>
                        <input type="checkbox" className="form-check-input form-check-input-lg" name="deeplink.login" ref={register} />
                        Login by a deeplink
                        <i className="input-helper"/>
                    </Label>

                    <small className="form-text text-muted">Allow consumer to login into website or application using deeplink</small>
                </FormGroup>
                <FormGroup check={true}>
                    <Label check={true}>
                        <input type="checkbox" className="form-check-input" name="deeplink.create" ref={register}/>
                        Create a deeplink
                        <i className="input-helper"/>
                    </Label>

                    <small className="form-text text-muted">To redirect consumer to other websites</small>
                </FormGroup>
            </Col>
        </Row>
        {createWatch === true &&

        <Row>
            <Col md={12} lg={8} xl={6}>
                <FormGroup>
                    <div className={"input-group"}>
                        <input
                            name="deeplink.createLinkCode"
                            placeholder={isEditCode ? "Enter link code" : ("Default link code: " + (esbProjectWatch && Config.esb.projectToAutologinLinkCode[esbProjectWatch]))}
                            className={`form-control form-control-sm ${(errors.deeplink && errors.deeplink.createLinkCode) ? "is-invalid" : ""}`}
                            disabled={!isEditCode}
                            ref={register}
                        />
                        <div className="input-group-append">
                            <Button color="primary" size="sm" outline={!isEditCode} onClick={() => {setEditCode(!isEditCode); setValue("deeplink.createLinkCode", isEditCode ? "" : defaultLinkCode)}}>
                                <i className="fa fa-cog"/>&nbsp;{isEditCode ? 'Reset' : 'Edit'}
                            </Button>
                        </div>
                    </div>

                    {errors.deeplink && errors.deeplink.createLinkCode && <div className="invalid-feedback">{errors.deeplink.createLinkCode.message}</div>}

                    <small className="form-text text-muted">Link code is configured in uCRM. It specifies website, where to lead consumer</small>
                </FormGroup>
            </Col>
        </Row>
        }

    </Fragment>
}

Deeplink.propTypes = {
    form: PropTypes.object.isRequired,
    enabled: PropTypes.bool.isRequired
}

export default Deeplink;