import React, {Fragment} from "react";
import DataTable, {field} from "../generic/DataTable";
import {findAndCount} from "../../service/page";
import PropTypes from "prop-types";
import format from "../../lib/format";
import {Config} from "lead-generation-common";
import util from "./util/page";
import CopyLink from "../generic/CopyLink";

const {page: {tag}} = Config;

const tagToClass = {
    [tag.HTML]: 'success',
    [tag.Redirect]: 'warning',
    [tag.NoSharing]: 'danger',
    [tag.QR]: 'info',
    [tag.Statistics]: 'info',
    [tag.DTEIframe]: 'primary',
    [tag.DTERegistration]: 'primary'
};

export default class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reload: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.reload !== this.props.reload) {
            this.setState({
                reload: !this.state.reload
            })
        }
    }

    render() {
        return <DataTable
            onDelete={this.props.onDelete}
            onChange={this.props.onChange}
            findAndCount={findAndCount}
            reload={this.state.reload}
            filters={this.props.filters}
            fields={[
                field("Project", false, "project.label"),
                field("Label", true, "label"),
                field("Key", true, "key", res => <Fragment>{res.key}<br/><small>
                    <CopyLink href={util.url(res)}/>
                </small></Fragment>),
                field("Tags", false, "tags", res => (res.tags || []).map(tag => <span key={tag} className={`mr-1 badge badge-${tagToClass[tag] || "secondary"}`}>{tag}</span>)),
                field("Created", true, "createdAt", res => format.datetime(res.createdAt)),
                field("Updated", true, "updatedAt", res => format.datetime(res.updatedAt)),
            ]}
            name={"pages"}
            >
        </DataTable>
    }
}

Table.propTypes = {
    onDelete: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    reload: PropTypes.bool,
    filters: PropTypes.object
};
