import {Service} from '../lib/strapi';
import {strapi} from "../lib/auth";

const service = new Service("whitelists");
export const getWhitelistAddresses = service.findAndCountServerSide;
export const createEntry = (IP, Type) => strapi.createEntry('whitelists', {
    IP,
    Type
});
export const updateEntry = async (id, IP, Type) => {
    const entry = {
        id,
        IP,
        Type
    };
    return strapi.updateEntry('whitelists', id, entry);
};
export const deleteEntry = id => service.deleteEntry(id);

export const deleteMultiple = ids => service.deleteMultiple(ids);