import {CopyToClipboard} from "react-copy-to-clipboard";
import * as notify from "../../lib/notify";
import React, {Fragment} from "react";
import PropTypes from "prop-types";

function Copy(props) {
    return <Fragment><span className={props.className}>{props.text || props.children}</span>
        &nbsp; <CopyToClipboard text={props.text || props.children} onCopy={() => notify.showSuccessToast("Copied to clipboard")}>
            <button className="btn btn-link p-0 mb-1"> <i className="fa fa-copy"/></button>
        </CopyToClipboard></Fragment>
}

Copy.propTypes = {
    className: PropTypes.string,
    text: PropTypes.node
};

export default Copy;