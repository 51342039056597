import React, {useState} from 'react'
import DataTable, {field} from "../../../../generic/DataTable";
import FieldTypeLabel from "./FieldTypeLabel";

export default function Table(props) {
    const [selectedRow, setSelectedRow] = useState(undefined);
    if (selectedRow) {
        (props.data.fields.filter((item, index) => {
            if (item.key === selectedRow.key) selectedRow.id = index;
            return true
        }));
    } // retrieve Id
    if (selectedRow) props.setEdit([true, selectedRow]);
    const filters = {};
    const fields = props.data.fields;
    return (
        <DataTable
            filters={filters}
            onClick={setSelectedRow}
            findAndCount={async (start, limit) => ({total: fields.length, data: fields.slice(start, start + limit)})}
            fields={[
                field("Key", false, 'key'),
                field("Name", false, "name"),
                field("Type", false, "type", res => <FieldTypeLabel field={res}/>),
                field("Placeholder", false, "placeholder"),
                field('Required', false, 'required')
            ]}
            name={"form-fields"}
        />
    )
}