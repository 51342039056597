import React from "react";
import DataTable, {field} from "../../generic/DataTable";
import {datetime} from "../../../lib/format";
import {DateParam, StringParam, useQueryParam} from "use-query-params";
import {Label} from "reactstrap";
import DatePicker from "../../generic/field/DatePicker";
import ClearButton from "../../generic/field/ClearButton";
import {getValidationLogsData} from "../../../service/validation_logs";
import FileLogsFilter from "../FileLogsFilter";
import moment from "moment";
import SeamlessPartnerSelect from "../../generic/SeamlessPartnerSelect";

const Table = ({type}) => {
    const [createdAt_gte, setCreatedAtGte] = useQueryParam('createdAt_gte', DateParam);
    const [createdAt_lte, setCreatedAtLte] = useQueryParam('createdAt_lte', DateParam);
    const [fileLogId, setFileLogId] = useQueryParam('fileLogId', StringParam);
    const [partner, setPartner] = useQueryParam('partner', StringParam);

    const clearFilters = () => {
        if (createdAt_gte) setCreatedAtGte(undefined);
        if (createdAt_lte) setCreatedAtLte(undefined);
        if (setFileLogId) setFileLogId(undefined);
        if (partner) setPartner(undefined);
    }

    const filters = {
        createdAt_gte: createdAt_gte && moment(createdAt_gte).format("YYYY-MM-DDTHH:mm:ssZ"),
        createdAt_lte: createdAt_lte && moment(createdAt_lte).clone().add(1, 'day').format("YYYY-MM-DDTHH:mm:ssZ"),
        fileLogId,
        type}
    if (partner) filters.partner_contains = partner

    return <>
        <div className="d-flex align-items-center flex-wrap mt-4">
            <div className="mr-2">
                <SeamlessPartnerSelect value={partner} onChange={setPartner} classNames={'mb-2 mr-2 h-48'} />
            </div>
            <div className="mb-2 mr-2" >
                <FileLogsFilter changeFileLogId={setFileLogId} fileLogId={fileLogId} type={type} />
            </div>
            <DatePicker placeholder={'Date from'} classNames={'form-control mb-2'}
                        onDateChange={(val) => setCreatedAtGte(val)}
                        selectedDay={createdAt_gte}
            />
            <Label size={'sm'} className={'mb-2 mr-2 ml-2'}> — </Label>
            <DatePicker placeholder={'Date to'} classNames={'form-control mb-2'}
                        onDateChange={(val) => setCreatedAtLte(val)}
                        selectedDay={createdAt_lte}
            />
            <ClearButton className="mb-2 ml-2 btn" onClick={clearFilters}/>
        </div>
        <DataTable
            filters={filters}
            findAndCount={getValidationLogsData}
            fields={[
                field("File Log Id", true, "fileLogId"),
                field("Partner", true, "partner"),
                field("Message", true, "message"),
                field("Row", true, "secureRow"),
                field("Created At", true, "createdAt", res => datetime(res.createdAt))
            ]}
            name={"validationlogs"}>
        </DataTable>
    </>
}

export default Table;
