import React from 'react'
import {getQueueData} from "../../../service/queue";
import DataList from "../../generic/DataList";
import './style.css'
import QueueCard from "./QueueCard";

const QueueConfig = () => {
    return <DataList
        findAndCount={getQueueData}
        renderer={res => <QueueCard queue={res} logsLink={`/administration/queue/logs?queue=${res.queue}`}/>}
        name={"queues"}>
    </DataList>
};

export default QueueConfig;