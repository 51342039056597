import React from 'react'
import Dropzone from 'react-dropzone';
import PropTypes from "prop-types";

class FileInput extends React.Component {
    constructor(props) {
        super(props);
        this.onDrop = this.onDrop.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.state = {
            file: this.props.file
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {file} = this.props;
        if (file !== prevProps.file) this.setState({file});
    }



    onDrop(files) {
        const file = files && files.length > 0 ? files[0] : undefined;
        this.setState({file});
        this.props.onSelect(file);
    }

    onCancel() {
        this.setState({
            file: undefined
        });
    }

    render() {

        const {file} = this.state;
        return (
            <Dropzone
                multiple={false}
                onDrop={this.onDrop.bind(this)}
                onFileDialogCancel={this.onCancel.bind(this)}
            >
                {({getRootProps, getInputProps}) => (
                    <div className={"dropify-wrapper" + (file ? " active" : "")} {...getRootProps()}>
                        <input className="dropify" {...getInputProps()} />

                        <div className="dropify-message">


                            {file ? <div>
                                    <i className="fa fa-check fa-2x"/>
                                    <p>Selected: {file.name}</p>
                                </div> :
                                <div>
                                    <i className="fa fa-upload fa-2x"/>
                                    <p>Click to select file or Drop file here</p>
                                </div>
                            }

                        </div>


                    </div>
                )}
            </Dropzone>
        );
    }
}

export default FileInput;

FileInput.propTypes = {
    onSelect: PropTypes.func.isRequired
};