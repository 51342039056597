import React from "react";
import PropTypes from "prop-types";

function Redirect(props) {
    return <div className="form-group">
        <label>URL</label>
        <input
            type="text"
            className="form-control"
            name="redirectUrl"
            placeholder="https://www.premium.one/url/"
            value={props.redirectUrl}
            onChange={props.onChange}
            autoComplete={"false"}
        />
    </div>
}

Redirect.propTypes = {
    redirectUrl: PropTypes.string,
    onChange: PropTypes.func.isRequired
}

export default Redirect;