import {Service} from "../lib/strapi";

const entity = "configs";

const service = new Service(entity);

export const findAndCount = service.findAndCountServerSide;

export const {deleteEntry, updateEntry, createEntry, getEntry} = service;

export default {findAndCount, deleteEntry, updateEntry, createEntry, getEntry};