import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import moment from "moment";
import _ from "lodash";
import {getConsumerById} from "../../../../service/consumers";
import {Row, Col} from "reactstrap";

function Data() {
    const {customerId} = useParams()
    const [customer, setCustomer] = useState({});
    useEffect(() => {
        if (customerId)
            getConsumerById(customerId).then(res => setCustomer(() => res[0]))
    }, [])

    const {_id, __v, data, externalorders, externalsubmission, status, form, ...rest} = customer;
    const fullData = {...data, ...rest};

    return (!Object.keys(customer)
            ? <></>
            : <>
                <form className="forms-sample">
                    <Row>
                        <Col xs={3}>
                            <div className="form-group">
                                <label htmlFor="Key">Updated</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={moment(customer.updatedAt).format("YYYY.MM.DD HH:mm:ss Z")}
                                    autoComplete="false"
                                    disabled={true}
                                />
                            </div>
                        </Col>
                        {
                            externalorders && <Col xs={3}>
                                <div className="form-group">
                                    <label htmlFor="Key">External orders (amt.)</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name={'extOrders'}
                                        value={externalorders.length}
                                        autoComplete="false"
                                        disabled={true}
                                    />
                                </div>
                            </Col>
                        }
                        {
                            status && <Col xs={3}>
                                <div className="form-group">
                                    <label htmlFor="Key">
                                        Status<small>{_.isObject(status) && `[queue: ${status.queue}]`}</small>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name={'status'}
                                        value={status.state || status || ''}
                                        autoComplete="false"
                                        disabled={true}
                                    />
                                </div>
                            </Col>
                        }
                        <Col>
                            <div className="form-group">
                                <label htmlFor="form">
                                    Form
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name={'status'}
                                    value={form ? form.key : ''}
                                    autoComplete="false"
                                    disabled={true}
                                />
                            </div>
                        </Col>
                        {
                            !_.isEmpty(fullData) && Object.keys(fullData).map(key => (
                                <Col xs={3}>
                                    <div className="form-group" key={key}>
                                        <label htmlFor="Key">{key}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name={key}
                                            value={fullData[key] || ""}
                                            autoComplete="false"
                                            disabled={true}
                                        />
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>


                </form>
            </>
    )
};

export default Data;