class ReferenceType {
    /**
     *
     * @param {string} key
     * @param {string} label
     */
    constructor(key, label) {
        this.key = key;
        this.label = label;
    }
}

export const Gender = new ReferenceType("gender", "Gender"),
    City = new ReferenceType("city", "City"),
    Metro = new ReferenceType("metro", "Metro"),
    Other = new ReferenceType("other", "Other"),
    DayFirst = new ReferenceType('dayFirstDate', "dd mm yyyy"),
    MonthFirst = new ReferenceType('monthFirstDate', "mm dd yyyy"),
    YearFirst = new ReferenceType('yearFirstDate', "yyyy mm dd (ISO)");

/**
 * All reference types
 * @type {ReferenceType[]}
 */
export const All = [Gender, Metro, City, Other, DayFirst, MonthFirst, YearFirst];


/**
 * Finds reference type object by key
 * @param {string} key
 * @returns {ReferenceType}
 */
export const findByKey = key => key && All.find(x => x.key === key);
