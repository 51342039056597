import React from "react";
import PropTypes from 'prop-types';
import {getByCode, getReference} from "../../../service/reference";
import SearchReference from "./SearchReference";

function RegistrationSource(props) {

    return <SearchReference
        onSearch={x => getReference(0, 10, "label", "asc", {"label_contains": x, kind: "registrationSource"}).then(x => x.data)}
        onChange={x => props.onChange(x.code, x)}
        loadValuePromise={code => getByCode(code)}
        className={"was-validated " + (props.isInvalid && "is-invalid")}
        value={props.value}
        getOptionValue={x => x.code}
    />
}

RegistrationSource.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    isInvalid: PropTypes.bool
};

export default RegistrationSource;