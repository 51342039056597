import React from "react";
import {Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from "prop-types";
import FileInput from "../../generic/field/FileInput";
import {showDangerToast} from "../../../lib/notify";
import {upload} from "../../../service/file";

function Upload (props) {
    const { isOpen, onClose } = props;

    function onSave(file) {
        upload(file).then(props.onSave).catch(showDangerToast);
    }

    return (
        <Modal isOpen={isOpen} toggle={onClose}>
            <ModalHeader>Choose file</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <label>File:</label>
                    <FileInput onSelect={onSave}/>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={onClose}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
}

Upload.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
}

export default Upload
