import React, {useEffect, useState} from 'react'
import DataTable, {field} from "../../generic/DataTable";
import {findAndCount, deleteEntry} from "../../../service/dteConfig";
import PropTypes from "prop-types";
import {datetime} from "../../../lib/format";

function Table(props) {
    const [reload, setReload] = useState(false);
    useEffect(() => setReload(!reload), [props.reload]);

    return (
        <DataTable
            reload={reload}
            filters={props.filters}
            findAndCount={findAndCount}
            fields={[
                field("Project", false, "project.label", res => res.project? res.project.label : ''),
                field("Label", true, "label"),
                field("Created at", true, "createdAt", res => datetime(res.createdAt)),
            ]}
            name={"dte-config"}
            onClick={props.onChange}
            onChange={props.onChange}
            onDelete={(res) => deleteEntry(res.id).then(() => setReload(!reload))}
        />
    )
}

Table.propTypes = {
    filters: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired
};

export default Table;
