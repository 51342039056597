import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from "prop-types";

class ModalConfirmation extends React.Component {
    render() {
        const {onConfirm, onClose, isOpen} = this.props;
        return (
            <Modal isOpen={isOpen} toggle={onClose} className={this.props.className}>
                <ModalHeader>{this.props.title}</ModalHeader>
                <ModalBody>
                    {this.props.text}
                </ModalBody>
                <ModalFooter>
                    <Button color={this.props.color} onClick={onConfirm}>{this.props.confirmButtonText}</Button>{' '}
                    {this.props.hasCancel && <Button color="secondary" onClick={onClose}>Cancel</Button>}
                </ModalFooter>
            </Modal>
        );
    }
}
ModalConfirmation.propTypes = {
    text: PropTypes.node,
    isOpen: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    className: PropTypes.string,
    closeModalConfirmation: PropTypes.func,
    confirmButtonText: PropTypes.string,
    color: PropTypes.string,
    title: PropTypes.string,
    hasCancel: PropTypes.bool
};

ModalConfirmation.defaultProps = {
    confirmButtonText: "Delete",
    color: "danger",
    isOpen: false,
    title: "Confirmation",
    hasCancel: true
};

export default ModalConfirmation;