import React, {useEffect, useState, Fragment} from 'react'
import DataTable, {field} from "../../../generic/DataTable";
import {findAndCount, exportData} from "../../../../service/dteStatistics";
import PropTypes from "prop-types";
import {datetime} from "../../../../lib/format";
import TextSearch from "../../../generic/field/TextSearch";
import DatePicker from "../../../generic/field/DatePicker";
import {Label} from "reactstrap";
import ClearButton from "../../../generic/field/ClearButton";
import { useQueryParam, StringParam, DateParam, BooleanParam } from 'use-query-params';
import moment from "moment";
import { pickBy } from 'lodash';
import ExportFunButton from "../../../generic/ExportFunButton";
import Button from 'reactstrap/lib/Button';

function Table(props) {
    const [reload, setReload] = useState(false);
    const [visitorId, setVisitorId] = useQueryParam('visitorId', StringParam);
    const [contactId, setContactId] = useQueryParam('contactId', StringParam);
    const [lastVisitDateFrom, setLastVisitDateFrom] = useQueryParam('lastVisitDateFrom', DateParam);
    const [lastVisitDateTo, setLastVisitDateTo] = useQueryParam('lastVisitDateTo', DateParam);
    const [lastRegistrationDateFrom, setLastRegistrationDateFrom] = useQueryParam('lastRegistrationDateFrom', DateParam);
    const [lastRegistrationDateTo, setLastRegistrationDateTo] = useQueryParam('lastRegistrationDateTo', DateParam);
    const [lastAuthorizationDateFrom, setLastAuthorizationDateFrom] = useQueryParam('lastAuthorizationDateFrom', DateParam);
    const [lastAuthorizationDateTo, setLastAuthorizationDateTo] = useQueryParam('lastAuthorizationDateTo', DateParam);
    const [userId, setUserId] = useQueryParam('userId', StringParam);
    const [allColumns, setAllColumns] = useQueryParam('allCols', BooleanParam);
    useEffect(() => setReload(!reload), [props.reload]);

    const filters = pickBy({...props.filters, visitorId, contactId, userId,
        lastVisitDate_gte: lastVisitDateFrom,
        lastRegistrationDate_gte: lastRegistrationDateFrom,
        lastAuthorizationDate_gte: lastAuthorizationDateFrom
    });

    if(lastVisitDateTo) filters['lastVisitDate_lte'] = moment(lastVisitDateTo).clone().endOf('day').toDate();
    if(lastRegistrationDateTo) filters['lastRegistrationDate_lte'] = moment(lastRegistrationDateTo).clone().endOf('day').toDate();
    if(lastAuthorizationDateTo) filters['lastAuthorizationDate_lte'] = moment(lastAuthorizationDateTo).clone().endOf('day').toDate();

    const tableDefaultFields = [
        field("Visitor Id", true, "visitorId"),
        field("Contact Id", true, "contactId"),
        field("Visit Count", true, "visitCount"),
        field("Registration Count", true, "registrationCount" ),
        field("Visit Date", true, "visitDate", res => datetime(res.visitDate)),
        field("Last Visit Date",true, "lastVisitDate", res => datetime(res.lastVisitDate)),
        field("Registration Date", true, "registrationDate", res => datetime(res.registrationDate)),
        field("Last Registration Date", true, "lastRegistrationDate", res => datetime(res.lastRegistrationDate) ),
        field("Last User Id", true, "userId" ),
        field("Type", true, "type"),
    ]

    const additionalFields =  [
        field("Authorization Count", true, "authorizationCount" ),
        field("Authorization Date", true, "authorizationDate", res => datetime(res.authorizationDate)),
        field("Last Authorization Date", true, "lastAuthorizationDate", res => datetime(res.lastAuthorizationDate) ),
        
        field("User Type", true, "userType"),
        field("Quiz Passed", true, "quizPassed"),
        field("Test Drive Date", true, "testDriveDate", res => datetime(res.testDriveDate)),
        field("Promocode Redeemed", true, "promocodeRedeemed")
    ]

    const fields = allColumns ? tableDefaultFields.concat(additionalFields) : tableDefaultFields;

    return <Fragment>
        <div className="form-inline">
            <TextSearch value={visitorId} className="mb-2 mr-2 form-control-sm" onSearch={setVisitorId}
                        placeholder="Visitor Id"/>
            <TextSearch value={contactId} className="mb-2 mr-2 form-control-sm" onSearch={setContactId}
                        placeholder="Contact Id"/>
            <TextSearch value={userId} className="mb-2 mr-2 form-control-sm" onSearch={setUserId}
                        placeholder="User Id"/>
            <DatePicker placeholder={'Visit date from'} classNames={'form-control-sm mb-2 mr-2'}
                        onDateChange={x => setLastVisitDateFrom(x)}
                        selectedDay={lastVisitDateFrom}
            />
            <Label size={'sm'} className={'mb-2 mr-2'}> — </Label>
            <DatePicker placeholder={'Visit date to'} classNames={'form-control-sm mb-2 mr-2'}
                        onDateChange={x => setLastVisitDateTo(x)}
                        selectedDay={lastVisitDateTo}
            />
            <DatePicker placeholder={'Reg date from'} classNames={'form-control-sm mb-2 mr-2'}
                        onDateChange={x => setLastRegistrationDateFrom(x)}
                        selectedDay={lastRegistrationDateFrom}
            />
            <Label size={'sm'} className={'mb-2 mr-2'}> — </Label>
            <DatePicker placeholder={'Reg date to'} classNames={'form-control-sm mb-2 mr-2'}
                        onDateChange={x => setLastRegistrationDateTo(x)}
                        selectedDay={lastRegistrationDateTo}
            />
            {
                allColumns && <>
                    <DatePicker placeholder={'Auth date from'} classNames={'form-control-sm mb-2 mr-2'}
                            onDateChange={x => setLastAuthorizationDateFrom(x)}
                            selectedDay={lastAuthorizationDateFrom}
                    />
                    <Label size={'sm'} className={'mb-2 mr-2'}> — </Label>
                    <DatePicker placeholder={'Auth date to'} classNames={'form-control-sm mb-2 mr-2'}
                                onDateChange={x => setLastAuthorizationDateTo(x)}
                                selectedDay={lastAuthorizationDateTo}
                    /> 
                </>
            }
                       
            <ClearButton className="mb-2 ml-2 btn-sm" onClick={() => {
                [setUserId, setVisitorId, setContactId, setLastRegistrationDateTo, setLastRegistrationDateFrom, setLastVisitDateTo, setLastVisitDateFrom].forEach(f => f(() => undefined));
                setReload(reload => !reload);
            }}/>
            <Button 
                color={allColumns ? "primary" : "outline-primary"} className="mb-2 ml-2"
                onClick={() => setAllColumns(!allColumns)}
            >
                All Cols
            </Button>
        </div>
        <DataTable
            reload={reload}
            filters={filters}
            tableButtons={dataParameters => <ExportFunButton dataParameters={dataParameters} exportFun={exportData}/> }
            findAndCount={findAndCount}
            fields={fields}
            name={"dte-statistics"}
        />
    </Fragment>
}

Table.propTypes = {
    filters: PropTypes.object.isRequired
};

export default Table;
