import React from 'react'
import {Button, Col, Form, FormGroup, Input, Label, Row} from 'reactstrap'
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-xcode";
import {Config} from 'lead-generation-common';

const Integration = ({dispatch, state, ...rest}) => {
    return (
        <div>
            <h4 className="card-title"> Integration </h4>
            <Form className="forms-sample">
                <FormGroup className="form-group">
                    <Label>Custom Collection</Label>
                    <Input
                        type={'select'}
                        className="form-control"
                        name="customCollection"
                        id='customCollection'
                        value={state.customCollection}
                        onChange={e => dispatch({
                            type: 'setCustomCollection',
                            payload: {value: e.target.value}
                        })}
                        autoComplete={"false"}
                    >
                        <option value={''} disabled> Choose collection</option>
                        {state.customCollections && state.customCollections.map((item, index) => {
                            return (<option value={item.value} key={index}>{item.label}</option>)
                        })}
                    </Input>
                </FormGroup>
                <FormGroup>
                    <div className='col-sm-3'>
                        <Button className='form-check-label' onClick={() => dispatch({type: 'addMapper'})}>
                            <i className="fa fa-plus"/> Add custom mapper
                        </Button>
                    </div>
                </FormGroup>
                {
                    !!state.mappers.length && state.mappers.map((item, mapperIndex) =>
                        <FormGroup style={{marginTop: '80px'}} key={'mapper_' + mapperIndex}>
                            <Row form>
                                <Col lg={10}>
                                    <Label>Form Source</Label>
                                    <Input
                                        type={'select'}
                                        className="form-control"
                                        name="mapType"
                                        id='mapType'
                                        value={item.mapType}
                                        onChange={e => dispatch({
                                            type: 'setMapperType',
                                            payload: {
                                                value: {
                                                    index: mapperIndex,
                                                    mapType: e.target.value
                                                }
                                            }
                                        })}
                                        autoComplete={"false"}
                                    >
                                        <option value="" disabled>Choose Mapper Type</option>
                                        {Object.entries(Config.queue.QUEUE_DATA)
                                            .map(([key, value]) =>
                                                <option key={key} value={key.toLowerCase()}>{value.LABEL}</option>)}
                                    </Input>
                                </Col>
                                <Col lg={1} className={'d-flex align-items-end'}>
                                    <Button color="danger" size={'xs'} onClick={() => dispatch({
                                        type: 'deleteMapper',
                                        payload: {
                                            value: {
                                                index: mapperIndex,
                                            }
                                        }
                                    })}>
                                        <i className="fa fa-window-close"/>
                                    </Button>
                                </Col>
                            </Row>
                            <label style={{padding: '10px 0'}}>Mapper function</label>
                            <Row>
                                <Col>
                                    <AceEditor
                                        width="100%"
                                        mode="javascript"
                                        theme="xcode"
                                        name="template"
                                        onChange={val => dispatch({
                                            type: 'setMapperValue',
                                            payload: {
                                                value: {
                                                    index: mapperIndex,
                                                    mapCode: val
                                                },
                                            }
                                        })}
                                        value={state.mappers[mapperIndex].mapCode}
                                        editorProps={{$blockScrolling: true}}
                                        setOptions={{useWorker: false}}
                                        tabSize={2}
                                    />
                                </Col>
                            </Row>

                        </FormGroup>)
                }
            </Form>
        </div>
    )
}

export default Integration;