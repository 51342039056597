import moment from "moment";
import PropTypes from 'prop-types';
import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import './DatePicker.css';

function parseDate(str, format, locale) {
    if (!str || str === "") return undefined;
    const parsed = moment(str, format);
    if (parsed.isValid()) return parsed.toDate();
    return undefined;
}

function formatDate(date, format, locale) {
    if (!date) return "";
    return moment(date).format(format);
}

export default class DatePicker extends React.Component {

    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.state = {
            selectedDay: this.props.selectedDay,
        };
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedDay !== prevProps.selectedDay)
            this.setState({
                selectedDay: this.props.selectedDay
            });
    }

    handleDayClick(date) {
        this.setState({
            selectedDay: date
        });
        if (this.props.onDateChange) this.props.onDateChange(date);
    }

    render() {
        const {selectedDay} = this.state;
        return (
            <DayPickerInput
                selectedDay={this.state.selectedDay}
                value={selectedDay ? selectedDay : undefined }
                format="YYYY.MM.DD"
                formatDate={formatDate} parseDate={parseDate}
                inputProps={{
                    className: 'form-control ' + this.props.classNames,
                    id: this.props.id,
                    placeholder: this.props.placeholder
                }}
                dayPickerProps={{
                    showWeekNumbers: true,
                    selectedDates: this.state.selectedDay,
                    onDayClick: this.handleDayClick,
                    firstDayOfWeek: 1
                }}
            />
        );
    }
}

DatePicker.propTypes = {
    onDateChange: PropTypes.func,
    selectedDay: PropTypes.instanceOf(Date)
};