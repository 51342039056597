import {Service} from '../lib/strapi';
import {strapi} from "../lib/auth";

const service = new Service("cors");
export const getCorsData = service.findAndCountServerSide;
export const insertCorsData = (domain) => strapi.createEntry('cors', {
    domain
});
export const changeCorsData = async (id, domain) => {
    const entry = {
        id,
        domain
    };
    return strapi.updateEntry('cors', id, entry);
};
export const deleteCorsData = (id) => {
    return strapi.deleteEntry('cors', id)
};

export const exportCorsData = service.export;