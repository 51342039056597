import React from "react";
import useMemoizeChart from "./useMemoizeChart";
import LoaderOverlay from "../../../generic/LoaderOverlay";
import useFetchStatistics from "../useFetchStatistics";
import {getStatistics} from "../../../../service/file_logs";
import EsbStatistics from "../generic/components/EsbStatistics";

const FunnelGraph = (props) => {
    const [chartData, isLoading] = useFetchStatistics({...props, getStatistics})
    const {rowsAmount, correctRowsAmount, uniqueRowsAmount, errorRowsAmount, notUniqueRowsAmount, correctRowsPercent,
        errorRowsPercent, uniqueRowsPercent, notUniqueRowsPercent, ...esbStatistics} = useMemoizeChart(chartData);

    return <LoaderOverlay isVisible={isLoading}>
        <div className="tree">
            <div className="main-branch">
                <div className="branch__name">
                    <div className="fz-18">Total</div>
                    <div><b>{rowsAmount}</b></div>
                </div>
                <div className="first__branch branch_green">
                    <div className="branch__name">
                        <div className="fz-18">Valid</div>
                        <div><b>{correctRowsAmount}</b> <br/> <span className="branch__percent">{correctRowsPercent}%</span></div>
                    </div>
                    <div className="first__branch branch_green">
                        <div className="branch__name">
                            <div className="fz-18">Unique</div>
                            <div><b>{uniqueRowsAmount}</b><br/><span className="branch__percent">{uniqueRowsPercent}%</span></div>
                        </div>
                        <EsbStatistics type={props.type} {...esbStatistics} />
                    </div>
                    <div className="second__branch branch_red">
                        <div className="branch__name">
                            <div className="fz-18">Not unique</div>
                            <div><b>{notUniqueRowsAmount}</b><br/><span className="branch__percent">{notUniqueRowsPercent}%</span></div>
                        </div>
                    </div>
                </div>
                <div className="second__branch branch_red">
                    <div className="branch__name">
                        <div className="fz-18">Invalid</div>
                        <div><b>{errorRowsAmount}</b><br/> <span className="branch__percent">{errorRowsPercent}%</span></div>
                    </div>
                </div>
            </div>
        </div>
    </LoaderOverlay>

}

export default FunnelGraph;