import React, {useState} from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from "prop-types";
import form from "../../../../../service/form";
import * as notify from "../../../../../lib/notify";

export default function BackupModal(props) {
    const [name, setName] = useState('');
    const {onClose, isOpen} = props;

    function saveBackup() {
        form.createBackup(props._id, {...props.data, name}).then(() => {
            notify.showSuccessToast("Form backed up");
            setName('');
            onClose();
        }, notify.showDangerToast);
    }
    return (
        <Modal isOpen={isOpen} toggle={onClose} >
            <ModalHeader>Backup name</ModalHeader>
            <ModalBody>
                <form className='forms-sample'>
                    <div className='form-group'>
                        <label htmlFor='backup_name'>Name</label>
                        <input
                            type='text'
                            id='backup_name'
                            className='form-control'
                            value={name}
                            autoComplete="false"
                            onChange={e => setName(e.target.value)}
                        />
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={saveBackup}>Save</Button>
                <Button color="secondary" onClick={onClose}>Close</Button>
            </ModalFooter>
        </Modal>
    )
};


BackupModal.propTypes = {
    _id:PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    data: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};