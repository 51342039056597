import React, {Fragment} from "react";
import {Config} from "lead-generation-common";
import PropTypes from "prop-types";
import {Col, FormGroup, Label, Row} from "reactstrap";

const allocations = Object.keys(Config.esb.brandNameToAllocationMap).map(name => ({
    label: `${name} (${Config.esb.brandNameToAllocationMap[name]})`,
    key: Config.esb.brandNameToAllocationMap[name]
}))

function General({form: {register, errors}}) {
    return <Fragment>
        <Row>
            <Col md={12} lg={6}>
                <FormGroup>
                    <Label for="project">ESB Project</Label>
                    <select className={`form-control ${errors.esbProject ? "is-invalid" : ""}`} name="esbProject" ref={register({required: "Select ESB project"})}>
                        <option value="" disabled>Select ESB project</option>
                        {Config.esb.projects.map(x => <option value={x} key={x}>{x}</option>)}
                    </select>

                    {errors.esbProject && <div className="invalid-feedback">{errors.esbProject.message}</div>}

                    <small className="form-text text-muted">DELL Boomi ESB Integration project. For integration and analytics</small>
                </FormGroup>
            </Col>
            <Col md={12} lg={6}>
                <FormGroup>
                    <Label for="project">Brand Allocation</Label>
                    <select className={`form-control ${errors.allocationCode ? "is-invalid" : ""}`} name="allocationCode" ref={register({required: "Select Brand Allocation"})}>
                        <option value="" disabled>Select allocation</option>
                        {allocations.map(x => <option value={x.key} key={x.label}>{x.label}</option>)}
                    </select>

                    {errors.allocationCode && <div className="invalid-feedback">{errors.allocationCode.message}</div>}

                    <small className="form-text text-muted">Consumer allocation to specific brand (Contact brand)</small>
                </FormGroup>
            </Col>
        </Row>
        <Row>
            <Col md={12} lg={6}>
                <FormGroup>
                    <Label for="project">Cell Id</Label>

                    <input
                        name="cellId"
                        placeholder="Enter Cell Id: 123456"
                        className={`form-control ${errors.cellId ? "is-invalid" : ""}`}
                        ref={register({
                            required: "Cell is required"
                        })}
                    />

                    {errors.cellId && <div className="invalid-feedback">{errors.cellId.message}</div>}

                    <small className="form-text text-muted">CellId is a unique campaign identifier. For history logs and analytics</small>
                </FormGroup>
            </Col>
        </Row>
    </Fragment>
}

General.propTypes = {
    form: PropTypes.object.isRequired
}

export default General;