import {apiUrl} from "../lib/strapi.js";

export const routes = [
    {
        title: "Dashboard",
        to: "/",
        icon: "home"
    },
    {
        title: "Forms",
        to: "/forms",
        icon: "copy",
        access: ["Administrator", "Form manager"]
    },
    {
        title: "Pages",
        to: "/pages",
        icon: "globe",
        access: ["Administrator", "Form manager"]
    },
    {
        title: "Projects",
        to: "/projects",
        icon: "building",
        access: ["Administrator", "Form manager"]
    },
    {
        title: "DTE",
        to: "/dte",
        icon: "shopping-cart",
        access: ["Administrator", "Form manager"]
    },
    {
        title: "Input",
        to: "/input",
        icon: "list-ul",
        children: [
            {
                title: "Files",
                to: "/input/files",
                access: ["Administrator", "Content manager"]
            },
        ],
        access: ["Administrator", "Content manager"]
    },
    {
        title: "Anti-fraud",
        to: "/anti-fraud",
        icon: "search-minus",
        children: [
            {
                title: "Logs",
                to: "/anti-fraud/logs",
                access: ["Administrator", "Content manager"]
            },
            {
                title: "Config",
                to: "/anti-fraud/config",
                access: ["Administrator", "Content manager"]
            },
            {
                title: "Function",
                to: "/anti-fraud/function",
                access: ["Administrator", "Content manager"]
            }
        ]
    },
    {
        title: "Seamless",
        to: "/seamless",
        icon: "eye",
        children: [
            {
                title: "Queues",
                to: "/seamless/queues",
                access: ["Administrator", "Seamless manager"]
            },
            {
                title: "File logs",
                to: "/seamless/filelogs",
                access: ["Administrator", "Seamless manager"]
            },
            {
                title: "Validation logs",
                to: "/seamless/validationlogs",
                access: ["Administrator", "Seamless manager"]
            },
            {
                title: "Customers",
                to: "/seamless/customers",
                access: ["Administrator", "Seamless manager"]
            },
            {
                title: "Orders",
                to: "/seamless/orders",
                access: ["Administrator", "Seamless manager"]
            },
            {
              title: "Statistics",
              to: "/seamless/statistics",
              access: ["Administrator", "Seamless manager"]
            },
            {
                title: "Email domains list",
                to: "/seamless/email-domains-list",
                access: ["Administrator", "Seamless manager"]
            },
        ]
    },
    {
        title: "UBE",
        to: "/ube",
        icon: "cog",
        children: [
            {
                title: "Config",
                to: "/ube/config",
                access: ["Administrator"]

            },
            {
                title: "Reference",
                to: "/ube/reference",
                access: ["Administrator"]

            },
            {
                title: "SMS Templates",
                to: "/ube/sms",
                access: ["Administrator"]

            },
            {
                title: "Terms Versions",
                to: "/ube/terms",
                access: ["Administrator"]

            }
        ],
        access: ["Administrator"]
    },
    {
        title: "Administration",
        to: "/administration",
        icon: "user",
        children: [
            {
                title: "User",
                to: "/administration/user",
                access: ["Administrator", "User manager", "Profile manager"]

            },
            {
                title: "Roles",
                to: "/administration/roles",
                access: ["Administrator"]
            },
            {
                title: "CORS",
                to: "/administration/cors",
                access: ["Administrator"]
            },
            {
                title: "Whitelist",
                to: "/administration/whitelist",
                access: ["Administrator"]
            },
            {
                title: "Queues",
                to: "/administration/queue",
                access: ["Administrator"]
            },
            {
                title: "Alerts",
                to: "/administration/alerts",
                access: ["Administrator"]
            },
            {
                title: "Verification",
                to: "/administration/verification-codes",
                access: ["Administrator"]
            },
            {
                title: "Journal",
                to: "/administration/journal",
                access: ["Administrator"]
            }
        ],
        access: ["Administrator", "User manager", "Profile manager"]
    },
    {
        title: "Exports",
        icon: "table",
        to: "/administration/export",
        access: ["Administrator", "Seamless manager"]
    },
    {
      title: "API Documentation",
      to: `${apiUrl}/documentation`,
      icon: "book",
      isExternal: true
    }
];

const flattenRoutesFun = (source, parent) =>
    source.reduce((acc, route) => {
        const builtRoute = {
            title: route.title,
            to: route.to,
            icon: route.icon || (parent && parent.icon) || "",
            breadcrumbs: ((parent && parent.breadcrumbs) || []).concat([route.title])
        };
        return acc.concat(builtRoute).concat(flattenRoutesFun(route.children || [], builtRoute))
    }, []);

const flattenRoutes = flattenRoutesFun(routes).reverse();

export const getCurrentRoute = to =>
    flattenRoutes.find(route => to.startsWith(route.to));