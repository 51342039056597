import React, {Component} from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {QueryParamProvider} from 'use-query-params';
import './App.css';
import Layout from './components/layout/Layout';
import Login from './components/Login';
import Index from './components/Index';
import PrivateRoute from './route/PrivateRoute';
import PublicRoute from "./route/PublicRoute";
import Me from "./components/Me/Index";
import AdministrationUser from "./components/administration/user/Route";
import AdministrationRoles from "./components/administration/role/Index";
import AdministrationCors from "./components/administration/cors/Index";
import AdministrationWhitelist from "./components/administration/whitelist/Index";
import AdministrationExport from "./components/administration/export/Index";
import Forms from "./components/forms/Route";
import Pages from "./components/pages/Route";
import Files from "./components/input/files/Index";
import Projects from "./components/projects/Route";
import AdministrationQueue from "./components/administration/queue/Index";
import AdministrationOutboundQueue from "./components/administration/outbound_queue/Index";
import {Alerts} from "./components/administration/alerts";
import VerificationCode from "./components/administration/verification-code/Index";
import UBE from "./components/ube/Route";
import AntiFraud from "./components/anti-fraud/logs/Index";
import Journal from "./components/administration/journal/Index";
import Dte from "./components/dte/Route";
import AntiFraudConfig from "./components/anti-fraud/config/Index";
import Function from "./components/anti-fraud/function/Route";
import {SeamlessQueues, SeamlessFileLogs, CustomersDataTable, OrdersDataTable, Customer, Order} from "./components/seamless";
import SeamlessStatistics from "./components/seamless/statistics";
import SeamlessValidationLogs from "./components/seamless/validationlogs";
import EmailDomainsList from "./components/seamless/emaildomainslist";

class App extends Component {
    render() {
        return (
            <Router>
                <QueryParamProvider ReactRouterRoute={Route}>
                    <Layout {...this.props}>
                    <Switch>
                        <PublicRoute path="/login" component={Login}/>
                        <PrivateRoute exact path="/" component={Index}/>
                        <PrivateRoute path="/me" component={Me}/>
                        <PrivateRoute path="/forms" component={Forms}/>
                        <PrivateRoute path="/pages" component={Pages}/>
                        <PrivateRoute path="/projects" component={Projects}/>
                        <PrivateRoute path="/dte" component={Dte}/>
                        <PrivateRoute path="/input/files" component={Files}/>
                        <PrivateRoute path="/administration/user" component={AdministrationUser}/>
                        <PrivateRoute path="/administration/roles" component={AdministrationRoles}/>
                        <PrivateRoute path="/administration/cors" component={AdministrationCors}/>
                        <PrivateRoute path="/administration/whitelist" component={AdministrationWhitelist}/>
                        <PrivateRoute path="/administration/queue" component={AdministrationQueue}/>
                        <PrivateRoute
                            path="/administration/outbound_queue"
                            component={AdministrationOutboundQueue}
                        />
                        <PrivateRoute path="/administration/export" component={AdministrationExport}/>
                        <PrivateRoute path="/administration/alerts" component={Alerts}/>
                        <PrivateRoute path="/administration/verification-codes" component={VerificationCode}/>
                        <PrivateRoute path="/administration/journal" component={Journal}/>
                        <PrivateRoute path="/ube" component={UBE}/>
                        <PrivateRoute path="/seamless/queues" component={SeamlessQueues}/>
                        <PrivateRoute path="/seamless/filelogs" component={SeamlessFileLogs}/>
                        <PrivateRoute path="/seamless/customers/:customerId" component={Customer}/>
                        <PrivateRoute path="/seamless/customers" component={CustomersDataTable}/>
                        <PrivateRoute path="/seamless/orders/:orderId" component={Order}/>
                        <PrivateRoute path="/seamless/orders" component={OrdersDataTable}/>
                        <PrivateRoute path="/seamless/statistics" component={SeamlessStatistics}/>
                        <PrivateRoute path="/seamless/validationlogs" component={SeamlessValidationLogs}/>
                        <PrivateRoute path="/seamless/email-domains-list" component={EmailDomainsList}/>
                        <PrivateRoute path="/anti-fraud/logs" component={AntiFraud}/>
                        <PrivateRoute path="/anti-fraud/config" component={AntiFraudConfig}/>
                        <PrivateRoute path="/anti-fraud/function" component={Function}/>
                    </Switch>
                </Layout>
                </QueryParamProvider>
            </Router>
        );
    }
}

export default App;
