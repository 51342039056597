import React from 'react'
import TableCard from "../../generic/TableCard";
import Table from "../cors/Table";
import Create from "./Create";
import Edit from "./Edit";

export default class AdministrationUser extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            createIsOpen: false,
            editIsOpen: false,
            reload: undefined
        };

        this.openEditModal = this.openEditModal.bind(this);
    };

    openEditModal(res) {
        this.setState({
            editIsOpen: true,
            idToEdit: res.id,
            domainToEdit: res.domain
        })
    }

    render() {
        const { createIsOpen, editIsOpen, reload, idToEdit, domainToEdit } = this.state;
        return <TableCard title={"CORS Policy"}>
            <div className={"row justify-content-start ml-0"}>
                <button className="btn btn-primary" onClick={() => this.setState({createIsOpen: true})}>
                    <i className="fa fa-plus"/>
                    &nbsp; Add new policy
                </button>
            </div>
            <Create isOpen={createIsOpen} closeModalConfirmation={() => this.setState({createIsOpen: false, reload: !reload})} />
            <Edit isOpen={editIsOpen} idToEdit={idToEdit} domainToEdit={domainToEdit} closeModalConfirmation={() => this.setState({editIsOpen: false, reload: !reload})} />
            <div className={"pt-3"}>
                <Table reload={reload} onChange={this.openEditModal} />
            </div>
        </TableCard>
    }
};
