import React, {Fragment} from 'react';
import {DropdownToggle, UncontrolledButtonDropdown, DropdownMenu, DropdownItem} from 'reactstrap';
import PropTypes from "prop-types";
import ModalConfirmation from "./ModalConfirmation";
import {Config} from "lead-generation-common";
import {downloadLink} from "../../service/export";
import * as notify from "../../lib/notify";
import Loader from "./Loader";

const statuses = Config.export.status;

class ExportButton extends React.Component {

    constructor(props) {
        super(props);

        this.onSelect = this.onSelect.bind(this);
        this.state = {
            showModal: false,
            showLoader: false
        }
    }

    onSelect(mode) {
        this.setState({showLoader: true});
        this.props.exportFun(mode).then(result => {
            this.setState({exportData: result, showModal: true, showLoader: false})
        }).catch((e) => {
            this.setState({showLoader: false});
            notify.showDangerToast(e);
        });
    }

    render() {
        const {exportData, showModal, showLoader} = this.state;
        const {status, description} = exportData || {};
        return <Fragment>
            <UncontrolledButtonDropdown direction="up">
                <DropdownToggle className="btn-outline-secondary btn-sm" caret>
                    Export
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => this.onSelect(ExportButton.All)}>All table</DropdownItem>
                    <DropdownItem onClick={() => this.onSelect(ExportButton.Page)}>Current page</DropdownItem>
                </DropdownMenu>
            </UncontrolledButtonDropdown>
            <ModalConfirmation onClose={() => this.setState({showModal: false})}
                               onConfirm={() => this.setState({showModal: false})}
                               isOpen={showLoader || showModal}
                               hasCancel={false}
                               title={"File export"}
                               confirmButtonText={showLoader ? "Loading..." : "Ok"}
                               color={"primary"}
                               text={
                                   showLoader ?
                                       <Loader/> :
                                       <Fragment>
                                           {status === statuses.Error && description}
                                           {status === statuses.Processing && `Export takes more time. You can download file later in /Administration/Export`}
                                           {status === statuses.Ready &&
                                           <p>
                                               <a href={downloadLink(exportData)} rel="noopener noreferrer" target="_blank"
                                                  download>Download file: <strong>{exportData.filename}</strong></a>
                                               <br/>
                                               You can download file later in /Administration/Export
                                           </p>
                                           }
                                       </Fragment>
                               }/>
        </Fragment>
    }
}

ExportButton.propTypes = {
    /**
     * function(mode: string): Promise<{id: string, status: string, filename: string}>
     */
    exportFun: PropTypes.func.isRequired,
};

ExportButton.All = "all";
ExportButton.Page = "page";

export default ExportButton;