import {getErrorClass, getInProgressClass, getRemovedClass} from "../index";
import React from "react";

const EsbStatistics = (props) => {
    const {processed, inProgress, errorEsb, removedEsb, processedRowsPercent, inProgressRowsPercent,
        errorEsbRowsPercent, removedEsbRowsPercent} = props

    return (<>
        <div className={`second__branch branch_green`}>
            <div className="branch__name">
                <div className="fz-18">In progress</div>
                <div><b>{inProgress}</b><br/><span className="branch__percent">{inProgressRowsPercent}%</span></div>
            </div>
        </div>
        <div className={`third__branch branch_red`}>
            <div className="branch__name">
                <div className="fz-18">Error</div>
                <div><b>{errorEsb}</b><br/><span className="branch__percent">{errorEsbRowsPercent}%</span></div>
            </div>
        </div>
        <div className={`fourth__branch branch_red`}>
            <div className="branch__name">
                <div className="fz-18">Removed</div>
                <div><b>{removedEsb}</b><br/><span className="branch__percent">{removedEsbRowsPercent}%</span></div>
            </div>
        </div>
        <div className="first__branch branch_green">
            <div className="branch__name">
                <div className="fz-18">Processed</div>
                <div><b>{processed}</b><br/><span className="branch__percent">{processedRowsPercent}%</span></div>
            </div>
        </div>
    </>)
}

export default EsbStatistics;