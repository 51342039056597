import React, {Fragment, useEffect, useState} from "react";
import {getEntry, updateEntry} from "../../../service/config";
import * as notify from "../../../lib/notify";
import {showDangerToast} from "../../../lib/notify";
import {useHistory} from "react-router-dom";
import LoaderOverlay from "../../generic/LoaderOverlay";
import Card from "../../generic/Card";
import {Button, Col, FormGroup, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import {Controller, useForm} from "react-hook-form";
import ProjectInput from "../../generic/field/ProjectInput";
import General from "./features/General";
import PhoneLogin from "./features/PhoneLogin";
import Registration from "./features/Registration";
import Deeplink from "./features/Deeplink";
import AgeVerification from "./features/AgeVerification";
import Communication from "./features/Communication";
import Terms from "./features/Terms";
import {StringParam, useQueryParam} from 'use-query-params';

const Features = [
    {
        key: "loginByPassword",
        label: "Login Password",
        render: (enabled, form) => <Fragment>Password login enabled. No parameters</Fragment>
    },
    {
        key: "loginByPhone",
        label: "Login Phone",
        render: (enabled, form) => <PhoneLogin form={form} enabled={enabled}/>
    },
    {
        key: "registration",
        label: "Registration",
        render: (enabled, form) => <Registration form={form} enabled={enabled}/>
    },
    {
        key: "deeplink",
        label: "Deeplink",
        render: (enabled, form) => <Deeplink form={form} enabled={enabled}/>
    },
    {
        key: "av",
        label: "AgeVerification",
        render: (enabled, form) => <AgeVerification form={form} enabled={enabled}/>
    },
    {
        key: "communication",
        label: "Communication",
        render: (enabled, form) => <Communication form={form} enabled={enabled}/>
    },
    {
        key: "terms",
        label: "Terms & Conditions",
        render: (enabled, form) => <Terms form={form} enabled={enabled}/>
    }
]

const Feature = (key) => Features.find(f => f.key === key)

function Edit(props) {
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [activeFeature,  setActiveFeature] = useQueryParam("activeFeature", StringParam);

    const form = useForm({});
    const { register, handleSubmit, reset, errors, control, setValue, watch } = form;

    useEffect(() => {
        loadData();
    }, [props.match.params.id]);

    function loadData() {
        const id = props.match.params.id;
        setLoading(true);
        getEntry(id).then(res => {
            reset({
                project: res.project && res.project._id,
                label: res.label,
                key: res.key,
                type: res.type,
                esbProject: res.esbProject,
                allocationCode: res.allocationCode,
                cellId: res.cellId,
                loginByPhone: res.loginByPhone,
                loginByPassword: res.loginByPassword,
                registration: res.registration,
                deeplink: res.deeplink,
                av: res.av,
                communication: res.communication,
                termsEnabled: res.termsEnabled,
                terms: res.terms
            })
        }).catch(showDangerToast).finally(() => setLoading(false));
    }

    function onSave(data) {
        setLoading(true);
        updateEntry(props.match.params.id, data).then(res => {
            notify.showSuccessToast("Config updated");
        }).catch(showDangerToast).finally(() => setLoading(false));
    }

    const loginByPhoneWatch = watch("loginByPhone.enabled");
    const loginByPasswordWatch = watch("loginByPassword.enabled");
    const registrationWatch = watch("registration.enabled");
    const deeplinkWatch = watch("deeplink.enabled");

    const enabled = {
        loginByPhone: loginByPhoneWatch,
        loginByPassword: loginByPasswordWatch,
        registration: registrationWatch,
        deeplink: deeplinkWatch,
        communication: watch("communication.enabled"),
        terms: watch("termsEnabled"),
        av: watch("av.enabled")
    }

    const enabledFeatures = Features.filter(x => enabled[x.key]);
    const disabledFeatures = Features.filter(x => !enabled[x.key]);

    const activeFeatureOrGeneral = enabledFeatures.map(x => x.key).find(x => x === activeFeature) || 'general';

    function enableFeature(x) {
        //TODO
        setValue(x + "Enabled", true);
        setValue(x + ".enabled", true);
    }

    function disableFeature(x) {
        //TODO
        setValue(x + "Enabled", false);
        setValue(x + ".enabled", false);
    }

    return <Fragment>
        <Card title={'Edit config'}>
            <LoaderOverlay isVisible={loading}>
                <form onSubmit={handleSubmit(onSave)}>
                    <input name="termsEnabled" ref={register} type="checkbox" />
                    <Row>
                        <Col sm={12} md={12} lg={6} xl={3}>
                            <FormGroup>
                                <Label for="project">Project</Label>
                                <Controller
                                    control={control}
                                    name="project"
                                    defaultValue={null}
                                    render={({ onChange, value }) => (
                                        <ProjectInput inputProps={{className: `form-control ${errors.project ? "is-invalid" : ""}`}}
                                                      selectedItemId={value} onSelect={onChange} disabled={true}/>
                                    )}
                                    rules={{required: "Project is required"}}
                                />

                                {errors.project && <div className="invalid-feedback">{errors.project.message}</div>}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={6} xl={3}>
                            <FormGroup>
                                <Label for="label">Label</Label>
                                <input
                                    name="label"
                                    placeholder="Enter label"
                                    className={`form-control ${errors.label ? "is-invalid" : ""}`}
                                    ref={register({
                                        required: "Label is required"
                                    })}
                                />

                                {errors.label && <div className="invalid-feedback">{errors.label.message}</div>}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={6} xl={3}>
                            <FormGroup>
                                <Label for="label">Key</Label>
                                <input
                                    name="key"
                                    placeholder="Enter key"
                                    disabled={true}
                                    className={`form-control ${errors.key ? "is-invalid" : ""}`}
                                    ref={register({
                                        required: "Key is required"
                                    })}
                                />

                                {errors.key && <div className="invalid-feedback">{errors.key.message}</div>}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={6} xl={3}>
                            <FormGroup>
                                <Label for="label">Type</Label>
                                <select className="form-control form-control-lg" name="type" ref={register} disabled>
                                    <option value="web">Web (Website, Landing, Promo page)</option>
                                    <option value="api">Rest API (PWA, Chat Bot)</option>
                                </select>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={5} lg={4} xl={3} className="mb-3">
                            <h3 className="mb-3">Features
                                {disabledFeatures.length > 0 && <div className="btn-group ml-3 float-lg-right">
                                    <button type="button" className="btn btn-success btn-sm dropdown-toggle" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false">
                                        <i className="fa fa-plus"/>&nbsp;Add
                                    </button>
                                    <div className="dropdown-menu">
                                        {disabledFeatures.map(x => <a key={x.key} className="dropdown-item pb-2 pt-2" href="#/" onClick={() => enableFeature(x.key)}>{x.label}</a>)}
                                    </div>
                                </div>}
                            </h3>

                            <Nav pills vertical>
                                <NavItem className="mr-0">
                                <NavLink className="mb-2" active={activeFeatureOrGeneral === "general"}  href="#/"
                                         onClick={() => setActiveFeature("general")}>
                                    General
                                    <i className="fa fa-home ml-2"/>
                                </NavLink>
                                </NavItem>
                                {enabledFeatures && enabledFeatures.map(x => <NavItem key={x.key}  className="mr-0">
                                    <NavLink className="mb-2" onClick={() => setActiveFeature(x.key)} active={activeFeatureOrGeneral === x.key} href="#/">
                                        {x.label}
                                    </NavLink>
                                </NavItem>)}
                            </Nav>
                        </Col>
                        <Col sm={12} md={7} lg={8} xl={9} className="mb-3">
                            <h3 className="mb-3">{activeFeatureOrGeneral === 'general' ? 'General' : Feature(activeFeatureOrGeneral).label}

                                {Feature(activeFeatureOrGeneral) && <Button className="float-right" color="danger" size="xs" onClick={() => disableFeature(activeFeatureOrGeneral)}>
                                    <i className="fa fa-times"/> Disable
                                </Button> }
                            </h3>

                            <TabContent activeTab={activeFeatureOrGeneral} className="tab-content-vertical">
                                <TabPane tabId="general">
                                    <General form={form}/>
                                </TabPane>
                                {Features.map(x => (
                                    <TabPane tabId={x.key} key={x.key}>
                                        <Row>
                                            <Col>
                                                <input type="checkbox" name={x.key+".enabled"} ref={register} className="d-none" />
                                                {x.render(enabledFeatures.some(y => y.key === x.key), form)}
                                            </Col>
                                        </Row>
                                    </TabPane>
                                ))}
                            </TabContent>
                        </Col>
                    </Row>
                </form>
                <Button color="primary" className="mr-2" onClick={() => {
                    handleSubmit(onSave)()
                }}>Save</Button>
                <Button color="secondary" onClick={() => history.goBack()}>Cancel</Button>
            </LoaderOverlay>
        </Card>
    </Fragment>
}

export default Edit;