import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import AsyncSelect from 'react-select/async';

function SearchReference(props) {

    const [valueObject, setValueObject] = useState(null);
    useEffect(() => {
        if(!props.value) {
            if(valueObject) setValueObject(null);
        }
        else {
            props.loadValuePromise(props.value).then(setValueObject);
        }
    }, [props.value]);

    return <AsyncSelect className={props.className} placeholder={props.placeholder} cacheOptions defaultOptions loadOptions={props.onSearch} getOptionValue={props.getOptionValue}
           styles={props.customStyles} value={valueObject} onChange={valueObject => props.onChange(valueObject)} getOptionLabel={(x) => x[props.labelKey]} />
}

SearchReference.propTypes = {
    onSearch: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    loadValuePromise: PropTypes.func.isRequired,
    labelKey: PropTypes.string,
    className: PropTypes.string,
    getOptionValue: PropTypes.func,
    getOptionLabel: PropTypes.func,
    customStyles: PropTypes.object
};

SearchReference.defaultProps = {
    labelKey: "label",
    getOptionValue: x => x.id
}

export default SearchReference;