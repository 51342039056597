import React from "react";
import DataTable, {field} from "../../generic/DataTable";
import ExportFunButton from "../../generic/ExportFunButton";
import {deleteCorsData, exportCorsData, getCorsData} from "../../../service/cors";
import PropTypes from "prop-types";
import {datetime} from "../../../lib/format";

export default class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reload: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.reload !== this.props.reload) {
            this.setState({
                reload: !this.state.reload
            })
        }
    }

    render() {

        return <DataTable
            onDelete={(res) => deleteCorsData(res.id).then(() => this.setState({ reload: !this.state.reload }))}
            onChange={this.props.onChange}
            findAndCount={getCorsData}
            tableButtons={dataParameters => <ExportFunButton dataParameters={dataParameters} exportFun={exportCorsData}/> }
            reload={this.state.reload}
            fields={[
                field("Domain", true, "domain"),
                field("Date", true, "createdAt", res => datetime(res.createdAt))
            ]}
            name={"cors"}>
        </DataTable>;
    }
}

Table.propTypes = {
    onChange: PropTypes.func,
    reload: PropTypes.any
};