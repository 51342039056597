import {Config} from 'lead-generation-common';

const initialState = {
    id: "",
    key: "",
    label: "",
    type: "simple",
    fields: [],
    template: "",
    project: null,
    sitekey: "",
    secretkey: "",
    integrationType: 'sf',
    backupModal: false,
    activeTab: 'Parameters',
    securityEnabled: false,
    isPrivate: false,
    customCollection: '',
    customCollections: Config.submission.customCollections,
    mappers: []
}

const reducer = (state, action) => {
    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        return {[name]: value};
    }

    switch (action.type) {
        // Assistance block
        case 'changeActiveTab':
            // value: 'Parameters'
            return {
                ...state,
                activeTab: action.payload.value
            }
        case 'toggleBackupModal':
            return {
                ...state,
                backupModal: !state.backupModal
            }

        // Data editing block
        case 'setFormData':
            // value: {exampleId: 'exampleKey'}
            return {
                ...state,
                ...action.payload.value
            }
        case 'setParameters':
            // value: input event
            return {
                ...state,
                ...handleInputChange(action.payload.value)
            }
        case 'setFields':
            // value: [...fields]
            return {
                ...state,
                fields: [
                    ...action.payload.value
                ]
            }
        case 'removeField':
            // value: 'fieldKey'
            return {
                ...state,
                fields: [...state.fields.filter(field => field.key !== action.payload.value)]
            }
        case 'setTemplate':
            // value: 'example'
            return {
                ...state,
                template: action.payload.value
            }
        case 'toggleSecurity':
            return {
                ...state,
                securityEnabled: !state.securityEnabled,
                secretkey: !state.securityEnabled ? '' : undefined,
                sitekey: !state.securityEnabled ? '' : undefined
            }
        case 'togglePrivacy':
            return {
                ...state,
                isPrivate: !state.isPrivate
            }
        case 'setSecurity':
            // value: event
            return {
                ...state,
                ...handleInputChange(action.payload.value)
            }
        case 'addMapper':
            return {
                ...state,
                ...state.mappers.push({
                    mapType: '',
                    mapCode: ''
                })
            }
        case 'deleteMapper':
            state.mappers = state.mappers.filter((mapper, index) => index !== action.payload.value.index)
            return {
                ...state,
                mappers: [...state.mappers]
            }
        case 'setMapperType':
            // value: {index: Number, mapType: String}
            state.mappers[action.payload.value.index].mapType = action.payload.value.mapType;
            return {
                ...state,
            }
        case 'setMapperValue':
            // value: {index: Number, mapCode: String}
            state.mappers[action.payload.value.index].mapCode = action.payload.value.mapCode;
            return {
                ...state,
            }
        case 'setIntegrationType':
            // value: 'sf'
            return {
                ...state,
                integrationType: action.payload.value
            }
        case 'setCustomCollection':
            // value: 'sf'
            return {
                ...state,
                customCollection: action.payload.value
            }
        case 'refreshFields':
            return {...initialState}
        default:
            return state
    }
}

export {reducer, initialState};