import React from "react";
import PropTypes from 'prop-types';
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {insertCorsData} from "../../../service/cors";

export default class Create extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            domain: undefined,
            reload: undefined
        };
        this.onSave = this.onSave.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    onSave() {
        const { domain } = this.state;
        const { closeModalConfirmation } = this.props;
        insertCorsData(domain).then(() => {
            this.setState({
                reload: !this.state.reload
            })
            closeModalConfirmation()
        });
    }

    onToggle() {
        const { closeModalConfirmation } = this.props;
        closeModalConfirmation();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    render() {
        const { isOpen, onToggle } = this.props;
        const { domain } = this.state;
        return <Modal isOpen={isOpen} toggle={onToggle}>
            <ModalHeader>Create new row</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="domain">Domain</Label>
                        <Input type="text" name="domain" id="domain" placeholder="Enter domain ..." value={domain} onChange={this.handleInputChange}/>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={this.onSave}>Save</Button>{' '}
                <Button color="secondary" onClick={this.onToggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}

Create.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModalConfirmation: PropTypes.func.isRequired
}
