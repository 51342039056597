import React, {useState} from 'react'
import Table from "./Table";
import TableCard from "../../generic/TableCard";
import CEComponent from "./CEComponent";

export default function UBESMSTemplates(props) {

    const [popupState, setPopupState] = useState(false);
    const [reload, setReload] = useState(undefined);
    const [isEditMode, setEditMode] = useState(undefined);
    const [editPopupData, setEditPopupData] = useState(undefined);
    const smscPersonalizationParams = [
        "Verification code (for SMS verification) {%FIRST_NAME%}",
        "After registration for welcome message {%PASSWORD_RESET_LINK%}",
        "Autologin link for password reminder and welcome message {%MOBILE_NUMBER%}"
    ]

    return (
        <TableCard title={"SMS Templates"}>
            <div className={"row justify-content-start ml-2"}>
                <button className="btn btn-primary" onClick={() => {
                    setEditMode(false);
                    setPopupState(true);
                }}>
                    <i className="fa fa-plus"/>
                    &nbsp; Add
                </button>
            </div>
            <CEComponent
                editPopupData={editPopupData}
                isOpen={popupState}
                closePopup={() => setPopupState(false)}
                setReload={() => setReload(!reload)}
                isEditMode={isEditMode}
            />
            <div className="row">
                <div className={"pt-3 col-lg-8"}>
                    <Table reload={reload}
                           setEditPopupData={data => setEditPopupData(data)}
                           setReload={() => setReload(!reload)}
                           setEditMode={() => setEditMode(true)}
                           openPopup={() => setPopupState(true)}
                    />
                </div>
                <div className="col-lg-4 jumbotron">
                    <h4 className="card-title">Information</h4>
                    <p className="card-description">There are two SMS Gates:</p>
                    <ul className="list-arrow">
                        <li>SMS Traffic (uCRM CC)</li>
                        <li>SMSC (RRP)</li>
                    </ul>
                    <p className="card-description">For SMS Traffic you need to specify template code</p>
                    <p className="card-description">For SMSC you need to specify SMS text with personalization params:</p>
                    <ul className="list-arrow">
                        {
                            smscPersonalizationParams.map(param => <li key={param}>{param}</li>)
                        }
                    </ul>
                </div>
            </div>
        </TableCard>
    );
};
