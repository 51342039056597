import React from "react";
import DataTable, {field} from "../../generic/DataTable";
import {findAndCount} from "../../../service/verification-code";
import PropTypes from "prop-types";
import {datetime} from "../../../lib/format";

const Table = (props) => {
    const filters = props.recipient ? {recipient: props.recipient} : {};
    return (<DataTable
        filters={filters}
        findAndCount={findAndCount}
        reload={props.reload}
        fields={[
            field("Channel", true, "channel"),
            field("Recipient", true, "recipient"),
            field("Code", true, "code"),
            field("Attempts", true, "attempts"),
            field("Sent At", true, "createdAt", res => datetime(res.createdAt)),
            field("Expire At", true, "expireAt", res => datetime(res.expireAt)),
        ]}>
    </DataTable>)
};

Table.propTypes = {
    recipient: PropTypes.string,
    reload: PropTypes.any
};

export default Table;