import React from "react";
import DataTable, {field} from "../../generic/DataTable";
import {getSMSTemplates, deleteTemplate} from "../../../service/smsTemplate";

export default function Table(props) {
    const { openPopup, setEditMode, setEditPopupData, setReload, reload } = props;

    return (
        <DataTable
            onDelete={response => deleteTemplate(response.id).then(() => setReload())}
            onChange={response => {
                openPopup();
                setEditMode(true);
                setEditPopupData(response)
            }}
            reload={reload}
            findAndCount={getSMSTemplates}
            fields={[
                field("Gate", false, "gate"),
                field("Label", false, "label"),
                field("Text", false, "smsTemplateText"),
                field("Code", false, "smsTemplateCode")
            ]}>
            name={"smsTemplates"}
        </DataTable>
    );
}
