import React, {useEffect, useState} from 'react'
import makeCancelable from "../../../lib/makeCancelable";
import * as notify from "../../../lib/notify";
import {getEntry, updateEntry, createEntry} from "../../../service/function";
import Card from "../../generic/Card";
import LoaderOverlay from "../../generic/LoaderOverlay";
import {Col, Row} from "reactstrap";
import EditorPane from "../../generic/EditorPane";
import {validateFunction} from "../../../lib/validation";
import {Redirect} from "react-router-dom";

export default function Edit(props) {
    const [item, setItem] = useState({
        key: '',
        name: '',
        type: 'fraud',
        content: '',
        isActive: false
    });

    const [redirectToTable, setRedirectToTable] = useState(false);
    const [loading, setLoading] = useState(false);
    const itemId = props.match.params.id;

    useEffect(() => {
        const cancellable = makeCancelable(loadData());
        return () => cancellable.cancel();
    }, [itemId]);

    async function loadData() {
        if (!itemId) return;
        setLoading(true);
        return getEntry(itemId).then(item => {
            return setItem(item)
        })
            .catch(e => {
                notify.showDangerToast("Error loading page id: " + itemId);
                setRedirectToTable(true);
            })
            .finally(() => setLoading(false));
    }

    function onSave(isClose = true) {
        return () => {
            const validation = validateFunction(item);
            if (validation) return notify.showDangerToast(validation);

            setLoading(true);

            (itemId ? updateEntry(itemId, item) : createEntry(item)).then(() => {
                setRedirectToTable(isClose);
                notify.showSuccessToast(`Page '${item.name}' ${itemId ? 'updated' : 'created'}`);
            }).catch((e) => {
                notify.showDangerToast(e.message || "something went wrong");
            }).finally(() => setLoading(false));
        }
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        handleFieldChange(name, value);
    }

    function handleFieldChange(name, value) {
        item[name] = value;
        setItem({...item});
    }

    function handleTemplateChange(mode, template) {
        handleFieldChange('content', template);
    }

    const isUpdate = !!itemId;
    if (redirectToTable) return <Redirect push to='/anti-fraud/function'/>;

    return (
        <Card title={isUpdate ? 'Edit function' : 'Create function'}>
            <LoaderOverlay isVisible={loading}>
                <Row>
                    <Col sm={12} md={12} lg={6} xl={6}>
                        <div className="form-group">
                            <label>Name</label>
                            <input
                                type="text"
                                className="form-control"
                                name="name"
                                placeholder="Name"
                                value={item.name}
                                onChange={handleInputChange}
                            />
                        </div>
                    </Col>
                    <Col sm={12} md={12} lg={6} xl={6}>
                        <div className="form-group">
                            <label>Type</label>
                            <select name="type" className="form-control h-48">
                                <option value={item.type}>{item.type}</option>
                            </select>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} lg={9}>
                        <div className="form-group">
                            <label>Contnet</label>
                            <EditorPane modes={['js']} js={item.content} onChange={handleTemplateChange}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="form-check">
                            <label className="form-check-label">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    name="isActive"
                                    checked={item.isActive}
                                    onChange={handleInputChange}
                                />
                                isActive
                                <i className="input-helper"/>
                            </label>
                        </div>
                    </Col>
                </Row>

                {isUpdate && <button className="btn btn-primary mr-2" onClick={onSave(false)}
                                     type="button">Update</button>}

                <button className="btn btn-primary mr-2" onClick={onSave()}
                        type="button">{isUpdate ? 'Update, close' : 'Create'}</button>

                <button className="btn" onClick={() => setRedirectToTable(true)}
                        type="button">Cancel
                </button>
            </LoaderOverlay>
        </Card>
    )
}