import React, {useState} from 'react'
import {Redirect} from 'react-router-dom'
import DataTable, {field} from "../generic/DataTable";
import {findAndCount} from "../../service/form";
import {Button} from "reactstrap";
import PropTypes from "prop-types";
import * as notify from "../../lib/notify";
import service from "../../service/form";

export default function Table(props) {
    const [selectedForm, setSelectedForm] = useState(undefined);
    const [viewForm, setViewForm] = useState(undefined);

    const copyForm = res => {
        res.key = "" + res.key + "-copy"
        res.label = "" + res.label + " Copy"

        return service.create(res)
            .then(
                () => {
                    props.reloadForms()
                    notify.showSuccessToast("Form successfully copied")
                }
            )
            .catch(() => notify.showDangerToast("Can't create copy. Probably copy already exists"))
    }

    if (selectedForm) return <Redirect push to={`/forms/edit/${selectedForm.id}`}/>;
    if (viewForm) return <Redirect push to={`/forms/view/${viewForm.id}`}/>;
    return (
        <DataTable
            filters={props.filters}
            findAndCount={findAndCount}
            fields={[
                field("Project", false, "project.label", res => res.project ? res.project.label : ''),
                field("Key", true, 'key'),
                field("Label", true, "label"),
                field("Type", true, "type")

            ]}
            name={"forms"}
            onChange={setSelectedForm}
            additionalButtons={(res) =>
                [
                    <Button color="info" size="xs" onClick={() => setViewForm(res)}><i
                        className="fa fa-eye"/></Button>,
                    <Button color="primary" size="xs" onClick={() => copyForm(res)}><i
                        className="fa fa-copy"/></Button>
                ]
            }

        />
    )
}

Table.propTypes = {
    filters: PropTypes.object,
    reloadForms: PropTypes.func
};
