import React from 'react';
import PropTypes from "prop-types";

export default class ClearButton extends React.Component {
    render() {
        return <button type="button" className={"btn btn-outline-secondary " + this.props.className} onClick={this.props.onClick}>
            <i className="fa fa-ban"/>
            &nbsp; Clear
        </button>
    }
}

ClearButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string
};