import React from 'react'
import TableCard from "../../../generic/TableCard";
import {strapi} from "../../../../lib/auth";
import {showDangerToast} from "../../../../lib/notify";
import DataTable, {field} from "../../../generic/DataTable";
import {Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import {generateJWT, getJWTLogs} from "../../../../service/S2SKeysLog";
import moment from "moment";

export default class APIKeysUser extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            start: 0,
            total: 0,
            limit: 10,
            ttl: '1d',
            reload: undefined
        };

        this.loadData = this.loadData.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
        this.handleSelectChange = this.handleSelectChange.bind(this);
    };

    componentDidMount() {
        this.loadData().catch(showDangerToast);
    }

    onPageChange(start) {
        this.setState({
            start: start
        });
    }

    async loadData() {
        const rawData = await strapi.getEntries("users");
        const total = rawData.length;
        const data = rawData.slice(this.state.start, this.state.start + Number(this.state.limit));

        this.setState({
            total: total,
            data: data
        });
    }

    handleSelectChange(e) {
        this.setState({
            ttl: e.target.value
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.start !== this.state.start) this.loadData().catch(showDangerToast);
    }

    render() {
        const {reason, reload, isPopupOpen, jwt} = this.state;
        return <TableCard title={"Generate new key"}>
            <Modal isOpen={isPopupOpen}>
                <ModalHeader>{'JWT Key'}</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="jwt_key">JWT</Label>
                            <textarea rows={4} className="form-control" id="jwt_key" value={jwt}/>
                        </FormGroup>
                        <p>
                            Copy and save your key. You will never see it again
                        </p>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => {
                        this.setState({
                            isPopupOpen: false
                        })
                    }}>Close</Button>
                </ModalFooter>
            </Modal>
            <div className={"pt-3"}>
                <div className="form-group">
                    <div className="mb-2">
                        <label>Time to live</label>
                        <select
                            className="form-control"
                            name="type"
                            onChange={this.handleSelectChange}
                            autoComplete={"false"}
                        >
                            <option value="1d">1d</option>
                            <option value="7d">7d</option>
                            <option value="30d">30d</option>
                            <option value="180d">180d</option>
                            <option value="1y">1y</option>
                            <option value="5y">5y</option>
                        </select>
                    </div>
                    <div className="mb-2">
                        <label>Reason</label>
                        <textarea rows={4} className="form-control"
                                  onChange={e => {
                                      this.setState({
                                          reason: e.target.value
                                      })
                                  }}/>
                    </div>
                    <Button color="primary" onClick={() => {
                        const {ttl} = this.state;
                        Promise.resolve().then(() => generateJWT({
                            ttl,
                            reason,
                            owner_id: this.props.match.params.id
                        })).then((jwt) => {
                            this.setState({
                                reload: !this.state.reload,
                                jwt,
                                isPopupOpen: true
                            })
                        });
                    }}>Generate</Button>
                </div>
                <p>It is a log of API keys creating. If you need to delete key, you have to block user.</p>
                <DataTable
                    findAndCount={() => getJWTLogs(0, 1000000, 'createdAt', 'desc', {
                        owner_id: this.props.match.params.id
                    })}
                    fields={[
                        field("Key", false, "jwt_log"),
                        field("Reason", false, "reason"),
                        field("Created At", false, "createdAt", data => moment(data.createdAt).format('DD.MM.YYYY')),
                        field("Created By", false, "createdBy"),
                    ]}
                    reload={reload}
                />
            </div>
        </TableCard>
    }
};