import React from 'react'
import {Redirect, Route, Switch} from "react-router-dom";
import Config from "./config/Route";

const DteRoute = () => <Switch>
    <Redirect exact from="/dte" to="/dte/config"/>
    <Route path="/dte/config" component={Config}/>
</Switch>;

export default DteRoute;
