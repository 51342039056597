import moment from "moment";

export const datetimeFull = d => d ? moment(d).format("YYYY.MM.DD HH:mm:ss") : "";

export const datetime = d => d ? moment(d).format("YYYY.MM.DD HH:mm") : "";

export const date = d => d ? moment(d).format("YYYY.MM.DD") : "";

export const time = d => d ? moment(d).format("HH:mm") : "";

export const number = (str) => {
    if(!str || str === "") return undefined;
    const parsed = parseInt(str);
    if(isNaN(parsed)) return undefined;
    return parsed;
}
export default {date, datetime, time, datetimeFull, number}