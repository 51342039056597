import {Service} from '../lib/strapi';
import {strapi} from "../lib/auth";

const service = new Service("reference");

export const getEntry = service.getEntry;

export const getReference = service.findAndCountServerSide;

export const getByCode = code => service.find(0, 1, undefined, undefined, {code});

export const insertNewReference = (kind, code, label) => strapi.createEntry('reference', {
  kind,
  code,
  label
});

export const changeReference = (id, kind, code, label) => strapi.updateEntry('reference', id, {
  id,
  kind,
  code,
  label
});

export const deleteReference = (id) => strapi.deleteEntry('reference', id);