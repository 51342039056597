import React, {useEffect, useState, useReducer} from 'react';
import {Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from 'reactstrap';
import {StringParam, useQueryParam, withDefault} from 'use-query-params';
import form from "../../../service/form";
import Button from "reactstrap/es/Button";
import * as notify from "../../../lib/notify";
import {Redirect} from "react-router-dom";
import Loader from "../../generic/Loader";
import Card from "../../generic/Card";
import Sidebar from "./Sidebar";
import BackupModal from "./tabs/backups/BackupModal";
import tabList from './tabs'
import {reducer, initialState} from './reducer'

export default function EditHead(props) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const [modal, setModal] = useState(false);
    const [hasError, setError] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useQueryParam("activeTab", withDefault(StringParam, "Parameters"));
    const [toTable, setToTable] = useState(false);

    useEffect(() => {
        loadData(props);
    }, [props.match.params.id]);

    function loadData(props) {
        const formId = props.match.params.id;
        dispatch({ type: 'setFormData', payload: { value: { id: formId } } } )
        if (formId) {
            setLoading(true);
            form.read(formId)
                .then((data) =>{
                    dispatch({
                            type: 'setFormData',
                            payload: {
                                value:
                                    {
                                        ...data,
                                        project: data.project && data.project._id,
                                        securityEnabled: !!(data.sitekey || data.secretkey)
                                    }
                            }
                    });
                    setLoading(false);
                })
                .catch(e => {
                    notify.showDangerToast(e);
                    setError(true);
                    setLoading(false);
                });
        }
    }

    const toggle = tab => { if (activeTab !== tab) setActiveTab(tab) };

    function saveBackup(e) {
        e.preventDefault();
        setModal(true)
    }

    function makeForm(e, returnBack) {
        e.preventDefault();
        !state.id ? form.create(state).then(() => {
                notify.showSuccessToast("Form created");
                if (returnBack) setToTable(true);
                else dispatch({type:'refreshFields'});
            }, e => {
                if (e.message !=="An internal server error occurred")
                    notify.showDangerToast(e.message || e);
                else
                    notify.showDangerToast("Probably, form with same key already exists")
            }
            ) :
            form.update(state.id, state).then(() => {
                    notify.showSuccessToast("Form saved");
                    if (returnBack) setToTable(true);
                }, notify.showDangerToast
            );
    }

    if (toTable) return <Redirect push to="/forms"/>;

    function buttons(isCreate) {
        return <div className="col-">
            <Button
                type="button"
                color="primary"
                className="mr-2"
                onClick={e => makeForm(e, true)}
            >
                {isCreate ? "Create" : "Save"}
            </Button>
            <Button
                type="button"
                color="primary"
                className="mr-2"
                onClick={e => makeForm(e, false)}
            >
                {isCreate ? "Create and continue" : "Save and continue"}
            </Button>
            {!isCreate &&
            <Button
                type="button"
                color="primary"
                className="mr-2"
                onClick={e => saveBackup(e)}
            >
                Make Version
            </Button>
            }
            <Button
                color="light" onClick={() => {
                (notify.showWarningToast(isCreate ? "Form creation cancelled" : "Form editing cancelled"));
                setToTable(true);
            }}
            >
                Cancel
            </Button>
        </div>
    }

    if (hasError) return <Redirect push to="/forms"/>;
    if (isLoading) return <Loader/>;
    return (
        <Card>
            {props.match.params.id &&
            <BackupModal
                isOpen={modal} _id={state.id}
                data={{
                    fields: state.fields,
                    template: state.template,
                    sitekey: state.sitekey,
                    secretkey: state.secretkey
                }}
                onClose={() => setModal(false)}
            />}
            <Row>
                <Col lg={9} md={8} sm={12}>
                    <Nav tabs>
                        {tabList({state, dispatch})
                                .filter(tab => tab.isDisplayed!== false)
                                .map(tab =>
                                    <NavItem key={`field-tabs-${tab.name}`}>
                                        <NavLink className={activeTab === tab.name && "active" ? "active" : ""}
                                                 onClick={() => toggle(tab.name)}>
                                            {tab.name}
                                        </NavLink>
                                    </NavItem>
                                )}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        {tabList({state, dispatch}).filter(tab => tab.isDisplayed !== false).map(tab => (
                            <TabPane tabId={tab.name} key={tab.name}>
                                <Row>
                                    <Col sm="12">
                                        {tab.child}
                                    </Col>
                                </Row>
                            </TabPane>
                        ))}
                    </TabContent>
                    <div className="row">
                        {buttons(!state.id)}
                    </div>
                </Col>
                <Col lg={3} md={4} sm={0} className="d-sm-none d-md-block">
                    <Sidebar formKey={state.key} activeTab={activeTab} state={state}/>
                </Col>
            </Row>
        </Card>
    );
}
