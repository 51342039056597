import {useMemo} from "react";
import {getPercentBy} from "../generic";
import useEsbCharts from "../generic/useEsbCharts";

const useMemoizeChart = (chartData = {}) => {
    const {rowsAmount = 0, correctRowsAmount = 0, uniqueRowsAmount = 0, errorRowsAmount = 0} = chartData;
    const esbStatistics = useEsbCharts(chartData, uniqueRowsAmount)
    let notUniqueRowsAmount = 0, correctRowsPercent, errorRowsPercent, uniqueRowsPercent, notUniqueRowsPercent;

    useMemo(() => {
        const getByTotalPercent = getPercentBy(rowsAmount);
        const getByValidPercent = getPercentBy(correctRowsAmount);

        notUniqueRowsAmount = correctRowsAmount - uniqueRowsAmount;

        correctRowsPercent = getByTotalPercent(correctRowsAmount);
        errorRowsPercent = getByTotalPercent(errorRowsAmount);

        uniqueRowsPercent = getByValidPercent(uniqueRowsAmount);
        notUniqueRowsPercent = getByValidPercent(notUniqueRowsAmount);
    }, [chartData]);


    return {rowsAmount, correctRowsAmount, uniqueRowsAmount, errorRowsAmount, notUniqueRowsAmount, correctRowsPercent,
        errorRowsPercent, uniqueRowsPercent, notUniqueRowsPercent, ...esbStatistics}
}

export default useMemoizeChart;