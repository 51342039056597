import React from "react";
import PropTypes from 'prop-types';

class Title extends React.Component {
    render() {

        const {title, icon, breadcrumbs} = this.props;
        return (
            <div className="row">
                <div className="col-md-12 mb-3">
                    <div className="d-flex align-items-baseline flex-wrap mt-1">
                        <h2 className="mr-4 mb-0">

                            {title}</h2>
                        <div className="d-flex align-items-baseline mt-2 mt-sm-0">
                            <i className={`fas fa-${icon} mr-1 text-muted`}/>

                            {breadcrumbs.map(item => (
                                <span key={"breadcrumbs-"+item}>
                                    <i className="fas fa-chevron-right fa-xs mr-1 text-muted"/>
                                    <small className="mb-0 mr-1">{item}</small>
                                </span>
                            ))
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Title.propTypes = {
    title: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired
};

export default Title;