import React, {Fragment, useState} from "react";
import TextSearch from "../../../generic/field/TextSearch";
import ClearButton from "../../../generic/field/ClearButton";
import DataTable, {field} from "../../../generic/DataTable";
import {getQueuesLogsData} from "../../../../service/queue_logs";
import {useLocation} from "react-router-dom";
import {datetime} from "../../../../lib/format";
import {Format} from "lead-generation-common";

import DatePicker from "../../../generic/field/DatePicker";
import {Label} from 'reactstrap';
import moment from "moment";
import {useParams} from "react-router";

const {parseMessage} = Format.queue;

const Logs = () => {
    const {orderId} = useParams()

    const [queue, setQueue] = useState(new URLSearchParams(useLocation().search).get("queue"));
    const [state, setState] = useState(undefined);
    const [type, setType] = useState(undefined);
    const [message, setMessage] = useState(undefined);
    const [daysFilter, _setDaysFilter] = useState(
        {
            from: undefined,
            to: undefined
        })
    const setDaysFilter = (type, value) => type === 'from'
        ? _setDaysFilter(filter => ({...filter, from: value}))
        : _setDaysFilter(filter => ({...filter, to: value}))

    const filters = {
        updatedAt_gte: daysFilter.from,
        updatedAt_lte: moment(daysFilter.to).clone().endOf('day').toDate(),
        entityId: orderId,
        queue_contains: queue,
        state_contains: state,
        type_contains: type,
        message_contains: message,
    };
    return (
        <Fragment>
            <div className="form-inline mt-2">
                <TextSearch value={type} className="mb-2 mr-2 form-control-sm" onSearch={setType} placeholder="Type"/>
                <TextSearch value={queue} className="mb-2 mr-2 form-control-sm" onSearch={setQueue}
                            placeholder="Queue"/>
                <TextSearch value={message} className="mb-2 mr-2 form-control-sm" onSearch={setMessage}
                            placeholder="Message / Error"/>

                <TextSearch value={state} className="mb-2 mr-2 form-control-sm" onSearch={setState}
                            placeholder="State"/>

                <DatePicker placeholder={'Updated from'} classNames={'form-control-sm mb-2'}
                            onDateChange={(val) => setDaysFilter('from', val)}
                            selectedDay={daysFilter.from}
                />
                <Label size={'sm'} className={'mb-2 mr-2 ml-2'}> — </Label>
                <DatePicker placeholder={'Updated to'} classNames={'form-control-sm mb-2'}
                            onDateChange={(val) => setDaysFilter('to', val)}
                            selectedDay={daysFilter.to}
                />
                <ClearButton className="mb-2 ml-2 btn-sm" onClick={() => {
                    [setQueue, setState, setType, setMessage].forEach(f => f(() => undefined))
                    delete filters.queue_contains;
                    delete filters.state_contains;
                    delete filters.type_contains;
                    delete filters.message_contains;
                    setDaysFilter('from', moment().subtract(5, 'day').toDate())
                    setDaysFilter('to', moment().toDate())

                }}/>
            </div>
            <DataTable
                filters={filters}
                findAndCount={getQueuesLogsData}
                fields={[
                    field("Log Type", true, 'type'),
                    field("Queue Name", true, "queue"),
                    field("Message/Error", false, 'message', (res) => parseMessage(res.message).error || parseMessage(res.message).message),
                    field("Submission state", true, 'state'),
                    field("Tries", true, 'tries', (res) =>
                        res.tries ? res.tries : 'no-tries'),
                    field("Updated at", true, "updatedAt", res => datetime(res.updatedAt)),
                    field("Created at", true, "createdAt", res => datetime(res.createdAt)),
                ]}
                sortField="createdAt"
                sortDir="desc"
                name="orderqueueslogs"
            />
        </Fragment>
    )
}

export default Logs;