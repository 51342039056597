import React from 'react'
import ProjectInput from "../../../generic/field/ProjectInput";
import PropTypes from 'prop-types';

export default function Edit({state, dispatch, ...rest}) {
    const setField = (e) =>{
        e.persist && e.persist();
        dispatch({
            type:'setParameters',
            payload: {
                value: e
            }
        })
    }
    return (
        <div>
            <h4 className="card-title"> {!state.id ? "Create Form" : "Edit Form"}</h4>
            <form className="forms-sample">
                <div className="form-group">
                    <label htmlFor="Key">Key</label>
                    <input
                        id="Key"
                        type="key"
                        className="form-control"
                        name="key"
                        placeholder="Key"
                        value={state.key}
                        onChange={setField}
                        autoComplete="false"
                    />
                </div>
                <div className="form-group">
                    <label>Label</label>
                    <input
                        type="text"
                        className="form-control"
                        name="label"
                        placeholder="Label"
                        value={state.label}
                        onChange={setField}
                        autoComplete={"false"}
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="Project">Project</label>
                    <ProjectInput selectedItemId={state.project}
                                  onSelect={value => setField({target: {name: "project", value}})}/>
                </div>
                <div className="form-group">
                    <label>Form Type</label>
                    <select
                        className="form-control"
                        name="type"
                        value={state.type}
                        onChange={setField}
                        autoComplete={"false"}
                    >
                        <option value="simple">Simple</option>
                        <option value="integrationForm">Integration Form</option>
                    </select>
                </div>
            </form>
        </div>
    )
}

Edit.propTypes = {
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired
}