import {CopyToClipboard} from "react-copy-to-clipboard";
import * as notify from "../../lib/notify";
import React, {Fragment} from "react";
import PropTypes from "prop-types";

function CopyLink(props) {
    return <Fragment><a className={props.className} rel="noopener noreferrer"  target="_blank" href={props.href}>{props.text || props.href}</a>
        &nbsp; <CopyToClipboard text={props.href} onCopy={() => notify.showSuccessToast("Url copied to clipboard")}>
            <button className="btn btn-link p-0 mb-1"> <i className="fa fa-copy"/></button>
        </CopyToClipboard></Fragment>
}

CopyLink.propTypes = {
    className: PropTypes.string,
    href: PropTypes.string.isRequired,
    text: PropTypes.node,
};

export default CopyLink;