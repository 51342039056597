import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Col, FormGroup, Label, Row} from "reactstrap";

function AgeVerification({enabled, form: {register, errors, control}}) {
    return <Fragment>
        <Row>
            <Col md={12} lg={6}>
                <FormGroup>
                    <Label for="project">Option</Label>
                    <select className={`form-control ${(errors.av && errors.av.avOption) ? "is-invalid" : ""}`} name="av.avOption"
                            ref={register}>
                        <option value="">None</option>
                        <option value="idxBefore">IDX before registration</option>
                        <option value="idxAfterAsync">IDX after registration</option>
                        <option value="idxDocuments">IDX documents</option>
                        <option value="idxAfterAsyncOrDocs">IDX after or documents</option>
                    </select>

                    {errors.av && errors.av.avOption && <div className="invalid-feedback">{errors.av.avOption.message}</div>}
                </FormGroup>
            </Col>
            <Col md={12} lg={6}>
                <FormGroup>
                    <Label for="project">Default</Label>
                    <select className={`form-control ${(errors.av && errors.av.defaultAV) ? "is-invalid" : ""}`} name="av.defaultAV"
                            ref={register}>
                        <option value="">None</option>
                        <option value="face">Face Recognition</option>
                        <option value="document">ID Documents</option>
                    </select>

                    {errors.av && errors.av.defaultAV && <div className="invalid-feedback">{errors.av.defaultAV.message}</div>}
                </FormGroup>
            </Col>
        </Row>
    </Fragment>
}

AgeVerification.propTypes = {
    form: PropTypes.object.isRequired,
    enabled: PropTypes.bool.isRequired
}

export default AgeVerification;