import React from "react";
import {Button, Row, Input, Modal, ModalBody, ModalFooter, ModalHeader, Col} from 'reactstrap';
import {datetime} from "../../../../lib/format";

const fieldNameAdapter = {
    fileName: 'File Name',
    sftpClient: 'Partner',
    userId: 'User Id',
    rowsAmount: 'Total',
    correctRowsAmount: 'Valid',
    errorRowsAmount: 'Invalid',
    uniqueRowsAmount: 'Unique',
    dataFrom: 'Data From',
    accountCreated: {name: 'Account Created', type: 'accounts'},
    accountFound: {name: 'Account Found', type: 'accounts'},
    createdAt: 'Created At',
    updatedAt: 'Updated At',
    processed: 'ESB Processed',
    processing: 'ESB Processing',
    removed: 'ESB Removed',
    error: 'ESB Error'
};

const fields = ['fileName', 'sftpClient', 'status', 'type', 'message', 'userId', 'dataFrom', 'createdAt', 'updatedAt',
    'accountCreated', 'accountFound', 'rowsAmount', 'correctRowsAmount', 'errorRowsAmount', 'uniqueRowsAmount',
    'processed', 'processing', 'removed', 'error'];

const capitalizeWord = (string) => string[0].toUpperCase() + string.slice(1);

const checkFieldType = (it, item) => {
  let label;

  if (typeof fieldNameAdapter[it] === 'object' && fieldNameAdapter[it].type !== item.type) return null

  if (fieldNameAdapter[it] && typeof fieldNameAdapter[it] === 'string') label = fieldNameAdapter[it]
  if (fieldNameAdapter[it] && typeof fieldNameAdapter[it] === 'object') label = fieldNameAdapter[it].name
  if (!fieldNameAdapter[it]) label = capitalizeWord(it)

  return <Item key={it} field={label} value={fieldValueFill(it, item)} />
};

const fieldValueFill = (it, item) => {
    if (it === 'createdAt' || it === 'updatedAt') return datetime(item[it])
    return item[it]
};

const Item = ({field, value}) => <Col xs={6}>
    <label>{field}</label>
    <Input type="text" disabled={true} name="kind" className="mb-2" value={value} />
</Col>;

export default function ModalDetail({modalOpen, setModalOpen, item}) {

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <Modal isOpen={modalOpen}>
            <ModalHeader>File logs detail</ModalHeader>
            <ModalBody>
                <Row>
                    {item && fields.map((it) => checkFieldType(it, item))}
                </Row>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={closeModal}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
}