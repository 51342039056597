import React, {useState} from 'react'
import {Button, Input} from "reactstrap";
import * as notify from "../../../../../lib/notify";
import * as FieldType from "./model/FieldType";
import * as PropTypes from "prop-types";

export default function Edit({state, dispatch, ...rest}) {
    const handleFieldsChange = (field, fieldId) => {
        let fields =  [...state.fields];
        if (fieldId || fieldId === 0) {
            fields[fieldId] = field;
        } else
            fields.push(field);
        dispatch({
            payload:{
                value: fields
            },
            type: 'setFields'
        })
    }

    const fieldData = rest.fieldData ? {...rest.fieldData} : {};

    const [field, setField] = useState({
        key: fieldData.key || "",
        name: fieldData.name || "",
        inputMask: fieldData.inputMask || "",
        placeholder: fieldData.placeholder || "",
        type: fieldData.type || "textfield",
        referenceType: fieldData.referenceType || null,
        hidden: fieldData.hidden || false,
        defaultValue: fieldData.defaultValue || "",
        tableView: fieldData.tableView !== false,
        conditional: fieldData.conditional || "",
        required: fieldData.required || false,
        customError: fieldData.customError || "",
        validationPattern: fieldData.validationPattern || "",
        validationCustom: fieldData.validationCustom || "",
        customDefaultValue: fieldData.customDefaultValue || "",
        calculateValue: fieldData.calculateValue || "",
        tags: fieldData.tags || [],
        uniqueError: fieldData.uniqueError || "",
    });

    function refreshData() {
        setField({
            key: "",
            name: "",
            inputMask: "",
            placeholder: "",
            type: "",
            referenceType: null,
            hidden: false,
            defaultValue: "",
            tableView: true,
            conditional: "",
            required: false,
            customError: "",
            validationPattern: "",
            validationCustom: "",
            customDefaultValue: "",
            calculateValue: "",
            tags: [],
            uniqueError: ""
        });
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        const tags = field.tags;
        if (name === 'validateNotExists' || name === 'submitOnValid') {
            value ?
                setField({...field, tags: [...tags, `${name}`]})
                // if value === false then tags contain chosen field
                : setField({...field, tags: tags.filter((item, id) => id !== tags.indexOf(`${name}`))});
            return;
        }

        setField({...field, [name]: value});
    }

    const showUniqueError = field.tags.includes('validateNotExists');

    return (
        <div className="card">
            <div className="card-body">
                <h4 className="card-title"> {!rest.fieldData ? "Create Field" : "Edit Field"}</h4>
                <form className="forms-sample">

                    <div className="accordion" id="accordion" role="tablist">
                        <div className="card">
                            <div className="card-header" role="tab" id="heading-1">
                                <h6 className="mb-0">
                                    <a data-toggle="collapse" href="#collapse-1" aria-expanded="true"
                                       aria-controls="collapse-1">
                                        Description
                                    </a>
                                </h6>
                            </div>
                            <div id="collapse-1" className="collapse show" role="tabpanel" aria-labelledby="heading-1"
                                 data-parent="#accordion">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12 col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="Key">Key*</label>
                                                <input
                                                    id="Key"
                                                    type="text"
                                                    className="form-control"
                                                    name="key"
                                                    placeholder="Key"
                                                    value={field.key}
                                                    onChange={handleInputChange}
                                                    autoComplete="false"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="Name">Name*</label>
                                                <input
                                                    type="text"
                                                    id="Name"
                                                    className="form-control"
                                                    name="name"
                                                    placeholder="Name"
                                                    value={field.name}
                                                    onChange={handleInputChange}
                                                    autoComplete={"false"}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="Type">Type*</label>
                                                <Input
                                                    id="Type"
                                                    type="select"
                                                    className="form-control form-control-lg"
                                                    name="type"
                                                    value={field.type}
                                                    onChange={handleInputChange}
                                                >
                                                    {FieldType.All.map(x => <option key={x.key}
                                                                                    value={x.key}>{x.label}</option>)}
                                                </Input>
                                            </div>

                                            {FieldType.hasReferences(field.type) && <div className="form-group">
                                                <label htmlFor="Type">Reference Type</label>
                                                <Input
                                                    id="RefType"
                                                    type="select"
                                                    className="form-control form-control-lg"
                                                    name="referenceType"
                                                    value={field.referenceType}
                                                    onChange={handleInputChange}
                                                    defaultValue=""
                                                >
                                                    <option value="" disabled>Choose reference type</option>
                                                    {FieldType.findByKey(field.type).referenceTypes.map(x => <option
                                                        key={x.key} value={x.key}>{x.label}</option>)}
                                                </Input>
                                            </div>}
                                        </div>
                                        <div className="col-md-12 col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="InputMask">Input mask</label>
                                                <input
                                                    id="InputMask"
                                                    type="text"
                                                    className="form-control"
                                                    name="inputMask"
                                                    placeholder="Input mask"
                                                    value={field.inputMask}
                                                    onChange={handleInputChange}
                                                    autoComplete="false"
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="Placeholder">Placeholder</label>
                                                <input
                                                    id="Placeholder"
                                                    type="text"
                                                    className="form-control"
                                                    name="placeholder"
                                                    placeholder="Placeholder"
                                                    value={field.placeholder}
                                                    onChange={handleInputChange}
                                                    autoComplete={"false"}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label htmlFor="Placeholder">Table View</label>
                                                <div className="form-check form-check-primary ml-2">
                                                    <label className="form-check-label">
                                                        <input
                                                            id="TableView"
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="tableView"
                                                            value={field.tableView}
                                                            checked={field.tableView ? 'checked' : ''}
                                                            onChange={handleInputChange}
                                                        />
                                                        <i className="input-helper">&nbsp;</i>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12 col-lg-6">
                                            <div className="form-group">
                                                <label htmlFor="Placeholder">Validate Not Exists</label>
                                                <div className="form-check form-check-primary ml-2">
                                                    <label className="form-check-label">
                                                        <input
                                                            id="ValidateNotExists"
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="validateNotExists"
                                                            value={showUniqueError}
                                                            checked={showUniqueError ? 'checked' : ''}
                                                            onChange={handleInputChange}
                                                        />
                                                        <i className="input-helper">&nbsp;</i>
                                                    </label>
                                                </div>
                                            </div>
                                            {showUniqueError && (<div className="form-group">
                                                <label htmlFor="UniqueError">Unique Error</label>
                                                <input
                                                    id="UniqueError"
                                                    type="text"
                                                    className="form-control"
                                                    name="uniqueError"
                                                    placeholder="Non-unique error message"
                                                    value={field.uniqueError}
                                                    onChange={handleInputChange}
                                                    autoComplete={"false"}
                                                />
                                            </div>)}
                                        </div>
                                        <div className="md-12 lg-6">
                                            <div className="form-group">
                                                <label htmlFor="Placeholder">Submit On Valid</label>
                                                <div className="form-check form-check-primary ml-2">
                                                    <label className="form-check-label">
                                                        <input
                                                            id="SubmitOnValid"
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="submitOnValid"
                                                            value={field.tags.includes('submitOnValid')}
                                                            checked={field.tags.includes('submitOnValid') ? 'checked' : ''}
                                                            onChange={handleInputChange}
                                                        />
                                                        <i className="input-helper">&nbsp;</i>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" role="tab" id="heading-2">
                                <h6 className="mb-0">
                                    <a className="collapsed" data-toggle="collapse" href="#collapse-2"
                                       aria-expanded="false" aria-controls="collapse-2">
                                        Validation
                                    </a>
                                </h6>
                            </div>
                            <div id="collapse-2" className="collapse" role="tabpanel" aria-labelledby="heading-2"
                                 data-parent="#accordion">
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="Required">Required</label>
                                        <div className="form-check form-check-primary ml-2">
                                            <label className="form-check-label">
                                                <input
                                                    id="Required"
                                                    type="checkbox"
                                                    className="form-control"
                                                    name="required"
                                                    value={field.required}
                                                    checked={field.required ? 'checked' : ''}
                                                    onChange={handleInputChange}
                                                />
                                                <i className="input-helper">&nbsp;</i>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="CustomError">Custom Error</label>
                                        <input
                                            id="CustomError"
                                            type="text"
                                            className="form-control"
                                            name="customError"
                                            placeholder="CustomError"
                                            value={field.customError}
                                            onChange={handleInputChange}
                                            autoComplete={"false"}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="ValidationPattern">Regex Pattern</label>
                                        <input
                                            id="ValidationPattern"
                                            type="text"
                                            className="form-control"
                                            name="validationPattern"
                                            placeholder="ValidationPattern"
                                            value={field.validationPattern}
                                            onChange={handleInputChange}
                                            autoComplete="false"
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="ValidationCustom">Validation Custom</label>
                                        <textarea
                                            id="ValidationCustom"
                                            type="text"
                                            className="form-control"
                                            name="validationCustom"
                                            placeholder="/*** Example Code ***/ valid = (input && input !== '42') ? true : 'Must not equal 42';"
                                            value={field.validationCustom}
                                            onChange={handleInputChange}
                                            autoComplete={"false"}
                                            rows={4}
                                        />
                                    </div>
                                    <p className="card-description">
                                        You must assign the <b>valid</b> variable as either true or an error message if
                                        validation fails.

                                        The global variables <b>input, component, and valid</b> are provided.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" role="tab" id="heading-3">
                                <h6 className="mb-0">
                                    <a className="collapsed" data-toggle="collapse" href="#collapse-3"
                                       aria-expanded="false" aria-controls="collapse-3">
                                        Visibility
                                    </a>
                                </h6>
                            </div>
                            <div id="collapse-3" className="collapse" role="tabpanel" aria-labelledby="heading-3"
                                 data-parent="#accordion">
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="Hidden">Hidden</label>
                                        <div className="form-check form-check-primary ml-2">
                                            <label className="form-check-label">
                                                <input
                                                    id="Hidden"
                                                    type="checkbox"
                                                    className="form-control"
                                                    name="hidden"
                                                    value={field.hidden}
                                                    checked={field.hidden ? 'checked' : ''}
                                                    onChange={handleInputChange}
                                                />
                                                <i className="input-helper">&nbsp;</i>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="Conditional">Conditional</label>
                                        <textarea id="Conditional"
                                                  type="text"
                                                  className="form-control"
                                                  name="conditional"
                                                  placeholder="/*** Example Code ***/ show = (data['mykey'] > 1);"
                                                  value={field.conditional}
                                                  onChange={handleInputChange}
                                                  autoComplete="false" rows="4"/>
                                    </div>
                                    <p className="card-description">
                                        You must assign the <b>show</b> variable as either true or false.
                                        The global variable <b>data</b> is provided
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-header" role="tab" id="heading-4">
                                <h6 className="mb-0">
                                    <a className="collapsed" data-toggle="collapse" href="#collapse-4"
                                       aria-expanded="false" aria-controls="collapse-4">
                                        Value and Calculation
                                    </a>
                                </h6>
                            </div>
                            <div id="collapse-4" className="collapse" role="tabpanel" aria-labelledby="heading-4"
                                 data-parent="#accordion">
                                <div className="card-body">
                                    <div className="form-group">
                                        <label htmlFor="DefaultValue">Default Value</label>
                                        <input
                                            id="DefaultValue"
                                            type="text"
                                            className="form-control"
                                            name="defaultValue"
                                            placeholder="Default value"
                                            value={field.defaultValue}
                                            onChange={handleInputChange}
                                            autoComplete={"false"}
                                        />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="CustomDefaultValue">Calculated default value</label>
                                        <textarea
                                            id="CustomDefaultValue"
                                            type="text"
                                            className="form-control"
                                            name="customDefaultValue"
                                            placeholder="/*** Example Code ***/ value = query.someVariableFromUrl;"
                                            value={field.customDefaultValue}
                                            onChange={handleInputChange}
                                            autoComplete={"false"}
                                            rows={4}
                                        />
                                    </div>
                                    <p className="card-description">
                                        You must assign the <b>value</b> variable

                                        The global variables <b>data, query</b> are provided.
                                    </p>
                                    <div className="form-group">
                                        <label htmlFor="CalculateValue">Calculated value (after form creation)</label>
                                        <textarea
                                            id="CalculateValue"
                                            type="text"
                                            className="form-control"
                                            name="calculateValue"
                                            placeholder="/*** Example Code ***/ value = data.gender=='female';"
                                            value={field.calculateValue}
                                            onChange={handleInputChange}
                                            autoComplete={"false"}
                                            rows={4}
                                        />
                                    </div>
                                    <p className="card-description">
                                        You must assign the <b>value</b> variable

                                        The global variables <b>data, query</b> are provided.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr/>

                    <div className="row justify-content-between">
                        <div className="col-">
                            <Button
                                color="primary"
                                className="mr-2"
                                onClick={() => {
                                    if (!field.key || !field.type || !field.name)
                                        return notify.showDangerToast('Not all required fields are filled');
                                    handleFieldsChange(field, fieldData.id);
                                    refreshData();
                                    rest.setEdit([false, undefined]);
                                }}
                            >
                                Save field
                            </Button>
                            <Button
                                className="mr-2"
                                color="light" onClick={() => {
                                refreshData();
                                rest.setEdit([false, undefined]);
                                (notify.showWarningToast("Unsaved data discarded"))
                            }}
                            >
                                Cancel
                            </Button>
                            <Button
                                className="mr-2"
                                color="danger" onClick={() => {
                                dispatch({
                                    type: 'removeField',
                                    payload: {
                                        value: field.key
                                    }
                                })
                                rest.setEdit([false, undefined]);
                                (notify.showWarningToast("Field will be removed after save"))
                            }}
                            >
                                Remove
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

Edit.propTypes = {
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    setEdit: PropTypes.func.isRequired,
    fieldData: PropTypes.object
};


