import jwtDecode from "jwt-decode";
import {getCookie, removeCookie, setCookie} from "./session";
import {validateCredentials, validatePasswordChange} from "./validation";

import {remote} from "./strapi";

/**
 * Backward compatibility
 * @type {Strapi}
 */
export const strapi = remote;

export const login = async (email, password) => {

    const validationError = validateCredentials(email, password);
    if (validationError) return Promise.reject(validationError);

    return strapi.login(email, password).then(res => {
        if (!res && !res.jwt) return Promise.reject(res);
        setCookie("jwt", res.jwt);
        window.localStorage.setItem("user", JSON.stringify({
            username: res.user.username,
            role: res.user.role.name,
            email: res.user.email,
            id: res.user.id
        }));
        return res;
    }, (e)=>e);
};

export const changePassword = async (password,confirm) => {
    const validationError = validatePasswordChange(password,confirm);
    if (validationError) return Promise.reject(validationError);
    const userId = getUserFromSession().id;
    return strapi.getEntry("users", userId).then(result => {
            result.password = password;
            return strapi.updateEntry("users", userId, result);
        }
    );

};

export const getUserFromSession = () => {
    const userString = window.localStorage.getItem("user");
    return (userString && JSON.parse(userString)) || {};
};

export const logout = () => {
    removeCookie("jwt");
    window.localStorage.removeItem("user");

    // to support logging out from all windows
    window.localStorage.setItem("logout", Date.now());
};

export const getJwt = () => getCookie("jwt");

/*
Проверка на наличие JWT токена и его актуальность. Проверка только локальная, без запроса к серверу.
 */
export const isAuthenticated = ctx => {
    const token = getJwt(ctx);
    if (!token) return false;
    const decoded = jwtDecode(token);
    var dateNow = new Date();
    return (decoded.exp * 1000) > dateNow.getTime();
};