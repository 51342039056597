import {Service} from '../lib/strapi';
import {strapi} from "../lib/auth";

const service = new Service("email-domains-list");
export const getEmailDomainsList = service.findAndCountServerSide;
export const createEntry = (domain, type) => strapi.createEntry('email-domains-list', {
    domain,
    type
});
export const updateEntry = async (id, domain, type) => {
    const entry = {
        id,
        domain,
        type
    };
    return strapi.updateEntry('email-domains-list', id, entry);
};
export const deleteEntry = id => service.deleteEntry(id);

export const deleteMultiple = ids => service.deleteMultiple(ids);