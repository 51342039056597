import React from "react";
import useEsbCharts from "../generic/useEsbCharts";
import LoaderOverlay from "../../../generic/LoaderOverlay";
import useFetchStatistics from "../useFetchStatistics";
import {getStatistics as getOrderStatistics} from "../../../../service/orders";
import {getStatistics as getConsumerStatistics} from "../../../../service/consumers";
import EsbStatistics from "../generic/components/EsbStatistics";

const defineStatisticsFn = (type) => {
    if (type === 'accounts') return getConsumerStatistics
    if (type === 'orders') return getOrderStatistics
    return () => Promise.reject()
}

const OutGraph = (props) => {
    const [chartData, isLoading] = useFetchStatistics({...props, getStatistics: defineStatisticsFn(props.type)})
    const statistics = useEsbCharts(chartData, chartData.rowsAmount);

    return  <LoaderOverlay isVisible={isLoading}>
        <div className="tree">
            <div className="main-branch">
                <div>
                    <div className="branch__name">
                        <div className="fz-18">Total</div>
                        <div><b>{chartData.rowsAmount}</b></div>
                    </div>
                    <EsbStatistics {...statistics} type={props.type} />
                </div>
            </div>
        </div>
    </LoaderOverlay>
}

export default OutGraph;