import React from 'react'
import TableCard from "../../generic/TableCard";
import Table from "./Table";
import {Link} from "react-router-dom";
import {getUserFromSession, strapi} from "../../../lib/auth";
import {showDangerToast} from "../../../lib/notify";
import Pager from "../../generic/Pager";

export default class AdministrationUser extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            start: 0,
            total: 0,
            limit: 10,
            currentUser: getUserFromSession()
        };

        this.loadData = this.loadData.bind(this);
        this.onPageChange = this.onPageChange.bind(this);
    };

    componentDidMount() {
        this.loadData().catch(showDangerToast);
    }

    onPageChange(start) {
        this.setState({
            start: start
        });
    }

    async loadData() {
        const rawData = await strapi.getEntries("users");
        const total = rawData.length;
        const data = rawData.slice(this.state.start, this.state.start + Number(this.state.limit));

        this.setState({
            total: total,
            data: data
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.start !== this.state.start) this.loadData().catch(showDangerToast);
    }

    render() {
        const {currentUser} = this.state;
        return <TableCard title={"Users"}>
            {(currentUser.role !== 'Profile manager') &&
            <div className={"row justify-content-start"} style={{margin: 10}}>
                <div className={"col-"}>
                    <Link className="btn btn-primary" to="/administration/user/edit">
                        <i className="fa fa-plus"/>
                        &nbsp; Add user
                    </Link>
                </div>
            </div>}
            <div className={"pt-3"}>
                <div className="table-responsive">
                    <table className="table table-striped table-bordered table-hover">
                        <thead>
                        <tr>
                            <th style={{width: "150px"}}>
                                ID
                            </th>
                            <th style={{width: '50px'}}>
                                Username
                            </th>
                            <th style={{width: '200px'}}>
                                Email
                            </th>
                            <th style={{width: "100px"}}>
                                Role
                            </th>
                            <th style={{width: "100px"}}>
                                Is blocked
                            </th>
                        </tr>
                        </thead>
                        <Table users={this.state.data}/>
                    </table>
                    <div className={"mt-2"}>
                        <Pager
                            start={this.state.start}
                            total={this.state.total}
                            limit={Number(this.state.limit)}
                            onPageChange={this.onPageChange}
                            name={'user'}
                        />
                    </div>
                </div>
            </div>
        </TableCard>
    }
};