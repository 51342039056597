import React from 'react'
import {Route, Switch} from "react-router-dom";
import Edit from "./Edit";
import Index from "./Index";

const PageRoute = () => <Switch>
    <Route exact path="/pages" component={Index}/>
    <Route path="/pages/edit/:id" component={Edit}/>
    <Route path="/pages/edit" component={Edit}/>
</Switch>;

export default PageRoute;
