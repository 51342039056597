import {Service, apiUrl} from '../lib/strapi';

const entity = "exports";
const service = new Service(entity);
export const getData = service.findAndCountServerSide;

export const deleteEntry = service.deleteEntry;

export const downloadLink = data => `${apiUrl}/${entity}/download/${data.id}/${data.filename}`;

export const deleteMultiple = ids => service.deleteMultiple(ids);