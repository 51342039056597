import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import PropTypes from "prop-types";
import moment from "moment";
import { ViewHead } from "./ViewHead";
import form from "../../../../../../service/form";
import * as notify from "../../../../../../lib/notify";
import { Redirect } from "react-router-dom";

export const ViewBackupModal = (props) => {
    // item is Backup object, previousItem is previous form, formRest is object from rest backup form field
    const {
        onClose,
        isOpen,
        btn,
        previousItem,
        item,
        item: {
            id,
            fields,
            template,
            sitekey,
            secretkey,
            createdAt,
            user,
            form: {
                key,
                label,
                project,
                ...formRest
            }
        }
    } = props;
    const [toTable, setToTable] = useState(false);

    async function updateForm(e) {
        e.preventDefault();

        try {
            if (btn === 'Use Release') {
                const name = `Before Release - ${moment(Date.now()).format("DD.MM.YYYY HH:mm:ss")}`;
                await form.createBackup(previousItem.id, { ...previousItem, name });

                notify.showSuccessToast("Form backed up");
            }

            await form.update(previousItem.id, { 
                project: (btn === 'Use Release') ? previousItem.project : project,
                backupId: id,
                key,
                label,
                template,
                sitekey,
                secretkey,
                fields,
                type: formRest.type
            });

            notify.showSuccessToast("Form saved");
            setToTable(true);
        } catch (e) {
            notify.showDangerToast(e)
        }
    }

    if (toTable) {
        return <Redirect push to="/forms"/>;
    }

    return (
        <Modal isOpen={isOpen} toggle={onClose} className={props.className} size={'lg'}>
            <ModalHeader>
                <div>Form <b>{`"${label}"`}</b> backup</div>
                <div>{`${moment(createdAt).format("YYYY.MM.DD HH:mm:ss")}`}</div>
                <div>{`${user.username}`}</div>
            </ModalHeader>
            <ModalBody>
                <ViewHead data={item}/>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={(e) => {
                    updateForm(e);
                    onClose();
                }}
                >
                    {btn}
                </Button>
                <Button color="secondary" onClick={onClose}>Close</Button>
            </ModalFooter>
        </Modal>
    );
};


ViewBackupModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    item: PropTypes.object.isRequired,
    btn: PropTypes.string,
    previousItem: PropTypes.object,
    onClose: PropTypes.func.isRequired
};

ViewBackupModal.defaultProps = {
    btn: 'Use Backup'
}
