import React, {useState} from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import './badge.css'
import PropTypes from "prop-types";
import EditForm from "./EditForm";
import {basicCreateOrUpdateEntry} from "../../../service/basicApiInteractions";
import {showDangerToast, showSuccessToast} from "../../../lib/notify";

const EditModal = (props) => {
    const [alert, setAlert] = useState({...props.alert})
    const createOrUpdate = () => {
        if (!alert.name){
            showDangerToast('Name is required');
            return;
        }
        basicCreateOrUpdateEntry('alerts', alert)
            .then(() => {
                showSuccessToast('Success')
                props.triggerModal()
            })
            .catch(showDangerToast)
    }
    return (
        <Modal isOpen={props.isOpened} className={'modal-lg'} toggle={props.triggerModal}>
            <ModalHeader>{alert.id ? "Edit alert contact" : "Create alert contact"}</ModalHeader>
            <ModalBody>
                <EditForm alert={{...alert}} setAlert={setAlert} />
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={createOrUpdate}>
                    Save
                </Button>
                <Button color="secondary" onClick={props.triggerModal}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>

    )
}

export default EditModal;

EditModal.propTypes = {
    alert: PropTypes.object.isRequired,
    isOpened: PropTypes.bool.isRequired,
    triggerModal: PropTypes.func.isRequired
}