import React, {useEffect, useState} from 'react';
import TableCard from "../../generic/TableCard";
import {Link, useHistory} from "react-router-dom";
import Table from "./Table";
import {deleteEntry} from '../../../service/function';

export default function Function(props) {
    const history = useHistory();
    const [reload, setReload] = useState(false);
    useEffect(() => setReload(!reload), [props.reload]);

    return <TableCard title={"Function"}>
        <Link className="btn btn-primary mb-2 mr-2" to={`/anti-fraud/function/edit`}>
            <i className="fa fa-plus"/>
            &nbsp; Add
        </Link>
        <Table
            onDelete={res => deleteEntry(res.id).then(() => setReload(!reload))}
            onChange={res => history.push(`/anti-fraud/function/edit/${res.id}`)}
            reload={reload}
        />
    </TableCard>
}