import PropTypes from "prop-types";
import React, {Fragment, useState} from "react";
import {Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";
import AceEditor from "react-ace";

const aceModes = {
    "js": "javascript"
};

function EditorPane(props) {
    const [activeTab, setActiveTab] = useState(props.modes[0])

    return (<Fragment><Nav pills>
        {props.modes.map(tab => <NavItem key={tab}>
            <NavLink className={activeTab === tab ? "active" : ""}
                     onClick={() => setActiveTab(tab)}>
                {tab.toUpperCase()}
            </NavLink>
        </NavItem>)}
    </Nav>
    <TabContent activeTab={activeTab} className="p-0">
        {props.modes.map(tab => (
            <TabPane tabId={tab} key={tab}>
                <AceEditor
                    width="100%"
                    mode={aceModes[tab] || tab}
                    theme="xcode"
                    name="template"
                    onChange={x => props.onChange(tab, x)}
                    value={props[tab]}
                    editorProps={{$blockScrolling: true}}
                    setOptions={{useWorker: false}}
                    tabSize={2}
                />
            </TabPane>
        ))}
    </TabContent></Fragment>);
}

EditorPane.propTypes = {
    html: PropTypes.string,
    css: PropTypes.string,
    js: PropTypes.string,
    modes: PropTypes.arrayOf(PropTypes.oneOf(["html", "css", "js"])).isRequired,
    onChange: PropTypes.func.isRequired
};

EditorPane.defaultProps = {
    modes: ["html", "css", "js"]
}

export default EditorPane;