import {validateCreateUser, validateUpdateUser, validatePasswordChange} from "../lib/validation";
import {strapi} from "../lib/auth";

export const create = async (username, email, password, confirmPassword, roleId, partner) => {
    const validationError = validateCreateUser(username, email, password, confirmPassword, roleId);
    if (validationError) return Promise.reject(validationError);

    const entry = {
        username: username,
        email: email.toLowerCase(),
        password: password,
        partner,
        role: {
            _id:roleId
        }
    };

    return strapi.createEntry('users', entry);
};

export const read = async id => strapi.getEntry("users", id).then(user => ({
    id: user.id,
    username: user.username,
    email: user.email,
    roleId: user.role.id,
    blocked: user.blocked,
    partner: user.partner
}));

export const update = async (id, username, email, password, confirmPassword, roleId, blocked, partner) => {

    const validationError = validateUpdateUser(username, email, password, confirmPassword, roleId);
    if (validationError) return Promise.reject(validationError);

    const entry = {
        id: id,
        username: username,
        email: email.toLowerCase(),
        role: roleId,
        blocked: blocked,
        partner
    };

    if (password && password !== "") {
        const validationError = validatePasswordChange(password,confirmPassword);
        if (validationError) return Promise.reject(validationError);
        else entry.password = password;
    }

    return strapi.updateEntry("users", id, entry);
};

export default {create, read, update};