import React, {Fragment, useState} from 'react'
import DataTable, {field} from "../../generic/DataTable";
import {findAndCount} from "../../../service/submission";
import PropTypes from "prop-types";
import ItemModal from "./ItemModal";
import {datetime} from "../../../lib/format";

export default function Table(props) {
    const [selected, setSelected] = useState(undefined);
    const [modal, setModal] = useState(false);
    const filters = {"form":props.formId};
    const formFields = props.fields;
    const tableFields = formFields.filter(x => x.tableView).map(x => field(x.name, true, "data." + x.key));

    return <Fragment>
        {selected && <ItemModal isOpen={modal} fields={props.fields} item={selected} onClose={() => setModal(false)}/>}
        <DataTable
            filters={filters}
            findAndCount={findAndCount}
            fields={tableFields.concat([
                field("Created", true, "createdAt", res => datetime(res.createdAt))
            ])}
            name={"form_submissions"}
            sortField="createdAt"
            sortDir="desc"
            onClick={(res) => {setSelected(res); setModal(true)}}
        />
    </Fragment>
}

Table.propTypes = {
    formId: PropTypes.string.isRequired,
    fields: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
        tableView: PropTypes.bool
    })).isRequired
};


