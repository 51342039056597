import React, {useCallback, useEffect, useState, useMemo} from "react";
import {NumberParam, StringParam, useQueryParam} from 'use-query-params';
import SortableTable, {HeaderConfig} from "../generic/SortableTable";
import LoaderOverlay from "../generic/LoaderOverlay";
import {showDangerToast} from "../../lib/notify";
import Pager from "../generic/Pager";
import PropTypes from "prop-types";
import {setCookie} from "../../lib/session";
import {Button} from 'reactstrap';
import "./DataTable.css";
import makeCancelable from "../../lib/makeCancelable";
import ButtonWithConfirm from "./ButtonWithConfirm";

function DataTable(props) {

    const prefix = props.name ? props.name + '_' : '';
    const [start, setStart] = useQueryParam(prefix + 'start', NumberParam);
    const [limit, setLimit] = useQueryParam(prefix + 'limit', NumberParam);
    const [sortField, setSortField] = useQueryParam(prefix + 'sortField', StringParam);
    const [sortDir, setSortDir] = useQueryParam(prefix + 'sortDir', StringParam);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState();
    const [total, setTotal] = useState();
    const [totalRow, setTotalRow] = useState();
    const [multipleSelectedIds, setMultipleSelectedIds] = useState([]);
    const [multipleSelectAll, setMultipleSelectAll] = useState(false);

    const multipleSelectedItems = useMemo(() =>
        (data || []).filter(x => x[props.rowKey] && multipleSelectedIds && multipleSelectedIds.includes(x[props.rowKey])), [data, multipleSelectedIds]);

    const {emptyContent, fields, onClick, onChange, onDelete, additionalButtons, tableButtons, onMultipleDelete, multipleSelection, onMultipleSelect} = props;

    const hasButtons = onChange || onDelete || additionalButtons;
    const hasMultipleSelection = !!onMultipleSelect || multipleSelection || onMultipleDelete;
    const hasMultipleDelete = onMultipleDelete && multipleSelectedIds && multipleSelectedIds.length > 0;

    const dataParameters = {
        sortField: sortField || props.sortField, sortDir: sortDir || props.sortDir, start: start || 0,
        limit: limit || 10, filters: {...props.filters}};

    function filterDataIds(ids, data) {
        return (ids || []).filter(id => (data || []).find(x => x[props.rowKey] === id));
    }

    function onMultipleSelectionClick(id, item, checked) {
        let newArray = filterDataIds(multipleSelectedIds, data).filter(x => x !== id);

        if(checked) newArray = newArray.concat(id);

        setMultipleSelectedIds(newArray);

        if(onMultipleSelect) onMultipleSelect(multipleSelectedIds, multipleSelectedItems);
    }

    function onMultipleSelectAll(checked) {
        let newArray = [];

        if(checked) newArray = (data || []).map(x => x[props.rowKey]);

        setMultipleSelectAll(checked);
        setMultipleSelectedIds(newArray);
    }

    useEffect(() => {
        const cancellable = makeCancelable(loadData());
        cancellable.promise.then(({data, total, totalRow}) => {
            setData(data);
            setTotal(total);
            setTotalRow(totalRow);
            setLoading(false);
            setMultipleSelectedIds(filterDataIds(multipleSelectedIds, data));
            setMultipleSelectAll(multipleSelectedIds && multipleSelectedIds.length > 0 && (data || []).every(x => multipleSelectedIds.includes(x[props.rowKey])));

            if(start >= total && total !== 0) setStart(total - total % (limit || 10));
        })
        .catch(e => {
            setLoading(false);
            showDangerToast(e);
        });

        return () => cancellable.cancel();
    }, [start, limit, sortField, sortDir, props.filters, props.reload]);

    useEffect(() => {
        setCookie(prefix + 'limit', limit);
    }, [limit]);

    async function loadData() {
        const {start, limit, sortField, sortDir, filters} = dataParameters;
        return props.findAndCount(start, limit, sortField, sortDir, filters)
    }

    const handleSort = useCallback(function (sortField, sortDir) {
        setSortField(sortField);
        setSortDir(sortDir);
    }, []);

    const handleRowClick = useCallback(function (e, row) {
        if (props.onCellClick) return;
        if (props.onClick) {
            e.preventDefault();
            // prevents triggering of onClick when clicking buttons
            if (e.target.className.includes('btn') || e.target.className.includes('fa')) return;
            props.onClick(row);
        }
    }, [props.onCellClick, props.onClick]);

    const handleCellClick = useCallback(function (e, row, field) {
        if (!props.onCellClick) return;
        e.preventDefault();
        props.onCellClick(row, field);
    }, [props.onCellClick]);

    const totalRowRender = props.totalRowRender || (totalRow => <tr className="totalRow">
        {hasMultipleSelection && <th/>}
        {fields.map(field =>
            <th key={`total-row-cell-${field.key}`}>
                {field.render(totalRow)}
            </th>
        )}
        {hasButtons && <th/>}
    </tr>);

    return <LoaderOverlay isVisible={loading} className="data-table-content">
        {((!data || data.length === 0) && emptyContent) ? emptyContent :
            <div className="table-responsive data-table-content">
                <SortableTable handleSort={handleSort}
                               fields={fields.map(x => x.headerConfig).concat(hasButtons ? [new HeaderConfig("", false, "action-column")] : [])}
                               sortField={sortField}
                               sortDir={sortDir}
                               hover={!!onClick}
                               hasMultipleSelection={hasMultipleSelection}
                               onMultipleSelectAll={onMultipleSelectAll}
                               multipleSelectAll={multipleSelectAll}
                                >
                    {totalRow && totalRowRender(totalRow)}
                    {data && data.map((res, i) => (
                        <tr className={props.rowClass && props.rowClass(res)}
                            key={`row-${props.name}-${res[props.rowKey]}-${i}`}
                            onClick={e => handleRowClick(e, res)}>

                            {hasMultipleSelection &&
                                <td style={{width:'1px'}}>
                                    <input
                                        onChange={event => onMultipleSelectionClick(res[props.rowKey], res, event.target.checked)}
                                        checked={multipleSelectedIds && res && multipleSelectedIds.includes(res[props.rowKey]) ? 'checked' : ''}
                                        className="checkbox"
                                        type="checkbox"
                                    />
                                </td>
                            }

                            {fields.map(field =>
                                <td key={`row-${res[props.rowKey]}-cell-${field.key}`}
                                    onClick={e => handleCellClick(e, res, field)}
                                    className={field.getClassName(res, field)}>
                                    {typeof field.render(res) === 'boolean' ? field.render(res).toString() : field.render(res)}
                                </td>
                            )}
                            {
                                hasButtons && (
                                    <td className="small-padding small-width">
                                        <div className="btn-group">
                                            {additionalButtons && additionalButtons(res)}
                                            {onChange &&
                                            <Button color="success" size="xs" onClick={() => onChange(res)}><i
                                                className="fa fa-marker"/></Button>}
                                            {onDelete && <ButtonWithConfirm onClick={() => onDelete(res)}
                                                               className="btn-xs"
                                                               modalButtonText="Delete"
                                                               color="danger"
                                                               modalText="Do you want to delete?">
                                                <i className="fa fa-trash"/>
                                            </ButtonWithConfirm>}
                                        </div>
                                    </td>
                                )
                            }
                        </tr>
                    ))}
                </SortableTable>
            </div>
        }
        <div className={"mt-2"}>
            <Pager
                start={start}
                total={total}
                limit={limit}
                onPageChange={x => setStart(x)}
                onLimitChange={x => {
                    setStart(0);
                    setLimit(x);
                }}
                canChangeLimit={!!props.name}
            />

            {tableButtons && tableButtons(dataParameters, multipleSelectedIds, multipleSelectedItems)}
            {hasMultipleDelete && <ButtonWithConfirm onClick={() => onMultipleDelete(multipleSelectedIds, multipleSelectedItems)}
                                                    className="btn-xs"
                                                    modalButtonText="Delete"
                                                    color="danger"
                                                    modalText={`Do you want to delete ${multipleSelectedIds.length} items?`}>
                Delete {multipleSelectedIds.length} item(s)
            </ButtonWithConfirm>}
        </div>
    </LoaderOverlay>;
}

export class Field {
    constructor(label, isSortable, key, render, className) {
        this.getClassName = this.getClassName.bind(this);
        this.label = label;
        this.isSortable = isSortable || false;
        this.key = key || label;
        this.render = render || (x => Field.defaultRender(Field.resolveNested(this.key, x)));
        this.className = className;
    }

    static resolveNested(path, item) {
        var properties = Array.isArray(path) ? path : path.split(".");
        return properties.reduce((prev, curr) => prev && prev[curr], item)
    }

    get headerConfig() {
        return new HeaderConfig(this.label, this.isSortable, this.key)
    }

    static defaultRender(value) {
        //Display decimal with two places
        if (Number(value) === value && value % 1 !== 0) {
            return parseFloat(Math.round(value * 100) / 100).toFixed(2);
        } else return value;
    }

    getClassName(res, field) {
        if (!this.className) return undefined;
        if (typeof this.className === "function") return this.className(res, field);
        return this.className;
    }
}

/**
 * Конфигурация поля таблицы
 * @param label - Название поля. Может быть тегом <i>курсив</i>
 * @param isSortable - Флаг сортировки. По умолчанию выключена
 * @param key - ключ поля (название поля в таблице). По умолчанию равен label
 * @param render - функция отображения поля. По умолчанию res => res[key] - значение по ключу поля
 * @param {string|function} className - класс ячейки td
 * @returns {Field}
 */
export const field = (label, isSortable, key, render, className) => new Field(label, isSortable, key, render, className);

DataTable.propTypes = {
    filters: PropTypes.object,
    findAndCount: PropTypes.func.isRequired,
    fields: PropTypes.arrayOf(PropTypes.instanceOf(Field)),
    sortField: PropTypes.string,
    sortDir: PropTypes.string,
    rowKey: PropTypes.string,
    onClick: PropTypes.func,
    onCellClick: PropTypes.func,
    name: PropTypes.string,
    reload: PropTypes.any,
    rowClass: PropTypes.func,
    emptyContent: PropTypes.node,
    limit: PropTypes.number,
    totalRowRender: PropTypes.func,
    onDelete: PropTypes.func,
    onChange: PropTypes.func,
    /**
     * function(row: object): JSX
     */
    additionalButtons: PropTypes.func,
    /**
     * function(parameters: {sortField, sortDir, start, limit, filters}, ids: any[], items: object[]): JSX
     */
    tableButtons: PropTypes.func,
    /**
     * function(ids: any[], items: object[])
     */
    onMultipleSelect: PropTypes.func,
    multipleSelection: PropTypes.bool,
    /**
     * function(ids: any[], items: object[])
     */
    onMultipleDelete: PropTypes.func
};

DataTable.defaultProps = {
    filters: {},
    sortDir: "desc",
    rowKey: "id",
    name: "",
    multipleSelection: false
};

export default DataTable;