import React from 'react'
import * as PropTypes from "prop-types";
import Switch from "../../../generic/Switch";

export default function Security({state, dispatch, ...rest}) {
    const handleInputChange = e => {
        e.persist && e.persist();
        dispatch({
            type: 'setSecurity',
            payload: {
                value: e
            }
        })
    }
    return (
        <div>
            <h4 className="card-title">Security</h4>
            <form className="forms-sample">
                <div className='form-group'>
                    <div className='form-group row'>
                        <div className='col-sm-3'>
                            <label className='form-check-label'>Enable captcha</label>
                        </div>
                        <div className='col-sm-9'>
                            <Switch onChange={() => {dispatch({type:'toggleSecurity'})}} value={state.securityEnabled}/>
                        </div>
                    </div>
                    {state.securityEnabled && <div>
                        <div className="form-group">
                            <label>Site key</label>
                            <input
                                type="text"
                                className="form-control"
                                name="sitekey"
                                placeholder="Site key"
                                onChange={handleInputChange}
                                autoComplete={"false"}
                                value={state.sitekey}
                            />
                        </div>
                        <div className="form-group">
                            <label>Secret key</label>
                            <input
                                type="text"
                                className="form-control"
                                name="secretkey"
                                placeholder="Secret key"
                                onChange={handleInputChange}
                                autoComplete={"false"}
                                value={state.secretkey}
                            />
                        </div>
                    </div>
                    }
                </div>
                <div className='form-group row'>
                    <div className='col-sm-3'>
                        <label className='form-check-label'>Is private</label>
                    </div>
                    <div className='col-sm-9'>
                        <Switch onChange={() => {dispatch({type:'togglePrivacy'})}} value={state.isPrivate}/>
                    </div>
                </div>
            </form>
        </div>
    )
}

Security.propTypes = {
    data: PropTypes.object,
    handleInputChange: PropTypes.func,
    disableSecurity: PropTypes.func
};


