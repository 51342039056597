import React, {useEffect, useState} from "react";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import * as notify from "../../../lib/notify"
import {insertNewReference, changeReference} from "../../../service/reference";

export default function CEComponent(props) {
    const {isOpen, closePopup, setReload, isEditMode, editPopupData} = props;
    const [code, setCode] = useState(undefined);
    const [label, setLabel] = useState(undefined);
    const [kind, setKind] = useState(undefined);
    const types = ['registrationChannel', 'registrationSource', 'terms'];

    useEffect(() => {
        if (editPopupData) {
            const {code, label, kind} = editPopupData;

            setCode(code);
            setLabel(label);
            setKind(kind);
        }
        if (!isEditMode) {
            deleteData();
        }
    }, [editPopupData])

    useEffect(() => setKind('registrationChannel'), [])

    const handleCodeChange = e => setCode(e.target.value);
    const handleLabelChange = e => setLabel(e.target.value);
    const handleKindChange = kind => setKind(kind);

    const onSave = async () => {
        if (!code || !label) {
            notify.showWarningToast('All fields are required!');
            return;
        }
        if (isEditMode) {
            await changeReference(editPopupData.id, kind, code, label);
        } else {
            await insertNewReference(kind, code, label);
        }

        deleteData();
        setReload();
        closePopup();
    }

    const deleteData = () => {
        setKind('registrationChannel');
        setLabel(undefined);
        setCode(undefined);
    }

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader>{!isEditMode ? "Create new row" : "Update existing row"}</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="kind">Kind</Label>
                        <Input type="select" disabled={isEditMode} name="kind" className="mb-2" id="kind" value={kind}
                               onChange={e => handleKindChange(e.target.value)}>
                            {
                                types.map(type => <option key={type}>{type}</option>)
                            }
                        </Input>
                        <Label for="code">Code</Label>
                        <Input type="text" name="code" id="code" className="mb-2" placeholder="Enter code..."
                               value={code} onChange={handleCodeChange}/>
                        <Label for="label">Label</Label>
                        <Input type="text" name="label" id="label" className="mb-2" placeholder="Enter label..."
                               value={label} onChange={handleLabelChange}/>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => {
                    onSave();
                }}>Save</Button>
                <Button color="secondary" onClick={() => {
                    deleteData()
                    closePopup();
                }}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
}