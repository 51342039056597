import React from 'react'
import TableCard from "../generic/TableCard";
import Table from "./Table";
import {Redirect} from "react-router-dom";
import {deleteEntry} from "../../service/project";
import * as notify from "../../lib/notify";

export default class IndexRoute extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            redirectToCreate: false,
            redirectToEditItemId: null,
            deleteItem: false,
            reload: false
        };

        this.onChange = this.onChange.bind(this);
        this.onDelete = this.onDelete.bind(this);
    };

    onChange(res) {
        this.setState({redirectToEditItemId: res.id})
    }

    onDelete(deleteItem) {
        const {reload} = this.state;
        deleteEntry(deleteItem.id)
            .then(() => {
                this.setState({deleteItem: null, reload: !reload});
                notify.showSuccessToast(`Project '${deleteItem.label}' was deleted`)
            })
            .catch(notify.showDangerToast);
    }

    render() {
        const {reload, redirectToCreate, redirectToEditItemId} = this.state;

        if (redirectToCreate) return <Redirect push to='/projects/edit'/>;
        if (redirectToEditItemId) return <Redirect push to={'/projects/edit/' + redirectToEditItemId}/>;

        return <TableCard title={"Projects"}>
            <div className="mb-3">
                <button className="btn btn-primary" onClick={() => this.setState({redirectToCreate: true})}>
                    <i className="fa fa-plus"/>
                    &nbsp; Add Project
                </button>
            </div>
            <Table reload={reload} onDelete={this.onDelete} onChange={this.onChange}/>
        </TableCard>
    }
};