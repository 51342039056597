import React, {useEffect, useState} from 'react';
import * as notify from "../../../lib/notify";
import Loader from "../../generic/Loader";
import Card from "../../generic/Card";
import form from "../../../service/form";
import Table from "./Table";

export default function EditHead(props) {

    const [data, setData] = useState({
        "id": "",
        "key": "",
        "label": "",
        "fields": []
    });

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        loadData(props);
    }, [props]);

    function loadData(props) {
        const formId = props.match.params.id;
        if (formId) {
            setLoading(true);
            form.getEntry(formId)
                .then(({id, label, key, fields}) => setData({
                    ...data, id, label, key, fields
                }))
                .finally(() => setLoading(false))
                .catch(notify.showDangerToast)
        }
    }

    if (isLoading) return <Loader/>;
    return (
        <Card title={`"${data.label}" form data`}>
            {data.id && data.fields && <Table formId={data.id} fields={data.fields}/>}
        </Card>
    );
}
