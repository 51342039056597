import React, {useState} from 'react'
import Table from "./Table";
import TableCard from "../../generic/TableCard";
import CEComponent from "./CEComponent";
import TextSearch from "../../generic/field/TextSearch";
import {StringParam, useQueryParam} from "use-query-params";
import ClearButton from "../../generic/field/ClearButton";
import {pickBy} from "lodash";
import {Input} from "reactstrap";

export default function UBEReference(props) {

    const [popupState, setPopupState] = useState(false);
    const [reload, setReload] = useState(undefined);
    const [isEditMode, setEditMode] = useState(undefined);
    const [editPopupData, setEditPopupData] = useState(undefined);
    const [label, setLabel] = useQueryParam('label', StringParam);
    const [code, setCode] = useQueryParam('code', StringParam);
    const [kind, setKind] = useQueryParam('kind', StringParam);
    const types = ['registrationChannel', 'registrationSource', 'terms'];

    const filters = pickBy({
        kind,
        code,
        label,
    });

    const handleKindChange = kind => setKind(kind);

    return (
        <TableCard title={"Reference"}>
            <div className={"form-inline"}>
                <button className="btn btn-primary mb-2 mr-2 " onClick={() => {
                    setEditMode(false);
                    setPopupState(true);
                }}>
                    <i className="fa fa-plus"/>
                    &nbsp; Add reference row
                </button>
                <div>

                    <Input type="select" disabled={isEditMode} name="kind" className="mb-2 mr-2 form-control" id="kind"
                           value={kind || ''} onChange={e => handleKindChange(e.target.value)}>
                        <option value={''}>kind: any</option>
                        {
                            types.map(type => <option key={type}>{type}</option>)
                        }
                    </Input>
                    <TextSearch value={code} className="mr-2 mb-2 form-control" onSearch={setCode}
                                placeholder="Code"/>
                    <TextSearch value={label} className="mb-2 mr-2 form-control" onSearch={setLabel}
                                placeholder="Label"/>
                    <ClearButton className="mb-2 ml-2 btn" onClick={() => {
                        [setKind, setCode, setLabel].forEach(f => f(() => undefined));
                        setReload(reload => !reload);
                    }}/>
                </div>
            </div>

            <CEComponent
                editPopupData={editPopupData}
                isOpen={popupState}
                closePopup={() => setPopupState(false)}
                setReload={() => setReload(!reload)}
                isEditMode={isEditMode}
            />
            <div className="row">
                <div className={"pt-3 col-lg-12"}>
                    <Table reload={reload}
                           setEditPopupData={data => setEditPopupData(data)}
                           setReload={() => setReload(!reload)}
                           setEditMode={() => setEditMode(true)}
                           openPopup={() => setPopupState(true)}
                           filters={filters}
                    />
                </div>
            </div>
        </TableCard>
    );
};
