import React from 'react'
import TableCard from "../../generic/TableCard";
import Table from "./Table";

export default class Index extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            reload: undefined
        };
    };

    render() {
        const { reload } = this.state;
        return <TableCard title={"Export"}>
             <Table reload={reload} onChange={this.openEditModal} />
        </TableCard>
    }
};
