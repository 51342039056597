import {useEffect, useState} from "react";
import * as notify from "../../../lib/notify";
import {pickBy} from "lodash";
import moment from "moment";

const useFetchStatistics = ({type, createdAt_gte, createdAt_lte, partner, getStatistics}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [chartData, setChartData] = useState({});

    const filters = pickBy({
        createdAt_gte: createdAt_gte && moment(createdAt_gte).format("YYYY-MM-DDTHH:mm:ssZ"),
        createdAt_lte: createdAt_lte && moment(createdAt_lte).clone().add(1, 'day').format("YYYY-MM-DDTHH:mm:ssZ"),
        partner,
        type
    });

    useEffect(() => {
        getStatistics(filters).then((data) => {
            setIsLoading(false)
            setChartData(data)
        }).catch(e => {
            if (e) {
                notify.showDangerToast(e);
            }
        })
    }, [createdAt_gte, createdAt_lte, partner]);

    return [chartData, isLoading]
}

export default useFetchStatistics;