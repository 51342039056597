import { Service } from "../lib/strapi";

const formDump = "formsbackupdumps";

const formDumpService = new Service(formDump);

export const findAndCountDump = formDumpService.findAndCountServerSide;

const formBackup = "formsbackups";

const formBackupService = new Service(formBackup);

export const findAndCountBackup = formBackupService.findAndCountServerSide;

const formReleases = "releases";

const formReleasesService = new Service(formReleases);

export const findAndCountRelease = formReleasesService.findAndCountServerSide;
