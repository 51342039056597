import React, {useState} from 'react';
import './statistics.css';
import {Nav, NavItem, NavLink} from "reactstrap";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import Card from "../../generic/Card";
import Statistics from "./statistics";

const SeamlessStatistics = () => {
    const location = useLocation();
    const history = useHistory();
    const activeTab = /\/(\w+)\/?$/.exec(location.pathname)[1];
    const [statisticBy, setStatisticBy] = useState('fileLogs');

    return <Card title={"Seamless Statistics"}>
        <Nav tabs>
            <NavItem>
                <NavLink className={activeTab === 'statistics' ? "active" : ""}
                         onClick={() => history.push(`/seamless/statistics`)}
                         href="#">
                    Orders
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={activeTab === 'accounts' ? "active" : ""}
                         onClick={() => history.push(`/seamless/statistics/accounts`)}
                         href="#">
                    Accounts
                </NavLink>
            </NavItem>
        </Nav>
        <Switch>
            <Route path="/seamless/statistics" component={() => <Statistics type="orders" statisticBy={statisticBy} setStatisticBy={setStatisticBy} />} exact/>
            <Route path="/seamless/statistics/accounts" component={() => <Statistics type="accounts" statisticBy={statisticBy} setStatisticBy={setStatisticBy} />}/>
        </Switch>
    </Card>
};

export default SeamlessStatistics;