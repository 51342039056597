import React from "react";
import PropTypes from 'prop-types';
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {changeCorsData} from "../../../service/cors";

export default class Edit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.onSave = this.onSave.bind(this);
        this.onToggle = this.onToggle.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this)
    }

    onSave() {
        const {closeModalConfirmation} = this.props;
        changeCorsData(this.props.idToEdit, this.state.domain ? this.state.domain : this.props.domainToEdit)
            .then(() => {
                this.setState({domain: null})
                closeModalConfirmation()
            })
    }

    onToggle() {
        const {closeModalConfirmation} = this.props;
        closeModalConfirmation();
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    render() {
        const {isOpen, onToggle, domainToEdit} = this.props;
        return <Modal isOpen={isOpen} toggle={onToggle}>
            <ModalHeader>Update row</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="domain">Domain</Label>
                        <Input type="text" name="domain" id="domain" placeholder="Enter domain ..."
                               value={this.state.domain ? this.state.domain : domainToEdit}
                               onChange={this.handleInputChange}/>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={this.onSave}>Save</Button>{' '}
                <Button color="secondary" onClick={this.onToggle}>Cancel</Button>
            </ModalFooter>
        </Modal>
    }
}

Edit.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    closeModalConfirmation: PropTypes.func.isRequired
};
