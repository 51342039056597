import React, {useMemo} from "react";
import DataTable, {field} from "../../generic/DataTable";
import {exportData, getFraudLogs} from "../../../service/fraud_calculation_log";
import PropTypes from "prop-types";
import ExportFunButton from "../../generic/ExportFunButton";
import {datetime} from "../../../lib/format";
import { Config } from "lead-generation-common";

const { AntifraudGroups} = Config.fraud;

function scoreToClass(score) {
    if(score <= 10) return 'danger';
    else if(score <= 50) return 'warning';
    else return 'success';
}

const GroupBadge = ({group}) => {
    switch(group) {
        case AntifraudGroups.Good:
            return <span className={"badge badge-success mr-2"}>Good</span>
        case AntifraudGroups.Suspicious:
            return <span className={"badge badge-warning mr-2"}>Suspicious</span>
        case AntifraudGroups.Malicious:
            return <span className={"badge badge-danger mr-2"}>Malicious</span>
    }
}

const baseFields = [
    field("Date", true, "createdAt", res => datetime(res.createdAt)),
    field("Form Key", true, "formKey"),
    field("Cell ID", true, "cellId"),
    field("User ID", true, "userId"),
    field("Score", true, "scoreB", res => res.scoreB ? <span className={"badge badge-"+scoreToClass(res.scoreB)}>{res.scoreB}</span> : undefined, 'small-padding'),
    field("Block", true, "block", res => 
        <span>
            {res.block ? <span className={"badge badge-danger mr-2"}>Block</span> : <span className={"badge badge-success mr-2"}>Pass</span>}
            {res.hardBlock && <span className={"badge badge-info mr-2"}>H</span>}
            {res.softBlock && <span className={"badge badge-warning mr-2"}>S</span>}
            {res.functionBlock && <span className={"badge badge-primary mr-2"}>F</span>}
        </span>
    , 'small-padding'),
    field("Group", true, "group", res => res.group && <GroupBadge group={res.group}/>, 'small-padding'),
    field("Malicious", true, "malicious", res =>
        <span>
            {res.malicious && <span className={"badge badge-danger"}>M</span>}
        </span>, 'small-padding')
];

const otherFields = [
    field("Project", true, "project"),
    field("IP Address", true, "ipAddress"),
    field("IP Regs Month", false, "ipRegsPerMonth"),
    field("IP+FP Regs Month", false, "ipFPRegsPerMonth"),
    field("Captcha Score", true, "captchaScore"),
    field("IP Registrations", true, "registrationCount"),
    field("Device ID", true, "deviceId"),
    field("Device Registrations", true, "deviceIdRegistrationsCount"),
    field("Bad emails", true, "badEmailCount"),
    field("Device Type", false, "deviceType"),
    field("OS", false, "os"),
    field("Audio fingerprint", false, "audioFingerprint"),
    field("Canvas fingerprint", false, "canvasFingerprint"),
    field("Font fingerprint", false, "fontFingerprint"),
    field("Common fingerprint", false, "commonFingerprint"),
    field("Resolution", false, "deviceResolution"),
    field("Model", false, "deviceModel"),
    field("Timezone", false, "deviceTimezone"),
    field("Incognito", false, "isIncognitoScore"),
    field("Scanpack Not Mobile", false, "isBadDeviceScore"),
    field("Imp. Token", false, "impressionToken"),
    field("IT Count", true, "impressionTokenCount"),
    field("Face Token", false, "faceToken"),
    field("FT Count", true, "faceTokenCount"),
    field("FT Month", true, "faceTokenMonthlyCount"),
    field("Scan IP", false, "scanIP"),
    field("Scan Date", false, "scanDate"),
    field("Scan Token", false, "scanToken"),
    field("Scan Token Count", false, "scanTokenCount"),
    field("Fun. Error", false, "functionError"),
    field("First name", false, "firstName"),
    field("Last name", false, "lastName"),
    field("Location", false, "locationId"),
    field("Email", false, "email"),
    field("Phone", false, "phone"),
    field("VEPC", false, "validateExistsPhoneCount"),
    field("Config status", false, "configStatus"),
];

const Table = (props) => {
    const filters = props.filters;

    const fields = useMemo(() => props.allColumns ? baseFields.concat(otherFields) : baseFields, [props.allColumns]);

    return (<DataTable
        filters={filters}
        findAndCount={getFraudLogs}
        tableButtons={dataParameters => <ExportFunButton dataParameters={dataParameters} exportFun={exportData}/> }
        reload={props.reload}
        fields={fields}
        sortField="createdAt"
        sortDir="desc"
        name="fraud-config">
    </DataTable>)
};

Table.propTypes = {
    reload: PropTypes.any,
    allColumns: PropTypes.bool
};

export default Table;