import React, {useEffect, useState} from "react";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';

export default function CEComponent(props) {
    const {isOpen, closePopup, isEditMode, editPopupData} = props;
    const [code, setCode] = useState(undefined);
    const [version, setVersion] = useState(undefined);
    const [text, setText] = useState(undefined);

    useEffect(() => {
        if (editPopupData) {
            const {code, version, text} = editPopupData;
            setCode(code);
            setVersion(version);
            setText(text)
        }
        if (!isEditMode) {
            deleteData();
        }
    }, [editPopupData])

    const handleCodeChange = e => setCode(e.target.value);
    const handleVersionChange = e => setVersion(e.target.value);
    const handleTextChange = e => setText(e.target.value)

    const deleteData = () => {
        setVersion(undefined);
        setCode(undefined);
        setText(undefined);
    }

    return (
        <Modal isOpen={isOpen}>
            <ModalHeader>{!isEditMode ? "Create new row" : "Update existing row"}</ModalHeader>
            <ModalBody>
                <Form>
                    <FormGroup>
                        <Label for="code">Code</Label>
                        <Input type="text" name="code" id="code" className="mb-2" placeholder="Enter code..."
                               value={code} onChange={handleCodeChange} disabled={true}/>
                        <Label for="version">Version</Label>
                        <Input type="text" name="version" id="version" className="mb-2" placeholder="Enter version..."
                               value={version} onChange={handleVersionChange} disabled={true}/>
                        <Label for="exampleText">Text</Label>
                        <Input type="textarea" name="text" rows={15} value={text} placeholder="Enter text..."
                               onChange={handleTextChange} disabled={true}/>
                    </FormGroup>
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={() => {
                    deleteData()
                    closePopup();
                }}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
}