import React, {useState} from 'react'
import Table from "./Table";
import TableCard from "../../generic/TableCard";
import CEComponent from "./CEComponent";
import {pickBy} from "lodash";
import {StringParam, useQueryParam} from "use-query-params";
import ClearButton from "../../generic/field/ClearButton";
import TextSearch from "../../generic/field/TextSearch";

export default function UBETerms(props) {

    const [popupState, setPopupState] = useState(false);
    const [reload, setReload] = useState(undefined);
    const [isEditMode, setEditMode] = useState(undefined);
    const [editPopupData, setEditPopupData] = useState(undefined);
    const [code, setCode] = useQueryParam('code', StringParam);
    const [version, setVersion] = useQueryParam('version', StringParam);

    const filters = pickBy({
        code,
        version,
    });

    return (
        <TableCard title={"Terms"}>
            <div className={"row justify-content-start ml-2"}>
                <div className="form-inline">
                    <TextSearch value={code} className="mb-2 mr-2 form-control" onSearch={setCode}
                                placeholder="Code"/>
                    <TextSearch value={version} className="mb-2 mr-2 form-control" onSearch={setVersion}
                                placeholder="Version"/>
                    <ClearButton className="btn btn-outline-secondary mb-2" onClick={() => {
                        [setCode, setVersion].forEach(f => f(() => undefined));
                        setReload(reload => !reload);
                    }}/>
                </div>
            </div>
            <CEComponent
                editPopupData={editPopupData}
                isOpen={popupState}
                closePopup={() => setPopupState(false)}
                setReload={() => setReload(!reload)}
                isEditMode={isEditMode}
            />
            <div className="row">
                <div className={"pt-3 col-lg-12"}>
                    <Table reload={reload}
                           setEditPopupData={data => setEditPopupData(data)}
                           setReload={() => setReload(!reload)}
                           setEditMode={() => setEditMode(true)}
                           openPopup={() => setPopupState(true)}
                           filters={filters}
                    />
                </div>
            </div>
        </TableCard>
    );
};
