import React from "react";
import DataTable, {field} from "../generic/DataTable";
import {findAndCount} from "../../service/project";
import PropTypes from "prop-types";
import format from "../../lib/format";

export default class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reload: false
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.reload !== this.props.reload) {
            this.setState({
                reload: !this.state.reload
            })
        }
    }

    render() {
        return <DataTable
            onDelete={this.props.onDelete}
            onChange={this.props.onChange}
            findAndCount={findAndCount}
            reload={this.state.reload}
            fields={[
                field("Label", true, "label"),
                field("Key", true, "key"),
                field("Created", true, "createdAt", res => format.datetime(res.createdAt)),
                field("Updated", true, "updatedAt", res => format.datetime(res.updatedAt)),
            ]}>
            name={"projects"}
        </DataTable>
    }
}

Table.propTypes = {
    onDelete: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    reload: PropTypes.bool
};
