import React, {useState} from 'react'
import TableCard from "../generic/TableCard";
import Table from "./Table";
import {Link} from "react-router-dom";
import ProjectInput from "../generic/field/ProjectInput";
import ClearButton from "../generic/field/ClearButton";


export default function Forms() {

    const [filterProjectId, setFilterProjectId] = useState(null);
    const [reload, setReload] = useState(undefined);

    const reloadForms = () => {
        setReload(!reload)
    }

    const filters = {}
    if(filterProjectId) filters.project = filterProjectId;

    return <TableCard title={"Forms"}>
        <form className="form-inline">
            <Link className="btn btn-primary mb-2 mr-2" to={`/forms/edit`}>
                <i className="fa fa-plus"/>
                &nbsp; Add form
            </Link>
            <ProjectInput className="mb-2 mr-2" selectedItemId={filterProjectId} onSelect={setFilterProjectId}/>

            <ClearButton className="mb-2" onClick={() => setFilterProjectId(null)}/>
        </form>
        <div style={{minHeight: "400px"}}>
            <Table reloadForms={reloadForms} filters={filters}/>
        </div>
    </TableCard>
}