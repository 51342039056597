import React from "react";
import {Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import './badge.css'
import PropTypes from "prop-types";
import {Config} from 'lead-generation-common'
import Badge from "./Badge";
import {handleInputChange} from "../../../lib/handleInputChange";

const EditForm = (props) => {
    const {alert, setAlert} = props
    const handleInput = e => {
        const {name, value} = handleInputChange(e)
        setAlert(alert => ({...alert, [name]: value}))
    }
    return (
        <Form>
            <FormGroup>
                <Label for="name">Name</Label>
                <Input type="text" name="name" id="name" placeholder="Enter name ..." value={alert.name || ''}
                       onChange={handleInput}/>
            </FormGroup>
            <FormGroup>
                <Label for="email">E-mail</Label>
                <Input type="email" name="email" id="email" placeholder="Enter e-mail ..."
                       value={alert.email || ''} onChange={handleInput}/>
            </FormGroup>
            <FormGroup>
                <Label for="pushbullet">Alert</Label>
                <Input type="pushbullet" name="pushbullet" id="pushbullet"
                       placeholder="Enter pushbullet token ..." value={alert.pushbullet || ''}
                       onChange={handleInput}/>
            </FormGroup>
            <FormGroup>
                <div className="card">
                    <div className="card-body">
                        <h4 className="card-title">Groups</h4>
                        <p className="card-description">Click to add or delete group</p>
                        <Row>
                            <Col>
                                {Config.alerts.groups.filter(group => group.label.indexOf('Website') !== -1)
                                    .map(group => (<Badge key={group.label} group={group} className={'form_alert-badge'}
                                                          setAlert={setAlert} alert={alert}/>))}
                            </Col>
                            <Col>
                                {Config.alerts.groups.filter(group => group.label.indexOf('Server') !== -1)
                                    .map(group => (<Badge key={group.label} group={group} className={'form_alert-badge'}
                                                          setAlert={setAlert} alert={alert}/>))}
                            </Col>
                            <Col>
                                {Config.alerts.groups.filter(group => group.label.indexOf('DM') !== -1)
                                    .map(group => (<Badge key={group.label} group={group} className={'form_alert-badge'}
                                                          setAlert={setAlert} alert={alert}/>))}
                            </Col>
                            <Col>
                                {Config.alerts.groups.filter(group => group.label.indexOf('Server') === -1 &&
                                    group.label.indexOf('Website') === -1 &&
                                    group.label.indexOf('DM') === -1
                                )
                                    .map(group => (<Badge key={group.label} group={group} className={'form_alert-badge'}
                                                          setAlert={setAlert} alert={alert}/>))}
                            </Col>
                        </Row>
                    </div>
                </div>
            </FormGroup>
        </Form>
    )
}

export default EditForm;

EditForm.propTypes = {
    alert: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
}