import React, {Fragment} from "react";
import PropTypes from "prop-types";
import EditorPane from "../../generic/EditorPane";

function NoSharing(props) {
    const {html, css, js} = props;

    return (<Fragment>

        <p>Scan QR code page template. If consumer doesn't scan QR code with secret parameter, he gets here.</p>
        <EditorPane html={html} css={css} js={js}
                                  onChange={(field, value) => props.onChange(field+'ScanTemplate', value)}/></Fragment>);
}

NoSharing.propTypes = {
    html: PropTypes.string,
    css: PropTypes.string,
    js: PropTypes.string,
    onChange: PropTypes.func.isRequired
};

export default NoSharing;