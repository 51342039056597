import React from 'react'
import {Redirect} from 'react-router-dom'
import {getUserFromSession} from "../../../lib/auth";

export default class Table extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            selectedUserId: undefined,
            currentUser: getUserFromSession()
        };
    };

    render() {

        const {selectedUserId, currentUser} = this.state;
        if (selectedUserId) return <Redirect push to={`/administration/user/edit/${selectedUserId}`}/>;
        return <tbody>
        {this.props.users && this.props.users.map(res => (
            <tr key={"user-list-" + res.id}
                style={(currentUser.role === 'User manager') ? {cursor: 'default'} : {cursor: 'pointer'}}
                className={"clickable-row"}
                onClick={() => {
                    if (currentUser.role === 'User manager') return;
                    this.setState({selectedUserId: res.id})
                }}>
                <td>{res.id}</td>
                <td>{res.username}</td>
                <td>{res.email}</td>
                <td>{res.role.name}</td>
                <td>{res.blocked ? "Yes" : "No"}</td>
            </tr>
        ))
        }
        </tbody>
    }
}