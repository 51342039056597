import {Typeahead} from 'react-bootstrap-typeahead';
import React from "react";
import {showDangerToast} from "../../../lib/notify";
import {findAndCount} from "../../../service/project";
import PropTypes from 'prop-types';
import makeCancelable from "../../../lib/makeCancelable";


export default class ProjectInput extends React.Component {

    constructor(props) {
        super(props);
        this.onSelect = this.onSelect.bind(this);
        this.state = {
            selectedItemId: props.selectedItemId,
            data: undefined
        };

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.selectedItemId !== prevProps.selectedItemId) {
            this.setState({selectedItemId: this.props.selectedItemId})
        }
    }

    componentDidMount() {
        this.cancelable = makeCancelable(findAndCount(0, 10000, 'label', 'asc'));
        this.cancelable.promise
            .then(({data}) => this.setState({data}))
            .catch(e => {
                console.log(e)
                showDangerToast(e);
            })
    }

    componentWillUnmount() {
        this.cancelable.cancel();
    }


    onSelect(selectedItem) {
        const selectedItemId = (selectedItem && selectedItem[0]) ? selectedItem[0].id : null;
        this.setState({selectedItemId});
        if (this.props.onSelect) this.props.onSelect(selectedItemId, selectedItem);
    }

    render() {
        const selectedItem = (this.state.data || []).find(x => x.id === this.state.selectedItemId);
        return (<Typeahead
            id="project"
            className={(this.props.className || "mr-sm-2")}
            labelKey="label"
            selected={selectedItem ? [selectedItem] : []}
            options={this.state.data || []}
            placeholder={this.props.placeholder || "Choose project"}
            onChange={this.onSelect}
            inputProps={this.props.inputProps}
            disabled={this.props.disabled}
        />);
    }
}

ProjectInput.propTypes = {
    onSelect: PropTypes.func,
    selectedItemId: PropTypes.any,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    inputProps: PropTypes.object,
    disabled: PropTypes.bool
};