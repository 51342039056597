import React from "react";
import DataTable, {field} from "../../generic/DataTable";
import {getReference, deleteReference} from "../../../service/reference";

export default function Table(props) {
    const { openPopup, setEditMode, setEditPopupData, setReload, reload, filters } = props;

    return (
        <DataTable
            filters={filters}
            onDelete={response => deleteReference(response.id).then(() => setReload())}
            onChange={response => {
                openPopup();
                setEditMode(true);
                setEditPopupData(response)
            }}
            reload={reload}
            findAndCount={getReference}
            fields={[
                field("Kind", false, "kind"),
                field("Code", false, "code"),
                field("Label", false, "label")
            ]}>
            name={"reference"}
        </DataTable>
    );
}
