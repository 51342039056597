import React, { useState } from 'react';
import Table from "../Table";
import CreateOrEdit from "../CreateOrEdit";

export default function Config() {
    const [popupState, setPopupState] = useState(false);
    const [reload, setReload] = useState(undefined);
    const [editPopupData, setEditPopupData] = useState(undefined);

    return (
        <div>
            <div className={"row justify-content-start ml-2"}>
                <button className="btn btn-primary" onClick={() => {
                    setEditPopupData({});
                    setPopupState(true)
                }}>
                    <i className="fa fa-plus" />
                    &nbsp; Add
                </button>
            </div>
            <CreateOrEdit data={editPopupData} isOpen={popupState} type="config" onSave={() => {
                setPopupState(false);
                setReload(!reload);
            }} onClose={() => {
                setPopupState(false);
            }} />
            <div className="row">
                <div className={"pt-3 col-lg-8"}>
                    <Table name="config" reload={reload} onChange={res => {
                        setEditPopupData(res);
                        setPopupState(true);
                    }} />
                </div>
                <div className="col-lg-4 jumbotron">
                    <h4 className="card-title">Types</h4>
                    <ul className="list-arrow">
                        <li>If there is no configuration, fraud is not active</li>
                        <li>{`Auto - automatic distribution of a prize to everyone who is not marked as Malicious`}</li>
                        <li>{`Allow - automatic distribution of prizes to everyone, even Malicious`}</li>
                        <li>{`Auto Suspicious - automatic issuance prize to Good users, manually for Suspicious, and a ban on issuing for Malicious.`}</li>
                        <li>{`Manual - manual issuance of a prize for Good and Suspicious, and a ban for Malicious.`}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}