import React, {useState} from 'react'
import {getSeamlessQueues} from "../../../service/queue";
import DataList from "../../generic/DataList";
import QueueCard from "../../administration/queue/QueueCard";

const Queues = () => {
    const [reload, setReload] = useState(true)
    return <>
        <DataList
            findAndCount={getSeamlessQueues}
            renderer={res => <QueueCard queue={res} logsLink={`/seamless/queues/logs?queue=${res.queue}`} tableReload={setReload}/>}
            reload={reload}
            name={"queues"}>
        </DataList>
        <p>Queues absolute sizes automatically update once per 10 min</p>
    </>
};

export default Queues;