import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Col, FormGroup, Label, Row} from "reactstrap";
import {Controller} from "react-hook-form";
import {Link} from "react-router-dom";
import Tags from "../../../generic/field/Tags";
import {getReference} from "../../../../service/reference"

function Terms({enabled, form: {register, errors, control}}) {
    const [allTerms, setAllTerms] = useState([]);

    useEffect(() => {
        getReference(0, 1000, undefined, undefined, {
            kind: "terms"
        }).then(result => setAllTerms((result.data || []).map(x => x.code)))
    }, []);

    return <Fragment>
        <Row>
            <Col md={12} lg={6}>
                <FormGroup>
                    <Label for="project">Selected Terms <Link to="/ube/reference">(see all)</Link></Label>

                    <Controller
                        control={control}
                        name="terms"
                        defaultValue={null}
                        render={({ onChange, value }) => (
                            <Tags value={value} onChange={onChange} options={allTerms}/>
                        )}
                    />

                    {errors.terms && errors.terms && <div className="invalid-feedback d-block">{errors.terms.message}</div>}

                    <small className="form-text text-muted">Consumer consent for registration and login terms&conditions</small>
                </FormGroup>
            </Col>
        </Row>
    </Fragment>
}

Terms.propTypes = {
    form: PropTypes.object.isRequired,
    enabled: PropTypes.bool.isRequired
}

export default Terms;