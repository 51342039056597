import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Col, FormGroup, Label, Row} from "reactstrap";
import RegistrationSource from "../../../generic/field/RegistrationSource";
import {Controller} from "react-hook-form";
import {Link} from "react-router-dom";

function PhoneLogin({enabled, form: {register, errors, control}}) {
    return <Fragment>
        <Row>
            <Col md={12} lg={6}>
                <FormGroup>
                    <Label for="project">Registration Source <Link to="/ube/reference">(see all)</Link></Label>

                    <Controller
                        control={control}
                        name="registration.registrationSource"
                        defaultValue={null}
                        render={({ onChange, value }) => (
                            <RegistrationSource isInvalid={errors.registration && !!errors.registration.registrationSource}
                                         onChange={onChange} value={value}/>
                        )}
                        rules={enabled && {required: "Registration Source is required"}}
                    />

                    {errors.registration && errors.registration.registrationSource && <div className="invalid-feedback d-block">{errors.registration.registrationSource.message}</div>}

                    <small className="form-text text-muted">Registration source attribute to store in uCRM for analytics and marketing</small>
                </FormGroup>
            </Col>
        </Row>
    </Fragment>
}

PhoneLogin.propTypes = {
    form: PropTypes.object.isRequired,
    enabled: PropTypes.bool.isRequired
}

export default PhoneLogin;