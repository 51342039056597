import React from 'react'
import Card from "../../generic/Card";
import {Nav, NavItem, NavLink} from "reactstrap";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import QueueConfig from "./QueueConfig";
import Logs from "./logs";
import FileLogs from "./fileLogs";

const AdministrationOutboundQueue = () => {
    const location = useLocation();
    const history = useHistory();
    const activeTab = /\/(\w+)\/?$/.exec(location.pathname)[1];

    return <Card title={"Queues"}>
        <Nav tabs>
            <NavItem>
                <NavLink className={ activeTab === 'queue' ? "active" : ""}
                         onClick={() => history.push(`/administration/queue`)}
                         href="#">
                    Configuration
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={ activeTab === 'logs' ? "active" : ""}
                         onClick={() => history.push(`/administration/queue/logs`)}
                         href="#">
                    Logs
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={ activeTab === 'filelogs' ? "active" : ""}
                         onClick={() => history.push(`/administration/queue/filelogs`)}
                         href="#">
                    File logs
                </NavLink>
            </NavItem>
        </Nav>
        <Switch>
            <Route path="/administration/queue" component={QueueConfig} exact/>
            <Route path="/administration/queue/logs" component={Logs}/>
            <Route path="/administration/queue/filelogs" component={FileLogs}/>
        </Switch>
    </Card>
};

export default AdministrationOutboundQueue;