import React, {useState} from 'react'
import TableCard from "../../generic/TableCard";
import Table from "./Table";
import TextSearch from "../../generic/field/TextSearch";
import ClearButton from "../../generic/field/ClearButton";
import DatePicker from "../../generic/field/DatePicker";
import {Label, Button} from "reactstrap";
import moment from "moment";
import { pickBy } from 'lodash';
import { useQueryParam, StringParam, DateParam, BooleanParam, withDefault } from 'use-query-params';

export default function Index() {

    const [ipAddress, setIPAddress] = useQueryParam('ipAddress', StringParam);
    const [userId, setUserId] = useQueryParam('userId', StringParam);
    const [deviceId, setDeviceId] = useQueryParam('deviceId', StringParam);
    const [cellId, setCellId] = useQueryParam('cellId', StringParam);
    const [formKey, setFormKey] = useQueryParam('formKey', StringParam);
    const [dateFrom, setDateFrom] = useQueryParam('dateFrom', DateParam);
    const [dateTo, setDateTo] = useQueryParam('dateTo', DateParam);
    const [commonFingerprint, setCommonFingerprint] = useQueryParam('commonFingerprint', StringParam);
    const [deviceType, setDeviceType] = useQueryParam('deviceType', StringParam);
    const [allColumns, setAllColumns] = useQueryParam('all', withDefault(BooleanParam, false));
    const [project, setProject] = useQueryParam('project', StringParam);
    const [reload, setReload] = useState(undefined);
    const [blockType, setBlockType] = useQueryParam('block', StringParam);
    const [impressionToken, setImpressionToken] = useQueryParam('impressionToken', StringParam);

    const filters = pickBy({
        userId,
        deviceId,
        cellId,
        ipAddress,
        formKey_contains: formKey,
        createdAt_gte: dateFrom,
        allColumns,
        commonFingerprint,
        deviceType,
        project,
        impressionToken
    });

    if(dateTo) filters['createdAt_lte'] = moment(dateTo).clone().endOf('day').toDate();
    switch(blockType) {

        case 'block':
            filters['block'] = true;
            break;

        case 'soft':
            filters['softBlock'] = true;
            break;

        case 'hard':
            filters['hardBlock'] = true;
            break;

        case 'pass':
            filters['block'] = false;
            break;

        default: 
            delete filters['block'];
            delete filters['softBlock'];
            delete filters['hardBlock'];
            delete filters['pass'];
    }

    return (<TableCard title={"Anti-fraud logs"}>
        <div className="form-inline">
            <TextSearch value={formKey} className="mb-2 mr-2 form-control-sm" onSearch={setFormKey}
                        placeholder="Form Key"/>
            <TextSearch value={cellId} className="mb-2 mr-2 form-control-sm" onSearch={setCellId}
                        placeholder="Cell Id"/>
            <TextSearch value={userId} className="mb-2 mr-2 form-control-sm" onSearch={setUserId}
                        placeholder="User Id"/>
            <select className="form-control mb-2 mr-2" value={blockType} onChange={(event) => {
                setBlockType(event.target.value)
            }}>
                <option value="" disabled selected>Block Type</option>
                <option value="none">None</option>
                <option value="block">Block</option>
                <option value="pass">Pass</option>
                <option value="hard">Hard</option>
                <option value="soft">Soft</option>
            </select>
            {allColumns && [
                <TextSearch value={project} className="mb-2 mr-2 form-control-sm" onSearch={setProject}
                            placeholder="Project"/>,
                <TextSearch value={ipAddress} className="mb-2 mr-2 form-control-sm" onSearch={setIPAddress}
                            placeholder="IP Address"/>,
                <TextSearch value={deviceId} className="mb-2 mr-2 form-control-sm" onSearch={setDeviceId}
                            placeholder="Device Id"/>,
                <TextSearch value={commonFingerprint} className="mb-2 mr-2 form-control-sm" onSearch={setCommonFingerprint}
                            placeholder="Common fingerprint"/>,
                <TextSearch value={deviceType} className="mb-2 mr-2 form-control-sm" onSearch={setDeviceType}
                            placeholder="Device Type"/>,
                <TextSearch value={impressionToken} className="mb-2 mr-2 form-control-sm" onSearch={setImpressionToken}
                    placeholder="Imp. Token"/>]
            }
            <DatePicker placeholder={'Date from'} classNames={'form-control-sm mb-2'}
                        onDateChange={x => setDateFrom(x)}
                        selectedDay={dateFrom}
            />
            <Label size={'sm'} className={'mb-2 mr-2 ml-2'}> — </Label>
            <DatePicker placeholder={'Date to'} classNames={'form-control-sm mb-2'}
                        onDateChange={x => setDateTo(x)}
                        selectedDay={dateTo}
            />
            <Button color="primary" size="sm" outline={!allColumns} className="mb-2 ml-2" onClick={() => setAllColumns(!allColumns)}>
                <i className="fa fa-table"/> &nbsp;
                All Cols
            </Button>
            <ClearButton className="mb-2 ml-2 btn-sm" onClick={() => {
                [setUserId, setDeviceId, setFormKey, setFormKey, setCellId, setIPAddress, setDateFrom, setDateTo,
                    setCommonFingerprint, setDeviceType, setImpressionToken].forEach(f => f(() => undefined));
                setReload(reload => !reload);
            }}/>
        </div>
        <Table reload={reload} filters = {filters} allColumns={allColumns}/>
    </TableCard>);
}