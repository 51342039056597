import React from "react";
import {PARTNER_LIST} from "lead-generation-common/config/seamless";
import {Input} from "reactstrap";

const partners = [...PARTNER_LIST].sort();
const adaptedPartners = [...PARTNER_LIST].sort().map((it) => {
    if (it === 'Sparmv') return {label: it, value: 'spar'}
    return it
});

const SeamlessPartnerSelect = ({value, onChange, adapted = false, title = 'Partner', classNames}) => {
    const partnerList = adapted ? adaptedPartners : partners;

    return <Input type="select" name="partner" className={classNames}
                  value={value || ''} onChange={e => onChange(e.target.value)}>
        <option value={''}>{title}</option>
        {
            partnerList.map(type => {
                if (typeof type === 'object') return <option value={type.value} key={type.label}>{type.label}</option>
                return <option value={type} key={type}>{type}</option>
            })
        }
    </Input>
};

export default SeamlessPartnerSelect;