import React, { useState, useMemo } from 'react'
import DataTable, { field } from "../../../../generic/DataTable";
import { findAndCountBackup, findAndCountRelease } from "../../../../../service/backups";
import PropTypes from "prop-types";
import { datetime } from "../../../../../lib/format";
import { ViewBackupModal } from "./view/ViewBackupModal";

export default function Table(props) {
    const [selected, setSelected] = useState(undefined);
    const [modal, setModal] = useState(false);
    const [btn, setBtn] = useState('Use Backup');

    const { previousItem, formId } = props;

    const filters = useMemo(() => formId? { "form": formId } : {}, [formId]);

    return (
        <>
            <h3 className="card-title">Current Environment</h3>
            { selected && <ViewBackupModal isOpen={modal} item={selected} previousItem={previousItem} btn={btn} onClose={() => setModal(false)}/> }
            <DataTable
                filters={filters}
                findAndCount={findAndCountBackup}
                fields={[
                    field("Name", true, "name"),
                    field("User", true, "user", res => res.user.username),
                    field("Created", true, "createdAt", res => datetime(res.createdAt))
                ]}
                name={"active_backups"}
                sortField="createdAt"
                sortDir="desc"
                onClick={(res) => {
                    setBtn('Use Backup');
                    setSelected(res);
                    setModal(true);
                }}
            />

            <hr />
            <h3 className="card-title">Previous Environment</h3>
            <DataTable
                filters={filters}
                findAndCount={findAndCountRelease}
                fields={[
                    field("Name", true, "name"),
                    field("User", true, "user", res => res.user.username),
                    field("Created", true, "createdAt", res => datetime(res.createdAt))
                ]}
                name={"active_backups"}
                sortField="createdAt"
                sortDir="desc"
                onClick={(res) => {
                    setBtn('Use Release');
                    setSelected(res);
                    setModal(true);
                }}
            />
        </>
    )
}

Table.propTypes = {
    formId: PropTypes.string, // is required
    previousItem: PropTypes.object
};


