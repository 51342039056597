import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Col, FormGroup, Label, Row} from "reactstrap";
import SMSTemplate from "../../../generic/field/SMSTemplate";
import {Controller} from "react-hook-form";
import {Link} from "react-router-dom";

function PhoneLogin({enabled, form: {register, errors, control}}) {
    return <Fragment>
        <Row>
            <Col md={12} lg={6}>
                <FormGroup>
                    <Label for="project">SMS Template <Link to="/ube/sms">(see all)</Link></Label>

                    <Controller
                        control={control}
                        name="loginByPhone.smsTemplate"
                        defaultValue={null}
                        render={({ onChange, value }) => (
                            <SMSTemplate isInvalid={errors.loginByPhone && !!errors.loginByPhone.smsTemplate}
                                         onChange={onChange} value={value}/>
                        )}
                        rules={enabled && {required: "SMS Template is required"}}
                    />

                    {errors.loginByPhone && errors.loginByPhone.smsTemplate && <div className="invalid-feedback d-block">{errors.loginByPhone.smsTemplate.message}</div>}

                    <small className="form-text text-muted">Consumer receive SMS code to confirm login</small>
                </FormGroup>
            </Col>
        </Row>
    </Fragment>
}

PhoneLogin.propTypes = {
    form: PropTypes.object.isRequired,
    enabled: PropTypes.bool.isRequired
}

export default PhoneLogin;