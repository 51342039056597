import React, {useEffect, useState} from "react";
import DataTable, {field} from "../../generic/DataTable";
import {getData, deleteEntry, downloadLink, deleteMultiple} from "../../../service/export";
import PropTypes from "prop-types";
import {datetime} from "../../../lib/format";
import {Config} from "lead-generation-common";
import {showDangerToast} from "../../../lib/notify";
const statuses = Config.export.status;
const filters = {status: [statuses.Ready, statuses.Processing, statuses.Error]};

const Table = props => {
    const [reload, setReload] = useState(false);
    useEffect(() => setReload(!reload), [props.reload]);

    return <DataTable
        filters={filters}
        onDelete={(res) => deleteEntry(res.id).then(() => setReload(!reload))}
        findAndCount={getData}
        reload={reload}
        fields={[
            field("Entity", true, "entity"),
            field("Progress", true, "progress"),
            field("Rows", true, "rows"),
            field("Current", true, "currentRows"),
            field("Status", true, "status"),
            field("File", true, "filename", res =>
                <a href={downloadLink(res)} rel="noopener noreferrer" target="_blank" download>{res.filename}</a>
            ),
            field("Description", true, "description", res =>
                <small>{res.description}</small>
            ),
            field("User", true, "user.username"),
            field("Date", true, "createdAt", res => datetime(res.createdAt))
        ]}
        onMultipleDelete={ids => deleteMultiple(ids).then(() => setReload(!reload)).catch(showDangerToast)}
        name={"export"}>
    </DataTable>;
}

Table.propTypes = {
    onChange: PropTypes.func,
    reload: PropTypes.any
};

export default Table;