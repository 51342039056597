import {useMemo} from "react";
import {getPercentBy} from "./index";

const useEsbCharts = (chartData = {}, percentBy) => {
    const {processed = 0, error = 0, postponed = 0, processing = 0, removed = 0} = chartData;

    let inProgress = 0, processedRowsPercent, inProgressRowsPercent, errorEsbRowsPercent, removedEsbRowsPercent;

    useMemo(() => {
        const getByTotalPercent = getPercentBy(percentBy);

        inProgress = processing + postponed;

        processedRowsPercent = getByTotalPercent(processed);
        inProgressRowsPercent = getByTotalPercent(inProgress);
        errorEsbRowsPercent = getByTotalPercent(error);
        removedEsbRowsPercent = getByTotalPercent(removed);
    }, [chartData]);

    return {processed, inProgress, errorEsb: error, removedEsb: removed,
        processedRowsPercent, inProgressRowsPercent, errorEsbRowsPercent, removedEsbRowsPercent}
}

export default useEsbCharts;