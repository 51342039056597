import React, {useState} from "react";
import {Col, Nav, NavItem, NavLink, Row, TabContent, TabPane} from "reactstrap";
import Template from "../../Template";
import PropTypes from "prop-types";
import ViewFieldsTable from "./ViewFieldsTable";

export const ViewHead = (props) => {
    const [activeTab, setActiveTab] = useState("Fields");
    const tabList = ["Fields", "Template"];

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    return (
        <Col>
            <Nav tabs>
                {
                    tabList.map(item =>
                        <NavItem key={`field-tabs-${item}`}>
                            <NavLink className={activeTab === item ? "active" : ''} onClick={() => toggle(item)}>
                                {item}
                            </NavLink>
                        </NavItem>)
                }
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="Fields">
                    <Row>
                        <Col>
                            <ViewFieldsTable data={props.data}/>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="Template">
                    <Row>
                        <Col>
                            <Template readOnly={true} state={props.data}/>
                        </Col>
                    </Row>
                </TabPane>
            </TabContent>
        </Col>
    )
};

ViewHead.propTypes = {
    data: PropTypes.object
};