import React from "react";
import {createEntry, getEntry, updateEntry} from "../../service/project";
import * as notify from "../../lib/notify";
import {Redirect} from "react-router-dom";
import {validateProject} from '../../lib/validation';
import LoaderOverlay from "../generic/LoaderOverlay";
import Card from "../generic/Card";
import {Col, Row} from "reactstrap";
import EditorPane from "../generic/EditorPane";
import {apiUrl} from "../../lib/strapi";
import Tags from "../generic/field/Tags";
import {Config} from 'lead-generation-common'

const projectTemplate = `
<!doctype html>
<html>
 <head>
  <title></title>
    <meta name="description" content="">
    <meta name="keywords" content="">
  </head>
  <body>
    %%CHILDREN%%
  </body>
</html>
`;

export default class Edit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            item: {
                key: '',
                label: '',
                htmlTemplate: projectTemplate,
                cssTemplate: '',
                jsTemplate: '',
                tags: []
            },
            redirectToTable: false,
            loading: false
        };
        this.onSave = this.onSave.bind(this);
        this.loadData = this.loadData.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.handleTemplateChange = this.handleTemplateChange.bind(this);
    }

    componentDidMount() {
        if (this.itemId) this.loadData();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const prevId = prevProps.match.params.id;
        const currentId = this.itemId;
        if (prevId !== currentId) this.loadData();
    }

    get itemId() {
        return this.props.match.params.id;
    }

    loadData() {
        if (!this.itemId) this.setState({item: {}, loading: false});
        else {
            this.setState({loading: true});
            getEntry(this.itemId).then(item => {
                this.setState({item, loading: false});
            }).catch(e => {
                notify.showDangerToast("Error loading project id: " + this.itemId);
                this.setState({loading: false, redirectToTable: true});
            })
        }
    }

    onSave(isClose = true) {
        return () => {
            const {item} = this.state;

            const validation = validateProject(item);
            if (validation) return notify.showDangerToast(validation);
            this.setState({loading: true});

            (this.itemId ? updateEntry(this.itemId, item) : createEntry(item)).then(() => {
                this.setState({redirectToTable: isClose});
                notify.showSuccessToast(`Project '${item.label}' ${this.itemId ? 'updated' : 'created'}`);
            }, (e) => {
                if (e.message !== "An internal server error occurred")
                    notify.showDangerToast(e.message || e);
                else
                    notify.showDangerToast("Probably, form with same key already exists")
            }).finally(() => this.setState({loading: false}));
        }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.handleFieldChange(name, value);
    }

    handleFieldChange(name, value) {
        const item = this.state.item;
        item[name] = value;
        this.setState({item});
    }

    handleTemplateChange(mode, template) {
        this.handleFieldChange(mode+'Template', template);
    }

    render() {
        const {redirectToTable, loading, item} = this.state;
        const isUpdate = !!this.itemId;

        if (redirectToTable) return <Redirect push to='/projects'/>;

        return <Card title={isUpdate ? 'Edit project' : 'Create project'}>
            <LoaderOverlay isVisible={loading}>
                <form className="forms-sample">
                    <Row>
                        <Col sm={12} md={6}>
                            <div className="form-group">
                                <label>Label</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name="label"
                                    placeholder="Label"
                                    value={item.label}
                                    onChange={this.handleInputChange}
                                    autoComplete={"false"}
                                />
                            </div>
                        </Col>
                        <Col sm={12} md={6}>
                            <div className="form-group">
                                <label htmlFor="Key">URL Key <small>pages
                                    address: {apiUrl}/web/{item.project && item.project.key}/page-key</small></label>
                                <input
                                    id="Key"
                                    type="key"
                                    className="form-control"
                                    name="key"
                                    placeholder="Key"
                                    value={item.key}
                                    onChange={this.handleInputChange}
                                    autoComplete="false"
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} lg={9}>
                            <div className="form-group">
                                <EditorPane html={item.htmlTemplate} css={item.cssTemplate} js={item.jsTemplate}
                                            onChange={this.handleTemplateChange}/>
                            </div>
                        </Col>
                        <Col md={12} lg={3} className={"jumbotron"}>
                            <h4 className="card-title">Information</h4>
                            <p className="card-description">Parameters available</p>
                            <ul className="list-arrow">
                                <li><b>%%CHILDREN%%</b><br/>page content</li>
                                <li><b>%%API_URL%%</b><br/>server address. {apiUrl}</li>
                            </ul>
                        </Col>
                    </Row>

                    <div className="form-group">
                        <label>Tags</label>
                        <Tags value={item.tags} onChange={v => this.handleFieldChange('tags', v)} options={Config.project.tags}/>
                    </div>

                    {isUpdate && <button className="btn btn-primary mr-2" onClick={this.onSave(false)}
                                         type="button">Update</button>}

                    <button className="btn btn-primary mr-2" onClick={this.onSave()}
                            type="button">{isUpdate ? 'Update, close' : 'Create'}</button>

                    <button className="btn btn-lhtmlTemplateight" onClick={() => this.setState({redirectToTable: true})}
                            type="button">Cancel
                    </button>
                </form>
            </LoaderOverlay>
        </Card>
    }
}