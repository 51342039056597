import React, {useState} from 'react'
import TableCard from "../../generic/TableCard";
import DataTable, {field} from "../../generic/DataTable";
import TextSearch from "../../generic/field/TextSearch";
import ClearButton from "../../generic/field/ClearButton";
import {datetime} from "../../../lib/format";
import {getConsumersData} from "../../../service/consumers";
import {useHistory} from "react-router-dom";
import {Button, ButtonGroup, Input, Label} from "reactstrap";
import ExportFunButton from "../../generic/ExportFunButton";
import {exportData} from "../../../service/consumers";
import DatePicker from "../../generic/field/DatePicker";
import FileLogsFilter from "../FileLogsFilter";
import {DateParam, StringParam, useQueryParam} from "use-query-params";
import {STATUS_STATES} from "../constants";
import SeamlessPartnerSelect from "../../generic/SeamlessPartnerSelect";
import moment from "moment";

const ConsumersDataTable = () => {
    let history = useHistory();
    const [email, setEmail] = useState(undefined);
    const [phone, setPhone] = useState(undefined);
    const [seamlessId, setSeamlessId] = useState(undefined);
    const [spiceId, setSpiceId] = useState(undefined);
    const [clientId, setClientId] = useState(undefined);
    const [fileName, setFilename] = useState(undefined);
    const [source, setSource] = useState(undefined);
    const [fileLogId, setFileLogId] = useQueryParam('fileLogId', StringParam);
    const [statusState, setStatusState] = useQueryParam('statusState', StringParam);
    const [createdAt_gte, setCreatedAtGte] = useQueryParam('createdAt_gte', DateParam);
    const [createdAt_lte, setCreatedAtLte] = useQueryParam('createdAt_lte', DateParam);

    const filters = {
        phone_contains: phone,
        email_contains: email,
        spiceId_contains: spiceId,
        clientId_contains: clientId,
        fileName_contains: fileName,
        source_contains: source,
        _id: seamlessId,
        createdAt_gte: createdAt_gte && moment(createdAt_gte).format("YYYY-MM-DDTHH:mm:ssZ"),
        createdAt_lte: createdAt_lte ? moment(createdAt_lte).clone().add(1, 'day').format("YYYY-MM-DDTHH:mm:ssZ") : undefined,
        fileLogId_eq: fileLogId,
        statusState_eq: statusState,
    };

    const setValOrUndefined = (data, setter) => setter(() => data || undefined)
    return (
        <TableCard title={"Consumers"}>
            <div className="form-inline">
                <TextSearch value={seamlessId} className="mb-2 mr-2" onSearch={data => setValOrUndefined(data, setSeamlessId)} placeholder="Id"/>
                <TextSearch value={email} className="mb-2 mr-2" onSearch={data => setValOrUndefined(data, setEmail)} placeholder="Email"/>
                <TextSearch value={phone} className="mb-2 mr-2" onSearch={data => setValOrUndefined(data, setPhone)} placeholder="Phone"/>
                <TextSearch value={spiceId} className="mb-2 mr-2" onSearch={data => setValOrUndefined(data, setSpiceId)} placeholder="UCRM ID"/>
                <TextSearch value={clientId} className="mb-2 mr-2" onSearch={data => setValOrUndefined(data, setClientId)} placeholder="Client ID"/>
                <TextSearch value={fileName} className="mb-2 mr-2" onSearch={data => setValOrUndefined(data, setFilename)} placeholder="Filename"/>
                <div className="mb-2 mr-2">
                    <SeamlessPartnerSelect value={source} onChange={setSource} title={'Source'} adapted={true} classNames={'h-48'} />
                </div>
                <div className="mb-2 mr-2" >
                    <FileLogsFilter changeFileLogId={setFileLogId} fileLogId={fileLogId} type="accounts" />
                </div>
                <Input type="select" name="statusMessage" className="mb-2 mr-2 h-48"
                       value={statusState || ''} onChange={e => setStatusState(e.target.value !== '' ? e.target.value : undefined)}>
                    <option value={''}>Status state</option>
                    {
                        STATUS_STATES.map(type => <option key={type}>{type}</option>)
                    }
                </Input>
                <DatePicker placeholder={'Created from'} classNames={'form-control mb-2'}
                            onDateChange={(val) => setCreatedAtGte(val)}
                            selectedDay={createdAt_gte}
                />
                <Label className={'mb-2 mr-2 ml-2'}> — </Label>
                <DatePicker placeholder={'Created to'} classNames={'form-control mb-2'}
                            onDateChange={(val) => setCreatedAtLte(val)}
                            selectedDay={createdAt_lte}
                />
                <ClearButton className="mb-2" onClick={() => {
                    [setEmail, setPhone, setSpiceId, setClientId, setSeamlessId, setSource, setFileLogId,
                        setStatusState].forEach(f => f(() => undefined));
                    if (createdAt_gte) setCreatedAtGte(undefined);
                    if (createdAt_lte) setCreatedAtLte(undefined);
                    delete filters.email_contains;
                    delete filters.phone_contains;
                    delete filters.spiceId_contains;
                    delete filters.clientId_contains;
                    delete filters._id;
                    delete filters.source_contains;
                    delete filters.fileName_contains;
                    delete filters.fileLogId_eq;
                    delete filters.statusState_eq
                    delete filters.createdAt_gte
                    delete filters.createdAt_lte
                }}/>
            </div>
            <DataTable
                filters={filters}
                findAndCount={getConsumersData}
                fields={[
                    field("id", true, '_id', res => res._id),
                    field("Email", true, 'email' ),
                    field("Phone", true, "phone"),
                    field("Type", true, "type", res => res.type || 'Не определен' ),
                    field("Status", true, "status.state"),
                    field("Client ID", true, "data.ClientId"),
                    field("UCRM ID", true, "spiceId"),
                    field("Status", true, "statusMessage"),
                    field("Updated at", true, "updatedAt", res => datetime(res.updatedAt)),
                ]}
                sortField="createdAt"
                sortDir="desc"
                name="consumers"
                additionalButtons={
                    (res) => <ButtonGroup>
                        <Button color="info" size="xs" onClick={() => history.push('/seamless/customers/' + res._id)}><i
                        className="fa fa-eye"/></Button>
                        <Button color="success" size="xs" onClick={() => history.push('/seamless/queues/logs?queue=esb_account&entityId=' + res._id)}><i
                            className="fa fa-book"/></Button>
                    </ButtonGroup>
                }
                tableButtons={dataParameters => <ExportFunButton dataParameters={dataParameters} exportFun={exportData}/> }

            />
        </TableCard>
    )
}

export default ConsumersDataTable;
