import React from 'react';
import {useParams} from "react-router";
import Card from "../../../generic/Card";
import {Nav, NavItem, NavLink} from "reactstrap";
import {Route, useHistory, useLocation, Switch} from "react-router-dom";
import Logs from "./Logs";
import Data from "./Data";
import History from "./History";

function Customer() {
    const {customerId} = useParams()
    const location = useLocation();
    const history = useHistory();
    const activeTab = /\/(\w+)\/?$/.exec(location.pathname)[1];

    return (
        <Card title={'Customer: ' + customerId}>
            <Nav tabs>
                <NavItem>
                    <NavLink className={ activeTab === customerId ? "active" : ""}
                             onClick={() => history.push(`/seamless/customers/${customerId}`)}
                             href="#">
                        Customer
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={ activeTab === 'logs' ? "active" : ""}
                             onClick={() => history.push(`/seamless/customers/${customerId}/logs`)}
                             href="#">
                        Queue Logs
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink className={ activeTab === 'history' ? "active" : ""}
                             onClick={() => history.push(`/seamless/customers/${customerId}/history`)}
                             href="#">
                        History of updates
                    </NavLink>
                </NavItem>
            </Nav>
            <Switch>
                <Route path={`/seamless/customers/:customerId`} component={Data} exact/>
                <Route path={`/seamless/customers/:customerId/logs`} component={Logs}/>
                <Route path={`/seamless/customers/:customerId/history`} component={History}/>
            </Switch>
        </Card>
    )
};

export default Customer;