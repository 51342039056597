import React from 'react'
import TableCard from "../../generic/TableCard";
import Table from "./Table";
import {Link, useHistory} from "react-router-dom";
import ProjectInput from "../../generic/field/ProjectInput";
import ClearButton from "../../generic/field/ClearButton";
import {pickBy} from "lodash";
import TextSearch from "../../generic/field/TextSearch";
import {useQueryParam} from "use-query-params";

export default function Index() {

    const [project, setProject] = useQueryParam("project");
    const [label, setLabel] = useQueryParam("label");
    const history = useHistory();

    const filters = pickBy({project, label_contains: label});

    return <TableCard title={"DTE Configs"}>
        <form className="form-inline">
            <Link className="btn btn-primary mb-2 mr-2" to={`/dte/config/edit/`}>
                <i className="fa fa-plus"/>
                &nbsp; Add
            </Link>
            <ProjectInput className="mb-2 mr-2" selectedItemId={project} onSelect={setProject}/>
            <TextSearch value={label} className="mr-2 mb-2 form-control" onSearch={setLabel} placeholder="Search..."/>
            <ClearButton className="mb-2" onClick={() => setProject(null) || setLabel(null)}/>
        </form>
        <Table filters={filters} onChange={res => history.push(`/dte/config/edit/${res.id}`)}/>
    </TableCard>
}