import Edit from "./tabs/Edit";
import React from "react";
import Fields from "./tabs/fields/Index";
import Template from "./tabs/Template";
import Security from "./tabs/Security";
import DumpTable from "./tabs/backups/DumpTable";
import ActiveTable from "./tabs/backups/ActiveTable";
import Integration from "./tabs/integration/Integration";

const tabList = ({state, dispatch, ...rest}) => {
    return (
        [
            {
                name: "Parameters",
                child: <Edit dispatch={dispatch} state={state}/>
            },
            {
                name: "Fields",
                child: <Fields dispatch={dispatch} state={state}/>
            },
            {
                name: "Template",
                child: <Template dispatch={dispatch} state={state}/>
            },
            {
                name: "Security",
                child: <Security dispatch={dispatch} state={state} data={state}/>
            },
            {
                name: "History",
                child: <DumpTable formId={state.id}/>,
                isDisplayed: !!state.id
            },
            {
                name: "Versions",
                child: <ActiveTable formId={state.id} previousItem={state} />,
                isDisplayed: !!state.id
            },
            {
                name: "Integrate",
                child:  <Integration dispatch={dispatch} state={state}/>,
                isDisplayed: state.type === 'integrationForm'
            }
        ]
    )
}

export default tabList;