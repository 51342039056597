import React from 'react'
import Card from "../../generic/Card";
import {Row, Nav, NavItem, NavLink, TabContent, TabPane, Col} from 'reactstrap';
import {StringParam, useQueryParam, withDefault} from 'use-query-params';
import Config from './tabs/Config';
import Project from './tabs/Project';

export default function AntiFraudConfig(props) {
    const [activeTab, setActiveTab] = useQueryParam("activeTab", withDefault(StringParam, "Config"));
    const toggle = tab => { if (activeTab !== tab) setActiveTab(tab) };
    const tabList = [
        {
            name: "Config",
            child: <Config />
        },
        {
            name: "Project",
            child: <Project />
        }
    ]

    return (
        <Card>
            <Row>
                <Col>
                    <Nav tabs>
                        {tabList.map(tab =>
                            <NavItem key={`field-tabs-${tab.name}`}>
                                <NavLink
                                    className={activeTab === tab.name && "active" ? "active" : ""}
                                    onClick={() => toggle(tab.name)}
                                >
                                    {tab.name}
                                </NavLink>
                            </NavItem>
                        )}
                    </Nav>
                    <TabContent activeTab={activeTab}>
                        {tabList.map(tab =>
                            <TabPane tabId={tab.name} key={tab.name}>
                                <Row>
                                    <Col>
                                        {tab.child}
                                    </Col>
                                </Row>
                            </TabPane>
                        )}
                    </TabContent>
                </Col>
            </Row>
        </Card>
    );
};