import React from "react";
import DataTable, {field} from "../../generic/DataTable";
import {findAndCount} from "../../../service/function";
import PropTypes from 'prop-types';
import format from "../../../lib/format";

function Table({onChange, onDelete, reload}) {

    let fields = [
        field("Name", false, "name"),
        field("Type", false, "type"),
        field("IsActive", false, "isActive"),
        field("Created", false, "createdAt", res => format.datetime(res.createdAt)),
        field("Updated", false, "updatedAt", res => format.datetime(res.updatedAt))
    ];

    return (
        <DataTable
            onDelete={onDelete}
            onChange={onChange}
            reload={reload}
            findAndCount={findAndCount}
            fields={fields}
            name={"function"}
        >
        </DataTable>
    );
}


Table.propTypes = {
    onDelete: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    reload: PropTypes.bool
};

export default Table;