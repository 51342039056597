import React, {useState} from "react";
import TableCard from "../../generic/TableCard";
import TextSearch from "../../generic/field/TextSearch";
import ClearButton from "../../generic/field/ClearButton";
import DataTable, {field} from "../../generic/DataTable";
import {basicDelete, basicFindAndCount} from "../../../service/basicApiInteractions";
import {Badge} from "reactstrap";
import EditModal from "./EditModal";
import {showSuccessToast, showDangerToast} from "../../../lib/notify";
import { sendUnloads } from "../../../service/dteStatistics";
import Loader from "../../generic/Loader";

const Alerts = () => {
    const findAndCount = basicFindAndCount('alerts')
    const [email, setEmail] = useState(undefined);
    const [name, setName] = useState(undefined);
    const [isOpened, setOpened] = useState(false);
    const [selectedAlert, setSelectedAlert] = useState({})
    const [reload, setReload] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const triggerModal = () => {
        setReload(reload => !reload)
        if (isOpened) setSelectedAlert(() => {})
        setOpened(isOpened => {return !isOpened})
    }

    const deleteAlert = async (alert) => {
        await basicDelete('alerts', alert.id).then(() => showSuccessToast('Successfully deleted'))
            .catch(showDangerToast)
        setReload(reload => !reload)
    }

    const send = () => {
        setIsLoading(true);
        sendUnloads()
            .then(res => {
                showSuccessToast('Unloads successfully sent');
            })
            .catch(e => {
                showDangerToast('Error sending unloads');
            })
            .finally(() => {
                setIsLoading(false);
            })
    }

    const filters = {
        email_contains: email,
        name_contains: name,
    };

    return (
        <TableCard title={"Alerts"}>
            {isOpened && <EditModal isOpened={isOpened} alert={{...selectedAlert}} triggerModal={triggerModal}/>}
            <div className="form-inline">
                <TextSearch value={email} className="mb-2 mr-2" onSearch={setEmail} placeholder="Email"/>
                <TextSearch value={name} className="mb-2 mr-2" onSearch={setName} placeholder="Name"/>
                <ClearButton className="mb-2" onClick={() => {
                    setEmail(() => null);
                    setName(() => null);
                }}/>
                <button type="button" style={{marginLeft:'10px'}} className={"btn btn-primary mb-2"}
                        onClick={triggerModal}
                >
                    <i className="fa fa-plus"/>
                    &nbsp; Alert Contact
                </button>
                <button type="button" disabled={isLoading} style={{marginLeft:'10px'}} className={"btn btn-primary mb-2"}
                        onClick={send}
                >
                    <i className="fa fa-envelope"/>
                    &nbsp; Send Unloads
                </button>
                {isLoading && <Loader />}
            </div>
            <DataTable
                filters={filters}
                findAndCount={findAndCount}
                fields={[
                    field("ID", true, 'id'),
                    field("Name", true, 'name'),
                    field("Email", true, 'email'),
                    field("Pushbullet Token", true, 'pushbullet'),
                    field('Groups', false, 'groups', res => <div>
                        {res.groups.map((group, id) =>
                            <Badge className={'table_alert-badge'} key={id} color={"success"}>{group}</Badge>)}
                    </div>)
                ]}
                sortField="createdAt"
                sortDir="desc"
                name="alerts"
                onClick={alert => {
                    setSelectedAlert(() => alert)
                    triggerModal()
                }}
                onDelete={deleteAlert}
                reload={reload}
            />
        </TableCard>
    )
}

export { Alerts };