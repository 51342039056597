import {Service} from '../lib/strapi';
import {strapi} from "../lib/auth";

const service = new Service("fraud-configs");
export const getFraudConfig = service.findAndCountServerSide;

export const createEntry = (params) => {
    return strapi.createEntry('fraud-configs', params)
};

export const updateEntry = async (id, params) => {
    return strapi.updateEntry('fraud-configs', id, params);
};
export const deleteEntry = (id) => {
    return strapi.deleteEntry('fraud-configs', id);
};