import React from "react";
import Card from "../generic/Card";
import {changePassword, getUserFromSession} from "../../lib/auth";
import {Input} from "reactstrap";
import * as notify from "../../lib/notify"
import {validatePasswordDifficulty} from "../../lib/validation";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: getUserFromSession(),
            isVisiblePasswordCard: false
        };
    };

    onChangePasswordOpen = () => {
        this.setState({
            isVisiblePasswordCard: !this.state.isVisiblePasswordCard
        })
    };

    onChangePasswordSubmit = (e) => {
        e.preventDefault();
        const {password, confirm} = this.state;
        if(!validatePasswordDifficulty(password)) {
            notify.showDangerToast('Bad password');
            return;
        }
        changePassword(password, confirm).then(() => {
            notify.showSuccessToast("Change success");
            this.setState({
                isVisiblePasswordCard: false
            })

        }).catch(e => {
            notify.showDangerToast(e)
        });

    };

    onCancelExit = (e) => {
        e.preventDefault();
        this.setState({
            isVisiblePasswordCard: false
        })

    };

    render() {
        return (<div>
                <Card>
                    <div className="border-bottom text-center pb-4">
                        <img src="/images/person/1.png" alt="profile"
                             className="img-lg rounded-circle mb-3"/>
                        <div className="mb-3">
                            <h3>{this.state.user.username}</h3>
                            <h5>{this.state.user.role}</h5>
                            <h5>{this.state.user.email}</h5>
                        </div>
                        <div className="d-flex justify-content-center">
                            <button onClick={this.onChangePasswordOpen} className="btn btn-success mr-1">Change
                                Password
                            </button>
                        </div>
                    </div>
                </Card>
                <div className={this.state.isVisiblePasswordCard ? '' : 'd-none'}>
                    <Card>
                        <form className="forms-sample">
                            <div className="form-group">
                                <label htmlFor="exampleInputPassword1">Password</label>
                                <Input className="form-control" id="exampleInputPassword1"
                                       onChange={e => this.setState({password: e.target.value})}
                                       type="password"
                                       name="password"
                                       value={this.state.password}
                                       placeholder="Enter Password"
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="exampleInputConfirmPassword1">Confirm Password</label>
                                <Input type="password" className="form-control" id="exampleInputConfirmPassword1"
                                       onChange={e => this.setState({confirm: e.target.value})}
                                       name="confirm"
                                       value={this.state.confirm}
                                       placeholder="Confirm Password"/>
                            </div>
                            <button onClick={this.onChangePasswordSubmit} className="btn btn-primary mr-2">Submit
                            </button>
                            <button onClick={this.onCancelExit} className="btn btn-light">Cancel</button>
                        </form>
                    </Card>
                </div>
            </div>

        );
    }
}


export default Index;


