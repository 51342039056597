import Strapi from "strapi-sdk-javascript";

export const apiUrl = process.env.REACT_APP_API_URL || "http://localhost:1337";
export const remote = new Strapi(apiUrl);

export class Service {
    constructor(entity, defaultSortField = "createdAt", defaultSortDir = "desc", defaultLimit = 20) {
        this.entity = entity;
        this.defaultSortField = defaultSortField;
        this.defaultSortDir = defaultSortDir;
        this.defaultLimit = defaultLimit;
        this.find = this.find.bind(this);
        this.count = this.count.bind(this);
        this.findAndCount = this.findAndCount.bind(this);
        this.findAndCountServerSide = this.findAndCountServerSide.bind(this);
        this.createEntry = this.createEntry.bind(this);
        this.deleteEntry = this.deleteEntry.bind(this);
        this.updateEntry = this.updateEntry.bind(this);
        this.getEntry = this.getEntry.bind(this);
        this.export = this.export.bind(this);
    }

    find(start = 0, limit = this.defaultLimit, sortField = this.defaultSortField, sortDir = this.defaultSortDir, filters = {}) {

        const withSortAndLimit = Object.assign(filters, {
            "_sort":`${sortField}:${sortDir.toUpperCase()}`,
            "_limit": limit,
            "_start": start
        });

        return remote.getEntries(this.entity, withSortAndLimit)
    }

    count(filters = {}) {
        return remote.getEntryCount(this.entity, filters);
    }

    findAndCount(start = 0, limit = this.defaultLimit, sortField = this.defaultSortField, sortDir = this.defaultSortDir, filters = {}) {
        return Promise.all([
            this.find(start, limit, sortField, sortDir, filters),
            this.count(filters)
        ]).then(([data, total]) => ({data, total}));
    }

    request(method, url, params) {
        return remote.request(method, url, params);
    }

    findAndCountServerSide(start = 0, limit = this.defaultLimit, sortField = this.defaultSortField, sortDir = this.defaultSortDir, filters = {}) {

        const withSortAndLimit = Object.assign(filters, {
            "_sort":`${sortField}:${sortDir.toUpperCase()}`,
            "_limit": limit,
            "_start": start
        });

        return remote.request("get", `/${this.entity}/findAndCount`, {params:withSortAndLimit});
    }

    /**
     * Exports all data by default (start and limit = 0)
     * @param start
     * @param limit
     * @param sortField
     * @param sortDir
     * @param filters
     * @returns {Promise<any>}
     */
    export(start = 0, limit = 0, sortField = this.defaultSortField, sortDir = this.defaultSortDir, filters = {}) {

        const withSort = Object.assign(filters, {
            "_sort": `${sortField}:${sortDir.toUpperCase()}`
        });

        if(limit && limit > 0) {
            withSort._limit = limit;
            withSort._start = start;
        }

        return remote.request("get", `/${this.entity}/export`, {params: withSort});
    }

    deleteEntry(id) {
        return remote.deleteEntry(this.entity, id);
    }

    createEntry(data) {
        return remote.createEntry(this.entity, data);
    }

    updateEntry(id, data) {
        return remote.updateEntry(this.entity, id, data);
    }

    getEntry(id) {
        return remote.getEntry(this.entity, id);
    }

    deleteMultiple(ids) {
        return remote.request("post", `/${this.entity}/deleteMultiple`, {data: {ids}});
    }

}

export default {remote, Service};