import React, {Fragment, useState} from "react";
import TextSearch from "../../../generic/field/TextSearch";
import ClearButton from "../../../generic/field/ClearButton";
import DataTable, {field} from "../../../generic/DataTable";
import {getFileLogsData, exportData} from "../../../../service/file_logs";
import {datetime} from "../../../../lib/format";
import DatePicker from "../../../generic/field/DatePicker";
import {Button, Label, ButtonGroup} from 'reactstrap';
import moment from "moment";
import {useHistory} from "react-router-dom";
import "./index.css";
import {pickBy} from "lodash";
import ModalDetail from "./ModalDetail";
import ExportFunButton from "../../../generic/ExportFunButton";
import SeamlessPartnerSelect from "../../../generic/SeamlessPartnerSelect";
import {DateParam, useQueryParam} from "use-query-params";

const statusToClass = {
    copiedFromSftp: 'info',
    ReadRows: 'primary',
    error: 'danger',
    finished: 'success',
};

const FileLogs = () => {
    let history = useHistory();
    const [name, setName] = useState(undefined);
    const [partner, setPartner] = useState(undefined);
    const [modalOpen, setModalOpen] = useState(false);
    const [itemForModal, setItemForModal] = useState(undefined);
    const [createdAt_gte, setCreatedAtGte] = useQueryParam('createdAt_gte', DateParam);
    const [createdAt_lte, setCreatedAtLte] = useQueryParam('createdAt_lte', DateParam);

    const filters = pickBy({
        createdAt_gte: createdAt_gte && moment(createdAt_gte).format("YYYY-MM-DDTHH:mm:ssZ"),
        createdAt_lte: createdAt_lte && moment(createdAt_lte).clone().add(1, 'day').format("YYYY-MM-DDTHH:mm:ssZ"),
        fileName_contains: name,
        sftpClient_contains: partner,
    });

    const openModalDetail = (data) => {
        setModalOpen(true);
        setItemForModal(data);
    }

    return (
        <Fragment>
            <div className="form-inline mt-2">
                <TextSearch value={name} className="mb-2 mr-2 form-control-sm" onSearch={setName}
                            placeholder='Name'/>
                <SeamlessPartnerSelect value={partner} onChange={setPartner} classNames={'mb-2 mr-2'} />
                <DatePicker placeholder={'Date from'} classNames={'form-control-sm mb-2'}
                            onDateChange={(val) => setCreatedAtGte(val)}
                            selectedDay={createdAt_gte}
                />
                <Label size={'sm'} className={'mb-2 mr-2 ml-2'}> — </Label>
                <DatePicker placeholder={'Date to'} classNames={'form-control-sm mb-2'}
                            onDateChange={(val) => setCreatedAtLte(val)}
                            selectedDay={createdAt_lte}
                />
                <ClearButton className="mb-2 ml-2 btn-sm" onClick={() => {
                    setName(() => undefined);
                    setPartner(undefined);
                    if (createdAt_gte) setCreatedAtGte(undefined);
                    if (createdAt_lte) setCreatedAtLte(undefined);
                }}/>
            </div>
            <DataTable
                filters={filters}
                findAndCount={getFileLogsData}
                fields={[
                    field("Name", true, 'fileName', res => <span title={res.remotePath}>{res.fileName}</span>),
                    field("Partner", true, 'sftpClient', res => <span title={res.sftpClient}>{(res.sftpClient && res.sftpClient.replace("SftpClient", "").replace("SftpOrder", "")) || ""}</span>),
                    field("Status", true, 'status', res => <span
                        className={`mr-1 badge badge-${statusToClass[res.status] || "secondary"}`}>{res.status}</span>),
                    field("Type", true, "type"),
                    field("Data from", true, "dataFrom"),
                    field("Total", false, "rowsAmount"),
                    field("Ok", false, 'correctRowsAmount'),
                    field("Bad", false, 'errorRowsAmount'),
                    field("Unique", false, 'uniqueRowsAmount'),
                    field("Error", false, 'message'),
                    field("Created at", true, "createdAt", res => datetime(res.createdAt)),
                    field("Updated at", true, "updatedAt", res => datetime(res.updatedAt))
                ]}
                sortField="createdAt"
                sortDir="desc"
                name="filelogs"
                additionalButtons={res => <ButtonGroup>
                    <Button color="primary" size="xs"
                            onClick={() => openModalDetail(res)}>
                        <i className="fa fa-eye"/>
                    </Button>
                    <Button color="success" size="xs"
                            onClick={() => history.push('/seamless/queues/logs?fileLogId=' + res._id)}>
                        <i className="fa fa-book"/>
                    </Button>
                    {(res.type === 'orders' || res.type === 'accounts') &&
                        <>
                            <Button color="info" size="xs"
                                onClick={() => history.push(`/seamless/${res.type === 'orders'
                                    ? 'orders' : 'customers'}?fileLogId=` + res._id)}>
                                <i className="fa fa-list"/>
                            </Button>
                            <Button color="secondary" size="xs"
                                onClick={() => history.push(`/seamless/validationlogs${res.type === 'accounts' 
                                    ? '/accounts' : ''}?fileLogId=` + res._id)}>
                                <i className="fa fa-user-shield"/>
                            </Button>
                        </>
                    }
                </ButtonGroup>}
                tableButtons={dataParameters => <ExportFunButton dataParameters={dataParameters} exportFun={exportData}/> }
            />
            <ModalDetail
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                item={itemForModal}
            />
        </Fragment>
    )
}

export default FileLogs;
