import React, {useState} from 'react'
import Table from "./Table";
import TableCard from "../../generic/TableCard";
import CreateOrEdit from "./CreateOrEdit";

export default function AdministrationWhitelist(props) {

    const [popupState, setPopupState] = useState(false);
    const [reload, setReload] = useState(undefined);
    const [editPopupData, setEditPopupData] = useState(undefined);
    return (
        <TableCard title={"IP Whitelist"}>
            <div className={"row justify-content-start ml-2"}>
                <button className="btn btn-primary" onClick={() => {
                    setEditPopupData({});
                    setPopupState(true)
                }}>
                    <i className="fa fa-plus"/>
                    &nbsp; Add
                </button>
            </div>
            <CreateOrEdit data={editPopupData} isOpen={popupState} onSave={() => {
                setPopupState(false);
                setReload(!reload);
            }} onClose={() => {
                setPopupState(false);
            }}/>
            <div className="row">
                <div className={"pt-3 col-lg-8"}>
                    <Table reload={reload} onChange={res => {
                        setEditPopupData(res);
                        setPopupState(true);
                    }}/>
                </div>
                <div className="col-lg-4 jumbotron">
                    <h4 className="card-title">Types</h4>
                    <p className="card-description">There is type <code>Whitelist</code> and type <code>Blacklist</code></p>
                    <ul className="list-arrow">
                        <li>Whitelist - disables request limit for specific IP/CIDR</li>
                        <li>Blacklist - blocks all requests from IP/CIDR address</li>
                    </ul>
                </div>
            </div>
        </TableCard>
    );
};
