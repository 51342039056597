import React, {useEffect} from "react";
import {Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import * as notify from "../../../lib/notify"
import {createEntry, updateEntry} from "../../../service/email-domains-list";
import {useForm} from "react-hook-form";
import PropTypes from "prop-types";
import {showDangerToast} from "../../../lib/notify";

function CreateOrEdit (props) {
    const { isOpen, onClose } = props;

    const isCreate = !props.data || !props.data.id;

    const { register, handleSubmit, reset, errors } = useForm({
        defaultValues: props.data || {}
    });

    useEffect(() => {
        reset(props.data);
    }, [props.data])

    function onSave(data) {
        const {domain, type} = data;
        if(!domain || !type) return notify.showWarningToast('All fields are required!');
        if(props.data && props.data.id) updateEntry(props.data.id, domain, type).then(props.onSave).catch(showDangerToast);
        else createEntry(domain, type).then(props.onSave).catch(showDangerToast);
    }

    return (
        <Modal isOpen={isOpen} toggle={props.onClose}>
            <ModalHeader>{isCreate ? "Create" : "Update"}</ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit(onSave)}>
                    <FormGroup>
                        <Label for="domain">Domain</Label>
                        <input
                            name="domain"
                            placeholder="Enter domain"
                            className={`form-control ${errors.domain ? "is-invalid" : ""}`}
                            ref={register({
                                required: "Domain is required",
                            })}
                        />

                        {errors.domain && <div className="invalid-feedback">{errors.domain.message}</div>}
                    </FormGroup>
                    <FormGroup>
                        <Label className="mt-2">Type</Label>
                        <div className="form-check form-check-flat form-check-primary">
                            <label className="form-check-label">
                                <input type="radio" name="type" value="whitelist" className={`form-check-input ${errors.type ? "is-invalid" : ""}`}
                                       ref={register({required: "Select Type"})}/>
                                Whitelist
                                <i className="input-helper" />
                            </label>
                        </div>
                        <div className="form-check form-check-flat form-check-primary">
                            <label className="form-check-label">
                                <input type="radio" name="type" value="blacklist" className={`form-check-input ${errors.type ? "is-invalid" : ""}`}
                                       ref={register({required: "Select Type"})}/>
                                Blacklist
                                <i className="input-helper" />
                                {errors.type && <div className="invalid-feedback">{errors.type.message}</div>}
                            </label>
                        </div>
                    </FormGroup>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" className="mr-2" onClick={() => {
                    handleSubmit(onSave)()
                }}>Save</Button>
                <Button color="secondary" onClick={onClose}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

CreateOrEdit.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    data: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
}

export default CreateOrEdit
