import React, {useEffect} from "react";
import {Button, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import * as notify from "../../../lib/notify"
import {createEntry, updateEntry} from "../../../service/dteSeller";
import {useForm} from "react-hook-form";
import PropTypes from "prop-types";
import {showDangerToast} from "../../../lib/notify";

function CreateOrEdit(props) {
    const {isOpen, onClose} = props;
    const isCreate = !props.data || !props.data.id;
    
    const {register, handleSubmit, reset, errors} = useForm({
        defaultValues: props.data || {}
    });

    useEffect(() => {
        reset(props.data);
    }, [props.data])

    function onSave(sellerData) {
        const { sellerName, contactId } = sellerData;
        const { dteConfig } = props.params;
        if (!sellerName || !contactId || !dteConfig) return notify.showWarningToast('Seller Name and Contact ID fields are required!');
        if (props.data && props.data.id) 
            updateEntry(props.data.id, sellerData, dteConfig)
                .then(props.onSave)
                .catch(showDangerToast);
        else 
            createEntry(sellerData, dteConfig)
                .then(props.onSave)
                .catch(showDangerToast);
    }

    return (
        <Modal isOpen={isOpen} toggle={props.onClose}>
            <ModalHeader>{isCreate ? "Create" : "Update"}</ModalHeader>
            <ModalBody>
                <form onSubmit={handleSubmit(onSave)}>
                    <FormGroup>
                        <Label for="sellerName">Seller Name*</Label>
                        <input
                            name="sellerName"
                            placeholder="Enter Seller Name"
                            className={`form-control`}
                            ref={register}
                        />
                        {errors.sellerName && <div className="invalid-feedback">{errors.sellerName.message}</div>}
                    </FormGroup>
                    <FormGroup>
                        <Label for="contactId">Contact ID*</Label>
                        <input
                            name="contactId"
                            placeholder="Enter Contact ID"
                            className={`form-control`}
                            ref={register}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="sellerName">First Name</Label>
                        <input
                            name="firstName"
                            placeholder="Enter First Name"
                            className={`form-control`}
                            ref={register}
                        />
                        {errors.sellerName && <div className="invalid-feedback">{errors.sellerName.message}</div>}
                    </FormGroup>
                    <FormGroup>
                        <Label for="sellerName">Middle Name</Label>
                        <input
                            name="middleName"
                            placeholder="Enter Middle Name"
                            className={`form-control`}
                            ref={register}
                        />
                        {errors.sellerName && <div className="invalid-feedback">{errors.sellerName.message}</div>}
                    </FormGroup>
                    <FormGroup>
                        <Label for="sellerName">Last Name</Label>
                        <input
                            name="lastName"
                            placeholder="Enter Last Name"
                            className={`form-control`}
                            ref={register}
                        />
                        {errors.sellerName && <div className="invalid-feedback">{errors.sellerName.message}</div>}
                    </FormGroup>
                    <FormGroup>
                        <Label for="promocodesLimit">Promocodes Limit</Label>
                        <input
                            type="number" 
                            min="0"
                            name="promocodesLimit"
                            placeholder=""
                            className={`form-control`}
                            ref={register}
                        />
                    </FormGroup>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" className="mr-2" onClick={() => {
                    handleSubmit(onSave)()
                }}>Save</Button>
                <Button color="secondary" onClick={onClose}>Cancel</Button>
            </ModalFooter>
        </Modal>
    );
};

CreateOrEdit.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    data: PropTypes.object,
    onSave: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired
}

export default CreateOrEdit
