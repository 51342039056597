import {Service} from '../lib/strapi';
import {strapi} from "../lib/auth";

const service = new Service("smsTemplates");

export const getSMSTemplates = service.findAndCountServerSide;

export const getEntry = service.getEntry;

export const insertNewSMSTemplate = (smsTemplateCode, smsTemplateText, gate, label) => strapi.createEntry('smsTemplates', {
    gate,
    smsTemplateText,
    smsTemplateCode,
    label
});

export const changeTemplateOrType = (id, smsTemplateCode, smsTemplateText, gate, label) => strapi.updateEntry('smsTemplates', id, {
    id,
    smsTemplateText,
    smsTemplateCode,
    gate,
    label
});

export const deleteTemplate = (id) => strapi.deleteEntry('smsTemplates', id);