import {Service} from "../lib/strapi";

export const basicFindAndCount = entity => new Service(entity).findAndCountServerSide;

/**
 * General  Create or Update function. Takes decision depending on existence of <id> field
 * @param entity
 * @param data
 * @return {Promise<object>}
 */
export const basicCreateOrUpdateEntry = (entity, data) => data.id
    ? new Service(entity).updateEntry(data.id, data)
    : new Service(entity).createEntry(data);

export const basicDelete = (entity, id) => new Service(entity).deleteEntry(id)