import React from 'react'
import {Redirect, Route, Switch} from "react-router-dom";
import Config from "./config/Route";
import Reference from "./reference/Index";
import SMS from "./smsTemplate/Index";
import Terms from "./terms/Index";

const Routes = () => <Switch>
    <Redirect exact from="/ube" to="/ube/config"/>
    <Route path="/ube/config" component={Config}/>
    <Route path="/ube/reference" component={Reference}/>
    <Route path="/ube/sms" component={SMS}/>
    <Route path="/ube/terms" component={Terms}/>
</Switch>;

export default Routes;
