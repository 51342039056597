import React, {useState} from "react";
import {createEntry} from "../../../service/dteConfig";
import * as notify from "../../../lib/notify";
import {showDangerToast} from "../../../lib/notify";
import {useHistory} from "react-router-dom";
import LoaderOverlay from "../../generic/LoaderOverlay";
import Card from "../../generic/Card";
import {Button, FormGroup, Label} from "reactstrap";
import {Controller, useForm} from "react-hook-form";
import ProjectInput from "../../generic/field/ProjectInput";

function Create() {
    const history = useHistory();

    const [loading, setLoading] = useState(false);

    const { register, handleSubmit, reset, errors, control } = useForm({});

    function onSave(data) {
        setLoading(true);
        createEntry(data).then(res => {
            notify.showSuccessToast("DTE config created");
            reset({});
            history.push(`/dte/config/edit/${res.id}`);
        }).catch(showDangerToast).finally(() => setLoading(false));
    }

    return <Card title={'Create DTE config'}>
        <LoaderOverlay isVisible={loading}>
            <form onSubmit={handleSubmit(onSave)}>
                <FormGroup>
                    <Label for="project">Project</Label>
                    <Controller
                        control={control}
                        name="project"
                        defaultValue={null}
                        render={({ onChange, value }) => (
                            <ProjectInput inputProps={{className: `form-control ${errors.project ? "is-invalid" : ""}`}}
                                          selectedItemId={value} onSelect={onChange}/>
                        )}
                        rules={{required: "Project is required"}}
                    />

                    {errors.project && <div className="invalid-feedback">{errors.project.message}</div>}
                </FormGroup>
                <FormGroup>
                    <Label for="label">Label</Label>
                    <input
                        name="label"
                        placeholder="Enter label"
                        className={`form-control ${errors.label ? "is-invalid" : ""}`}
                        ref={register({
                            required: "Label is required"
                        })}
                    />

                    {errors.label && <div className="invalid-feedback">{errors.label.message}</div>}
                </FormGroup>
                <FormGroup>
                    <Label for="label">Registration form key</Label>
                    <input
                        name="registrationFormKey"
                        placeholder="Enter form key"
                        className={`form-control ${errors.registrationFormKey ? "is-invalid" : ""}`}
                        ref={register({
                            required: "Form key is required"
                        })}
                    />

                    {errors.registrationFormKey && <div className="invalid-feedback">{errors.registrationFormKey.message}</div>}
                </FormGroup>
                <FormGroup>
                    <Label for="label">Registration form URL</Label>
                    <input
                        name="registrationFormUrl"
                        placeholder="Enter form URL, like: https://qr.marlboro.ru/dte/"
                        className={`form-control ${errors.registrationFormUrl ? "is-invalid" : ""}`}
                        ref={register({
                            required: "Form URL is required. For example https://qr.marlboro.ru/dte/"
                        })}
                    />

                    {errors.registrationFormUrl && <div className="invalid-feedback">{errors.registrationFormUrl.message}</div>}
                </FormGroup>
            </form>
            <Button color="primary" className="mr-2" onClick={() => {
                handleSubmit(onSave)()
            }}>Save</Button>
            <Button color="secondary" onClick={() => history.goBack()}>Cancel</Button>
        </LoaderOverlay>
    </Card>
}

export default Create;