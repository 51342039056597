import {Service} from '../lib/strapi';
import {strapi} from "../lib/auth";

const service = new Service("terms-versions");

export const getEntry = service.getEntry;

export const getTerms = service.findAndCount;

export const getByCode = code => service.find(0, 1, undefined, undefined, {code});

export const deleteTerms = (id) => strapi.deleteEntry('terms-versions', id);