import React from 'react'
import DataTable, {field} from "../../../../../generic/DataTable";
import FieldTypeLabel from "../../fields/FieldTypeLabel";
import * as PropTypes from "prop-types";


export default function ViewFieldsTable(props) {
    const filters = {};
    const fields = props.data.fields;
    return (
        <DataTable
            filters={filters}
            findAndCount={async (start, limit) => ({total: fields.length, data: fields.slice(start, start + limit)})}
            fields={[
                field("Key", false, 'key'),
                field("Name", false, "name"),
                field("Type", false, "type", res => <FieldTypeLabel field={res}/>),
                field("Placeholder", false, "placeholder")
            ]}
            name={"form-fields"}
        />
    )
}

ViewFieldsTable.propTypes = {
    data: PropTypes.object
};
