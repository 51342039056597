import React from "react";
import {getEntry, getFileLogsData} from "../../service/file_logs";
import SearchReference from "../generic/field/SearchReference";

const FileLogsFilter = ({fileLogId, changeFileLogId, type}) => {
    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: '1px solid #f3f3f3',
            height: 48,
            borderRadius: 2,
            minWidth: 210,
            padding: '4px 14px'
        }),
        placeholder: (provided) => ({
            ...provided,
            color: '#aaa7a7',
            fontSize: '14px'
        })
    }

    return <SearchReference
        onSearch={x => getFileLogsData(0, 10, "fileName", "asc", {"fileName_contains": x, type}).then(x => x.data)}
        onChange={x => changeFileLogId(x._id)}
        loadValuePromise={id => getEntry(id)}
        value={fileLogId}
        labelKey={"fileName"}
        customStyles={customStyles}
        placeholder={'Select File Logs...'}
    />

};

export default FileLogsFilter;