import React, {useCallback} from 'react';
import PropTypes from "prop-types";
import ExportButton from "./ExportButton";

const ExportFunButton = ({exportFun, dataParameters}) => {

    const {sortField, sortDir, filters} = dataParameters;

    /**
     * @param {{start, limit, sortField, sortDir, filters}} dataParameters
     * @returns {function(*): Promise<any>}
     */
    const onExport = useCallback((mode) => {
        const limit = mode === ExportButton.Page ? dataParameters.limit : 10000000;
        const start = mode === ExportButton.Page ? dataParameters.start : 0;
        return exportFun(start, limit, sortField, sortDir, filters);
    }, [exportFun, dataParameters]);

    return <ExportButton exportFun={onExport}/>
}

ExportFunButton.propTypes = {
    /**
     * function(mode: string): Promise<{id: string, status: string, filename: string}>
     */
    exportFun: PropTypes.func.isRequired,
    dataParameters: PropTypes.object.isRequired
};

export default ExportFunButton;