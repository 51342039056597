import React from 'react';
import {useParams} from "react-router";
import DataTable, {field} from "../../../generic/DataTable";
import {getChangeHistoryData} from "../../../../service/changeHistories";
import {datetime} from "../../../../lib/format";

function History() {
    const {customerId} = useParams()
    const filters = {
        entityId: customerId,
        entity: 'externalsubmissions'
    }

    return (
        <DataTable findAndCount={getChangeHistoryData}
                   filters={filters}
                   fields={[
                       field("Date", true, 'date', res => datetime(res.createdAt)),
                       field("Source", true, "source")
                   ]}
                   sortField="createdAt"
                   sortDir="desc"
                   name="historychangesextsubmissions"/>
    )
};

export default History;