import React from 'react';
import {DateParam, StringParam, useQueryParam} from "use-query-params";
import {Input, Label} from "reactstrap";
import DatePicker from "../../generic/field/DatePicker";
import ClearButton from "../../generic/field/ClearButton";
import FunnelGraph from "./funelGraph/FunnelGraph";
import OutGraph from "./outGraph/OutGraph";
import SeamlessPartnerSelect from "../../generic/SeamlessPartnerSelect";

const Statistics = ({type, statisticBy, setStatisticBy}) => {
    const [createdAt_gte, setCreatedAtGte] = useQueryParam('createdAt_gte', DateParam);
    const [createdAt_lte, setCreatedAtLte] = useQueryParam('createdAt_lte', DateParam);
    const [partner, setPartner] = useQueryParam('partner', StringParam);

    const clearFilters = () => {
        if (createdAt_gte) setCreatedAtGte(undefined);
        if (createdAt_lte) setCreatedAtLte(undefined);
        if (partner) setPartner(undefined);
    }

    const props = {createdAt_gte, createdAt_lte, partner, type}

    return <>
        <div className='tree-container'>
            <div className="d-flex align-items-center flex-wrap mt-4">
                <div className="mr-2">
                    <Input type="select" name="partner" className="mb-2 mr-2"
                           value={statisticBy} onChange={e => setStatisticBy(e.target.value)}>
                        <option value={'fileLogs'} key={'fileLogs'}>File Logs</option>
                        <option value={'esb'} key={'esb'}>Esb</option>
                    </Input>
                </div>
                <div className="mr-2">
                    <SeamlessPartnerSelect value={partner} onChange={setPartner} adapted={statisticBy === 'esb'} classNames={'mb-2 mr-2'} />
                </div>
                <DatePicker placeholder={'Date from'} classNames={'form-control-sm mb-2'}
                            onDateChange={(val) => setCreatedAtGte(val)}
                            selectedDay={createdAt_gte}
                />
                <Label size={'sm'} className={'mb-2 mr-2 ml-2'}> — </Label>
                <DatePicker placeholder={'Date to'} classNames={'form-control-sm mb-2'}
                            onDateChange={(val) => setCreatedAtLte(val)}
                            selectedDay={createdAt_lte}
                />
                <ClearButton className="mb-2 ml-2 btn-sm" onClick={clearFilters}/>
            </div>
            {statisticBy === 'fileLogs' && <FunnelGraph {...props} />}
            {statisticBy === 'esb' && <OutGraph {...props} />}
        </div>
    </>
};

export default Statistics;