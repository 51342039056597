import React from "react";
import {getUserFromSession} from "../../lib/auth.js";
import {ExpandableLink, MenuLink, SubMenuLink} from "../generic/links";
import {routes} from "../../route/routes";
import PropTypes from 'prop-types';

class Sidebar extends React.Component {

    render() {

        const {username, role} = getUserFromSession();
        return (
            <nav className={"sidebar sidebar-offcanvas " + (this.props.active ? "active" : "")} id="sidebar">
                <ul className="nav">
                    <li className="profile">
                        <div className="profile-wrapper">
                            <img src="/images/person/1.png" alt="profile"/>
                            <div className="profile-details">
                                <p className="name">{username}</p>
                                <small className="designation">{role}</small>
                            </div>
                        </div>
                    </li>

                    {routes.filter(route => !route.access || route.access.includes(role)).map(route => route.children ?
                        <ExpandableLink key={route.to} to={route.to} name={route.title} icon={route.icon} {...this.props}>
                            {
                                route.children.map(subRoute => {
                                        let permitted;
                                        if (subRoute.access) permitted = !!(subRoute.access.find(role => role === getUserFromSession().role));
                                        return permitted ? <SubMenuLink key={subRoute.to}
                                                                       to={subRoute.to} {...this.props}>{subRoute.title}
                                        </SubMenuLink> : <></>
                                }
                                )
                            }
                        </ExpandableLink> :
                        <MenuLink key={route.to} name={route.title} icon={route.icon} to={route.to} isExternal={route.isExternal} {...this.props}/>
                    )}
                </ul>
            </nav>
        );
    }
}

Sidebar.propTypes = {
    active: PropTypes.bool
};

export default Sidebar;
