import React, {useState, useMemo} from 'react'
import DataTable, {field} from "../../../../generic/DataTable";
import {findAndCountDump} from "../../../../../service/backups";
import PropTypes from "prop-types";
import {datetime} from "../../../../../lib/format";
import {ViewBackupModal} from "./view/ViewBackupModal";

export default function Table({formId}) {
    const [selected, setSelected] = useState(undefined);
    const [modal, setModal] = useState(false);

    const filters = useMemo(() => formId? { "form": formId } : {}, [formId]);

    return (
        <>
            {selected && <ViewBackupModal isOpen={modal} item={selected} onClose={() => setModal(false)}/>}
            <DataTable
                filters={filters}
                findAndCount={findAndCountDump}
                fields={[
                    field("User", true, "user", res => res.user && res.user.username),
                    field("Created", true, "createdAt", res => datetime(res.createdAt))
                ]}
                name={"dump_backups"}
                sortField="createdAt"
                sortDir="desc"
                onClick={(res) => {
                    setSelected(res);
                    setModal(true);
                }}
            />
        </>
    )
}

Table.propTypes = {
    formId: PropTypes.string, // is required
};


