import React, {useState, useEffect} from "react";
import LoaderOverlay from "../generic/LoaderOverlay";
import {showDangerToast} from "../../lib/notify";
import Pager from "../generic/Pager";
import PropTypes from "prop-types";
import {getCookie, setCookie} from "../../lib/session";
import makeCancelable from "../../lib/makeCancelable";
import { useHistory } from 'react-router-dom';
import usePrevious from "./hooks/usePrevious";
import _ from 'lodash';
import {number} from "../../lib/format";

const DataList = (props) => {
    const history = useHistory();
    const params = new URLSearchParams(history.location.search);
    const query = (props) => ({
        start: number(params.get(props.name + '_start')),
        limit: number(params.get(props.name + '_limit')) || getCookie(props.name + "-limit")
    })
    const prevProps = usePrevious(props)

    const [state, setState] = useState({
        sortField: props.sortField,
        sortDir: props.sortDir,
        start: query(props).start,
        limit: query(props).limit,
        loading: true
    })

    useEffect(()=>{
        if (!_.isEqual(prevProps, props)) {
            const cancelable = loadDataPromise();
            return () => cancelable.cancel();
        }
    })

    const dataParameters  = () =>{
        const { start, limit } = state;
        const {sortField, sortDir} = props;
        const filters = Object.assign({}, props.filters);
        return {sortField, sortDir, start, limit, filters}
    }

    const loadDataPromise = () => {
        const cancelable = makeCancelable((async () => {
            const {start, limit, filters, sortField, sortDir} = dataParameters;
            return props.findAndCount(start, limit, sortField, sortDir, filters)
        })());
        cancelable
            .promise
            .then(({data, total}) => {
                setState((state) => ({...state, data, total, loading: false}))
            })
            .catch(e => {
                setState((state) => ({...state, loading: false}));
                showDangerToast(e);
            });
        return cancelable;
    }
    const {data} = state;
    const {emptyContent, tableButtons} = props;

    return <LoaderOverlay isVisible={state.loading} className="data-table-content">
        {((!data || data.length === 0) && emptyContent) ? emptyContent :
            (data && data.map(res => props.renderer(res)))
        }
        <div className={"mt-2"}>
            <Pager
                start={state.start}
                total={state.total}
                limit={state.limit}
                onPageChange={start => {
                    params.set(`${props.name}_start`, start)
                    history.push(history.location.pathname + '?' + params.toString())
                    setState((state) => ({...state, start}));
                }}
                onLimitChange={limit => {
                    setCookie(`${props.name}-limit`, limit);
                    params.set(`${props.name}_limit`, limit)
                    history.push(history.location.pathname + '?' + params.toString())
                    setState((state) => ({...state, limit}));
                }}
                canChangeLimit={!!props.name}
            />
            {tableButtons && tableButtons(dataParameters)}
        </div>
    </LoaderOverlay>;
}

DataList.propTypes = {
    filters: PropTypes.object,
    findAndCount: PropTypes.func.isRequired,
    /**
     * function(res: object): JSX
     */
    renderer: PropTypes.func.isRequired,
    sortField: PropTypes.string,
    sortDir: PropTypes.string,
    name: PropTypes.string,
    reload: PropTypes.any,
    emptyContent: PropTypes.node,
    /**
     * function(parameters: {sortField, sortDir, start, limit, filters}): JSX
     */
    tableButtons: PropTypes.func,
};

DataList.defaultProps = {
    filters: {},
    name: ""
};

export default DataList;