import React, {useState, useEffect, Fragment} from "react";
import DataTable, {field} from "../../generic/DataTable";
import {getData, deleteEntry, downloadLink} from "../../../service/file";
import PropTypes from 'prop-types';
import {datetime} from "../../../lib/format";
import CopyLink from "../../generic/CopyLink";
import {pickBy} from "lodash";

function size(res) {
    if(isNaN(res.size)) return "";
    let label = "";
    if(res.size >= 100) label = (Math.round(res.size / 100) / 10) + ' MB';
    else label = Math.round(res.size) + ' KB';
    if(res.width && res.height) label += ` ${res.width}x${res.height}px`
    return label;
}

function Table(props) {
    const [reload, setReload] = useState(false);
    useEffect(() => setReload(!reload), [props.reload]);
    const filters = pickBy({url_contains: props.name});

    return (
        <DataTable
            onDelete={(res) => deleteEntry(res.id).then(() => setReload(!reload))}
            filters={filters}
            reload={reload}
            findAndCount={getData}
            fields={[
                field("Name", true, "name", res => <CopyLink href={downloadLink(res.url)} text={res.name}/>),
                field("Mime", true, "mime"),
                field("Size", true, "size", res => size(res)),
                field("Image", false, null, res => <Fragment>
                    {res.formats && res.formats.thumbnail && res.formats.thumbnail.url &&
                    <a rel="noopener noreferrer"  target="_blank" href={downloadLink(res.url)}>
                        <img src={downloadLink(res.formats.thumbnail.url)} alt="preview"/></a>}
                </Fragment>),
                field("Date", true, "createdAt", res => datetime(res.createdAt))
            ]}
            name={"file"}>
        </DataTable>
    );
}


Table.propTypes = {
    name: PropTypes.string,
    reload: PropTypes.any
};

export default Table;