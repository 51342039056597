import React, {useEffect, useState} from 'react';
import {useParams} from "react-router";
import moment from "moment";
import _ from "lodash";
import {getOrderById} from "../../../../service/orders";
import {Row, Col, Button} from "reactstrap";
import {useHistory} from "react-router-dom";

function Data() {
    const {orderId} = useParams()
    let history = useHistory();
    const [order, setOrder] = useState({});
    useEffect(() => {
        if (orderId)
            getOrderById(orderId).then(res => setOrder(() => res))
    }, [])

    const {_id, __v, data, externalorders, externalsubmission, status, form, ...rest} = order;
    const fullData = {...data, ...rest};

    return (!Object.keys(order)
            ? <></>
            : <>
                <form className="forms-sample">
                    <Row>
                        <Col xs={3}>
                            <div className="form-group">
                                <label htmlFor="Key">Updated</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={moment(order.updatedAt).format("YYYY.MM.DD HH:mm:ss Z")}
                                    autoComplete="false"
                                    disabled={true}
                                />
                            </div>
                        </Col>
                        {
                            externalsubmission && <>
                                <Col xs={3}>
                                    <div className="form-group">
                                        <label htmlFor="Key">External submission ID</label>
                                        <div className={'input-group'}>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name={'extOrders'}
                                                value={externalsubmission._id}
                                                autoComplete="false"
                                                disabled={true}
                                            />
                                            <span className={'input-group-append'}>
                                                <Button color="info" size="xs" onClick={() => history.push('/seamless/customers/' + externalsubmission._id)}><i
                                                    className="fa fa-eye"/></Button>
                                            </span>
                                        </div>

                                    </div>
                                </Col>

                            </>
                        }
                        {
                            status && <Col xs={3}>
                                <div className="form-group">
                                    <label htmlFor="Key">
                                        Status<small>{_.isObject(status) && `[queue: ${status.queue}]`}</small>
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        name={'status'}
                                        value={status.state || status || ''}
                                        autoComplete="false"
                                        disabled={true}
                                    />
                                </div>
                            </Col>
                        }
                        <Col>
                            <div className="form-group">
                                <label htmlFor="form">
                                    Form
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name={'status'}
                                    value={form ? form.key : ''}
                                    autoComplete="false"
                                    disabled={true}
                                />
                            </div>
                        </Col>
                        {
                            !_.isEmpty(fullData) && Object.keys(fullData).map(key => (
                                <Col xs={3}>
                                    <div className="form-group" key={key}>
                                        <label htmlFor="Key">{key}</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name={key}
                                            value={typeof fullData[key] === "object" ? JSON.stringify(fullData[key]) : (fullData[key] || "")}
                                            autoComplete="false"
                                            disabled={true}
                                        />
                                    </div>
                                </Col>
                            ))
                        }
                    </Row>


                </form>
            </>
    )
};

export default Data;