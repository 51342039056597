import React from 'react';
import {Route, Switch} from "react-router-dom";
import Edit from "./Edit";
import Index from "./Index";

const PageRoute = () => <Switch>
    <Route exact path="/anti-fraud/function" component={Index}/>
    <Route path="/anti-fraud/function/edit/:id" component={Edit}/>
    <Route path="/anti-fraud/function/edit" component={Edit}/>
</Switch>;

export default PageRoute;