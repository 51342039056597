import {Service} from "../lib/strapi";

const entity = "functions";

const service = new Service(entity);

export const findAndCount = service.findAndCountServerSide;

export const {deleteEntry, updateEntry, createEntry, getEntry} = service;

export default {findAndCount};