import React, {useState} from 'react'
import TableCard from "../../generic/TableCard";
import Table from "./Table";
import TextSearch from "../../generic/field/TextSearch";
import ClearButton from "../../generic/field/ClearButton";

export default function Index() {

    const [recipient, setRecipient] = useState(undefined);
    const [reload, setReload] = useState(undefined);

    return (<TableCard title={"Verification codes"}>
        <div className="form-inline">
            <TextSearch value={recipient} className="mb-2 mr-2 form-control-sm" onSearch={setRecipient}
                        placeholder="Phone or Email"/>
            <ClearButton className="mb-2 ml-2 btn-sm" onClick={() => {
                setRecipient(() => undefined);
                setReload(reload => !reload)
            }}/>
        </div>
        <Table reload={reload} recipient={recipient}/>
    </TableCard>);
}