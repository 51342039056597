import React from 'react'
import {Route, Switch} from "react-router-dom";
import Edit from "./Edit";
import Create from "./Create";
import Index from "./Index";

const PageRoute = () => <Switch>
    <Route exact path="/ube/config" component={Index}/>
    <Route exact path="/ube/config/edit" component={Create}/>
    <Route exact path="/ube/config/edit/:id" component={Edit}/>
</Switch>;

export default PageRoute;
