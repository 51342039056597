import { strapi } from "../lib/auth";
import {Service} from "../lib/strapi";

const entity = "dte-statistics";

const service = new Service(entity);

export const findAndCount = service.findAndCountServerSide;
export const exportData = service.export;

export const sendUnloads = async () => {
    return await strapi.request("post", `${entity}/unload`, {});
}
export default {findAndCount, exportData};
