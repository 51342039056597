import React, {useEffect, useState, Fragment} from "react";
import {updateEntry, getEntry} from "../../../service/dteConfig";
import * as notify from "../../../lib/notify";
import {showDangerToast} from "../../../lib/notify";
import {Link, useHistory} from "react-router-dom";
import LoaderOverlay from "../../generic/LoaderOverlay";
import Card from "../../generic/Card";
import {Button, FormGroup, Label, Col, Row} from "reactstrap";
import {Controller, useForm} from "react-hook-form";
import ProjectInput from "../../generic/field/ProjectInput";
import Statistics from "./statistics/Table";
import DTESellers from "./Sellers";
import TableCard from "../../generic/TableCard";
import CreateOrEdit from "./CreateOrEdit";
import Upload from "../../input/files/Upload";
import { deleteEntry } from "../../../service/file";
import { upsertEntriesFromFile } from "../../../service/dteSeller";
import Switch from "../../generic/Switch";

function Edit(props) {
    const history = useHistory();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [popupState, setPopupState] = useState(false);
    const [uploadPopupState, setUploadPopupState] = useState(false);
    const [reload, setReload] = useState(undefined);
    const [editPopupData, setEditPopupData] = useState(undefined);

    const { register, handleSubmit, reset, errors, control } = useForm({});

    useEffect(() => {
        loadData();
    }, [props.match.params.id]);

    function loadData() {
        const id = props.match.params.id;
        setLoading(true);
        getEntry(id).then(res => {
            setData(res);
            reset({
                project: res.project && res.project._id,
                label: res.label,
                registrationFormKey: res.registrationFormKey,
                isHostess: res.isHostess
            })
        }).catch(showDangerToast).finally(() => setLoading(false));
    }

    async function upsertSellers(res) {
        const [{id, mime, ext, url}] = res;
        if (mime === 'text/csv' && ext === '.csv') {
            await upsertEntriesFromFile(data.id, { fileUrl: url });
            setReload(!reload);
        } else {
            notify.showDangerToast("File is not in correct format (csv with comma delimiters)");
            await deleteEntry(id);
        }
    }

    function onSave(data) {
        setLoading(true);
        updateEntry(props.match.params.id, data).then(res => {
            notify.showSuccessToast("DTE config updated");
        }).catch(showDangerToast).finally(() => setLoading(false));
    }

    return <Fragment>
        <Card title={'Edit DTE config'}>
            <LoaderOverlay isVisible={loading}>
                <form onSubmit={handleSubmit(onSave)}>
                    <Row>
                        <Col sm={12} md={12} lg={5} xl={3}>
                            <FormGroup>
                                <Label for="project">Project</Label>
                                <Controller
                                    control={control}
                                    name="project"
                                    defaultValue={null}
                                    render={({ onChange, value }) => (
                                        <ProjectInput inputProps={{className: `form-control ${errors.project ? "is-invalid" : ""}`}}
                                                      selectedItemId={value} onSelect={onChange} disabled={true}/>
                                    )}
                                    rules={{required: "Project is required"}}
                                />

                                {errors.project && <div className="invalid-feedback">{errors.project.message}</div>}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={5} xl={3}>
                            <FormGroup>
                                <Label for="label">Label</Label>
                                <input
                                    name="label"
                                    placeholder="Enter label"
                                    className={`form-control ${errors.label ? "is-invalid" : ""}`}
                                    ref={register({
                                        required: "Label is required"
                                    })}
                                />

                                {errors.label && <div className="invalid-feedback">{errors.label.message}</div>}
                            </FormGroup>
                        </Col>
                        <Col sm={12} md={12} lg={5}>
                            <FormGroup>
                                <Label for="label">Registration form key</Label>
                                <input
                                    name="registrationFormKey"
                                    placeholder="Enter form key"
                                    className={`form-control ${errors.registrationFormKey ? "is-invalid" : ""}`}
                                    ref={register({
                                        required: "Form key is required"
                                    })}
                                />

                                {errors.registrationFormKey && <div className="invalid-feedback">{errors.registrationFormKey.message}</div>}
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                Pages:
                                {data.dteIframePage && <Link className="mr-2 ml-2" to={"/pages/edit/" + data.dteIframePage.id}>
                                    <span className="badge badge-pill badge-outline-primary mr-2">1</span>
                                    DTE Iframe Page (Shows QR)
                                </Link>}
                                {data.dteRegistrationPage && <Link className="mr-2" to={"/pages/edit/" + data.dteRegistrationPage.id}>
                                    <span className="badge badge-pill badge-outline-primary mr-2">2</span>
                                    DTE Registration Page (Tracks visits)
                                </Link>}
                                <Row className="mt-3 align-items-center">
                                    <Col xs="auto">
                                        <label>Hostess DTE</label>
                                    </Col>
                                    <Col>   
                                        <Controller
                                            control={control}
                                            name="isHostess"
                                            defaultValue={!!data.isHostess}
                                            render={({ onChange, value }) => (
                                                <Switch onChange={(e) => onChange(e.target.checked)} value={value} />
                                            )}
                                        />
                                    </Col>                               
                                </Row>
                            </FormGroup>
                        </Col>
                    </Row>
                </form>
                <Button color="primary" className="mr-2" onClick={() => {
                    handleSubmit(onSave)()
                }}>Save</Button>
                <Button color="secondary" className="mr-2" onClick={() => history.goBack()}>Cancel</Button>
                
            </LoaderOverlay>
        </Card>
        <TableCard title={"Aggregated statistics"} className="mt-3">
            <Statistics filters={{dteConfig: props.match.params.id}}/>
        </TableCard>
        <TableCard title={"Internal sellers"} className="mt-3">
            <Button color="primary" className="mr-2 mb-3" onClick={() => {
                setEditPopupData({});
                setPopupState(true)
            }}>Add Seller</Button>
            <CreateOrEdit data={editPopupData} params={{dteConfig: props.match.params.id}} isOpen={popupState} onSave={() => {
                setPopupState(false);
                setReload(!reload);
                
            }} onClose={() => {
                setPopupState(false);
            }}/>
            <Upload isOpen={uploadPopupState} onSave={(res) => {
                setUploadPopupState(false);
                upsertSellers(res);
            }} onClose={() => {
                setUploadPopupState(false);
            }}/>
            <button className="btn btn-primary mr-2 mb-3" onClick={() => {
                    setUploadPopupState(true)
                }}>
                    <i className="fa fa-plus"/>
                    &nbsp; Upload
            </button>
            <DTESellers reload={reload} filters={{dteConfig: props.match.params.id}} 
                handleClick={res => {
                    setEditPopupData(res);
                    setPopupState(true);
                }}
            />
        </TableCard>
    </Fragment>
}

export default Edit;