import React from 'react'
import Card from "../../generic/Card";
import {Nav, NavItem, NavLink} from "reactstrap";
import {Route, Switch, useHistory, useLocation} from "react-router-dom";
import Queues from "./Queues";
import Logs from "../../administration/queue/logs";

const SeamlessQueues = () => {
    const location = useLocation();
    const history = useHistory();
    const activeTab = /\/(\w+)\/?$/.exec(location.pathname)[1];

    return <Card title={"Seamless Queues"}>
        <Nav tabs>
            <NavItem>
                <NavLink className={ activeTab === 'queues' ? "active" : ""}
                         onClick={() => history.push(`/seamless/queues`)}
                         href="#">
                    Configuration
                </NavLink>
            </NavItem>
            <NavItem>
                <NavLink className={ activeTab === 'logs' ? "active" : ""}
                         onClick={() => history.push(`/seamless/queues/logs`)}
                         href="#">
                    Logs
                </NavLink>
            </NavItem>
        </Nav>
        <Switch>
            <Route path="/seamless/queues" component={Queues} exact/>
            <Route path="/seamless/queues/logs" component={Logs}/>
        </Switch>
    </Card>
};

export default SeamlessQueues;