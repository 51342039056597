import React from 'react';
import "./Loader.css";
import PropTypes from "prop-types";

class Pagination extends React.Component {

    constructor(props) {
        super(props);
        this.onButtonClick = this.onButtonClick.bind(this);
        this.renderButton = this.renderButton.bind(this);
        this.buttonsVisible = 9;
        this.state = {
            current: props.current
        };
    };

    componentDidUpdate(prevProps) {
        if (this.state.current !== this.props.current) {
            this.setState({
                current: this.props.current
            });
        }
    }

    onButtonClick(e, page) {
        e.preventDefault();
        this.setState({
            current: page
        });
        this.props.onPageChange(page);
    }

    renderButton = page =>
        <li key={"page-"+page} className={`page-item ${this.state.current === page ? "active" : ""}`}>
            <button className="page-link" href="#" onClick={e => this.onButtonClick(e, page)}>{page}</button>
        </li>;


    renderBlank = (number) =>
        <li key={"page-blank-"+number} className="page-item">
            <span className="page-link">..</span>
        </li>;

    render() {
        const {current} = this.state;
        const {total} = this.props;

        const renderButtonAt = i => {
            const delta = Math.floor(this.buttonsVisible / 2);

            if(i === 1 || total <= this.buttonsVisible)  return this.renderButton(i);

            if(i === 2) {
                if (current - delta > 2) return this.renderBlank(i);
                else return this.renderButton(i);
            }

            if(i > 2 && i < this.buttonsVisible - 1) {
                return this.renderButton(
                    Math.min(
                        Math.max(current + i - delta - 1, i),
                        total - (this.buttonsVisible - i)
                    )
                );
            }

            if(i === (this.buttonsVisible - 1)) {
                if(current + delta <= total) return this.renderBlank(i);
                else return this.renderButton(total - 1);
            }

            if(i === this.buttonsVisible) return this.renderButton(total);
        };

        return <nav>
            <ul className="pagination flex-wrap pagination-separated">
                {[...Array(Math.min(this.buttonsVisible, total)).keys()].map(i => renderButtonAt(i + 1))}
            </ul>
        </nav>
    }
}

Pagination.propTypes = {
    current: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired
};

Pagination.defaultProps = {
    current: 1
};

export default Pagination;

