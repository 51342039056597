import React from 'react'

import * as notify from "../../../../lib/notify"
import Button from "reactstrap/es/Button";
import user from "../../../../service/user";
import role from "../../../../service/role";
import {Redirect} from "react-router-dom";
import Loader from "../../../generic/Loader";
import {validatePasswordDifficulty} from "../../../../lib/validation";
import {getUserFromSession} from "../../../../lib/auth";
import {PARTNER_LIST} from "lead-generation-common/config/seamless";

export default class Edit extends React.Component {

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.refreshFields = this.refreshFields.bind(this);
        this.state = {
            id: undefined,
            username: "",
            email: "",
            roleId: undefined,
            partner: "",
            blocked: false,
            password: "",
            confirmPassword: "",
            error: false,
            loading: false,
            roles: undefined,
            currentUser: getUserFromSession()
        };
    };

    componentDidMount() {
        this.loadData(this.props);
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.loadData(nextProps);
    }

    loadData(props) {

        if (!this.state.roles) this.loadRoles();

        const userId = props.match.params.id;
        if (userId) {
            this.setState({loading: true});
            user.read(userId)
                .then(({id, username, email, roleId, blocked, partner}) =>
                    this.setState({id, username, email, roleId, blocked, partner, loading: false}))
                .catch(e => {
                    notify.showDangerToast(e);
                    this.setState({error: true, loading: false});
                });
        }
    }

    loadRoles() {
        role.all().then(roles => this.setState({roles: roles}));
    }

    handleChange(event) {
        this.setState({[event.target.name]: event.target.value});
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    refreshFields() {
        this.setState({
            id: undefined,
            username: "",
            email: "",
            password: "",
            confirmPassword: "",
            roleId: "",
            blocked: false,
            error: false,
            loading: false,
            partner: ""
        });
        this.props.history.push("/administration/user/edit")
    }

    changeUser = (e, returnBack) => {
        e.preventDefault();

        const {id, username, email, password, confirmPassword, blocked, currentUser, roles, partner} = this.state;
        let roleId = this.state.roleId;

        if ((password !== '') && !validatePasswordDifficulty(password)) {
            notify.showDangerToast("Bad password");
            return;
        }

        const isCreate = !id;
        if (currentUser.role === 'User manager') {
            roleId = (roles.find(role => role.name === 'Authenticated'))._id
        }

        if (isCreate) {
            user.create(username, email, password, confirmPassword, roleId, partner)
                .then(() => {
                    notify.showSuccessToast("User created");
                    if (returnBack) this.props.history.push("/administration/user");
                    else this.refreshFields();
                }, (error) => notify.showDangerToast(error));

        } else {
            user.update(id, username, email, password, confirmPassword, roleId, blocked, partner)
                .then(() => {
                    notify.showSuccessToast("User saved");
                    if (returnBack) this.props.history.push("/administration/user");
                }, (error) => notify.showDangerToast(error));
        }
    };

    createButtons() {
        return <div className="col-">
            <Button
                color="primary"
                className="mr-2"
                type="submit"
                onClick={e => this.changeUser(e, true)}
            >
                Create
            </Button>
            <Button
                type="submit"
                color="primary"
                className="mr-2"
                onClick={this.changeUser}
            >
                Create and continue
            </Button>
            <Button
                color="light" onClick={() => {
                (notify.showWarningToast("User creation cancelled"));
                this.props.history.push("/administration/user")
            }}
            >
                Cancel
            </Button>
        </div>
    }

    updateButtons() {
        return <div className="col-">
            <Button
                color="primary"
                className="mr-2"
                type="submit"
                onClick={(e) => this.changeUser(e, true)}
            >
                Save
            </Button>
            <Button
                type="submit"
                color="primary"
                className="mr-2"
                onClick={this.changeUser}
            >
                Save and continue
            </Button>
            <Button
                color="light" onClick={() => {
                (notify.showWarningToast("User editing cancelled"));
                this.props.history.push("/administration/user")
            }}
            >
                Cancel
            </Button>
        </div>
    }

    render() {
        if (this.state.error) return <Redirect push to="/administration/user"/>;
        if (this.state.loading) return <Loader/>;
        const {currentUser} = this.state;
        const isCreate = !this.state.id;
        const isProfileManager = (currentUser.role === 'Profile manager');
        return (
            <div className="card">
                <div className="card-body">
                    <h4 className="card-title"> {isCreate ? "Create User" : "Edit User"}</h4>
                    <form className="forms-sample">
                        <div className="form-group">
                            <label>Username</label>
                            <input
                                type="text"
                                className="form-control"
                                name="username"
                                placeholder="Username"
                                value={this.state.username}
                                onChange={this.handleInputChange}
                                autoComplete={"false"}
                                disabled={isProfileManager}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="InputEmail">Email address</label>
                            <input
                                id="InputEmail"
                                type="email"
                                className="form-control"
                                name="email"
                                placeholder="Email"
                                value={this.state.email}
                                onChange={this.handleInputChange}
                                autoComplete="false"
                                disabled={isProfileManager}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="InputPassword">Password</label>
                            <input
                                id="InputPassword"
                                type="password"
                                className="form-control"
                                name="password"
                                placeholder="Password"
                                value={this.state.password}
                                onChange={this.handleInputChange}
                                autoComplete="false"
                                disabled={isProfileManager}
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="InputConfirmPassword">Confirm Password</label>
                            <input
                                id="InputConfirmPassword"
                                type="password"
                                className="form-control"
                                name="confirmPassword"
                                placeholder="Password"
                                value={this.state.confirmPassword}
                                onChange={this.handleInputChange}
                                autoComplete="false"
                                disabled={isProfileManager}
                            />
                        </div>
                        {currentUser.role !== 'User manager' && <div className="form-group">
                            <label htmlFor="ChooseRole">Choose role</label>
                            <select
                                id="ChooseRole"
                                className="form-control"
                                name="roleId"
                                value={this.state.roleId}
                                onChange={this.handleChange}
                                defaultValue='choose'
                            >
                                <option key={"user-role-none"} value='choose' disabled>Choose role</option>
                                {this.state.roles && this.state.roles.map(role => {
                                    return <option key={"user-role-" + role._id} value={role._id}>{role.name}</option>
                                })
                                }
                            </select>
                        </div>
                        }
                        {this.state.roles && this.state.roleId &&
                        this.state.roles.find((it) => it.id === this.state.roleId).type === 'partner' &&
                        <div className="form-group">
                            <label htmlFor="ChooseRole">Choose partner</label>
                            <select
                                id="ChooseRole"
                                className="form-control"
                                name="partner"
                                value={this.state.partner}
                                onChange={this.handleChange}
                                defaultValue='choose'
                            >
                                <option key={"user-partner-none"} value=''>Choose partner</option>
                                {PARTNER_LIST.map(partner => {
                                    return <option key={"user-partner-" + partner} value={partner}>{partner}</option>
                                })
                                }
                            </select>
                        </div>
                        }
                        {!isCreate ?
                            <div className="form-check">
                                <label className="form-check-label">
                                    <input
                                        type="checkbox"
                                        className="form-check-input"
                                        name="blocked"
                                        checked={this.state.blocked}
                                        onChange={this.handleInputChange}
                                        disabled={isProfileManager}
                                    />
                                    Blocked
                                    <i className="input-helper"/>
                                </label>
                            </div> : <div/>}
                        <div className="row justify-content-between">
                            {isCreate ? this.createButtons() : this.updateButtons()}
                        </div>
                    </form>
                </div>
            </div>
        );
    }
};
