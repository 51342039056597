import React, {useState} from 'react'
import TableCard from "../../generic/TableCard";
import DataTable, {field} from "../../generic/DataTable";
import TextSearch from "../../generic/field/TextSearch";
import ClearButton from "../../generic/field/ClearButton";
import {datetime} from "../../../lib/format";
import {getOrdersData, exportData} from "../../../service/orders";
import ExportFunButton from "../../generic/ExportFunButton";
import {Button, ButtonGroup, Input, Label} from "reactstrap";
import {useHistory} from "react-router-dom";
import FileLogsFilter from "../FileLogsFilter";
import {DateParam, StringParam, useQueryParam} from "use-query-params";
import {EXTERNAL_STATUSES, STATUS_STATES} from "../constants";
import SeamlessPartnerSelect from "../../generic/SeamlessPartnerSelect";
import moment from "moment";
import DatePicker from "../../generic/field/DatePicker";

const OrdersDataTable = () => {
    const [seamlessId, setSeamlessId] = useState(undefined);
    const [orderId, setOrderId] = useState(undefined);
    const [clientId, setClientId] = useState(undefined);
    const [source, setSource] = useState(undefined);
    const [fileName, setFilename] = useState(undefined);
    const [fileLogId, setFileLogId] = useQueryParam('fileLogId', StringParam);
    const [statusState, setStatusState] = useQueryParam('statusState', StringParam);
    const [externalStatus, setExternalStatus] = useQueryParam('externalStatus', StringParam);
    const [createdAt_gte, setCreatedAtGte] = useQueryParam('createdAt_gte', DateParam);
    const [createdAt_lte, setCreatedAtLte] = useQueryParam('createdAt_lte', DateParam);
    const [closedDate_gte, setClosedDateGte] = useQueryParam('closedDate_gte', DateParam);
    const [closedDate_lte, setClosedDateLte] = useQueryParam('closedDate_lte', DateParam);

    let history = useHistory();
    const filters = {
        createdAt_gte: createdAt_gte && moment(createdAt_gte).format("YYYY-MM-DDTHH:mm:ssZ"),
        createdAt_lte: createdAt_lte && moment(createdAt_lte).clone().add(1, 'day').format("YYYY-MM-DDTHH:mm:ssZ"),
        closedDate_gte: closedDate_gte && moment(closedDate_gte).format("YYYY-MM-DD"),
        closedDate_lte: closedDate_lte && moment(closedDate_lte).format("YYYY-MM-DD"),
        clientId_contains: clientId,
        source_contains: source,
        orderId_contains: orderId,
        _id: seamlessId,
        fileName_contains: fileName,
        fileLogId_eq: fileLogId,
        statusState_eq: statusState,
        externalStatus_eq: externalStatus,
    };
    const setValOrUndefined = (data, setter) => setter(() => data || undefined)
    return (
        <TableCard title={"Orders"}>
            <div className="form-inline">
                <TextSearch value={seamlessId} className="mb-2 mr-2" onSearch={data => setValOrUndefined(data, setSeamlessId)} placeholder="Id"/>
                <TextSearch value={orderId} className="mb-2 mr-2" onSearch={data => setValOrUndefined(data, setOrderId)} placeholder="Order Id"/>
                <div className="mb-2 mr-2">
                    <SeamlessPartnerSelect value={source} onChange={setSource} title={'Source'} adapted={true} classNames={'h-48'} />
                </div>
                <TextSearch value={clientId} className="mb-2 mr-2" onSearch={data => setValOrUndefined(data, setClientId)} placeholder="Client Id"/>
                <TextSearch value={fileName} className="mb-2 mr-2" onSearch={data => setValOrUndefined(data, setFilename)} placeholder="Filename"/>
                <div className="mb-2 mr-2" >
                    <FileLogsFilter changeFileLogId={setFileLogId} fileLogId={fileLogId} type="orders" />
                </div>
                <Input type="select" name="statusMessage" className="mb-2 mr-2 h-48"
                       value={statusState || ''} onChange={e => setStatusState(e.target.value !== '' ? e.target.value : undefined)}>
                    <option value={''}>Status state</option>
                    {
                        STATUS_STATES.map(type => <option key={type}>{type}</option>)
                    }
                </Input>
                <Input type="select" name="externalStatus" className="mb-2 mr-2 h-48"
                       value={externalStatus || ''} onChange={e => setExternalStatus(e.target.value !== '' ? e.target.value : undefined)}>
                    <option value={''}>External status</option>
                    {
                        EXTERNAL_STATUSES.map(type => <option key={type}>{type}</option>)
                    }
                </Input>
                <DatePicker placeholder={'Created from'} classNames={'form-control mb-2'}
                            onDateChange={(val) => setCreatedAtGte(val)}
                            selectedDay={createdAt_gte}
                />
                <Label size={'sm'} className={'mb-2 mr-2 ml-2'}> — </Label>
                <DatePicker placeholder={'Created to'} classNames={'form-control mb-2 mr-2'}
                            onDateChange={(val) => setCreatedAtLte(val)}
                            selectedDay={createdAt_lte}
                />
                <DatePicker placeholder={'Closed date from'} classNames={'form-control mb-2'}
                            onDateChange={(val) => setClosedDateGte(val)}
                            selectedDay={closedDate_gte}
                />
                <Label size={'sm'} className={'mb-2 mr-2 ml-2'}> — </Label>
                <DatePicker placeholder={'Closed date to'} classNames={'form-control mb-2 mr-2'}
                            onDateChange={(val) => setClosedDateLte(val)}
                            selectedDay={closedDate_lte}
                />
                <ClearButton className="mb-2" onClick={() => {
                    if (createdAt_gte) setCreatedAtGte(undefined);
                    if (createdAt_lte) setCreatedAtLte(undefined);
                    if (closedDate_gte) setClosedDateGte(undefined);
                    if (closedDate_lte) setClosedDateLte(undefined);
                    [setClientId, setSource, setSeamlessId, setOrderId, setFileLogId, setStatusState, setExternalStatus].forEach(f => f(() => undefined));
                    delete filters._id;
                    delete filters.clientId_contains;
                    delete filters.source_contains;
                    delete filters.orderId_contains;
                    delete filters.fileName_contains;
                    delete filters.fileLogId_eq;
                    delete filters.statusState_eq;
                    delete filters.externalStatus_eq;
                    delete filters.createdAt_gte;
                    delete filters.createdAt_lte;
                    delete filters.closedDate_gte;
                    delete filters.closedDate_lte;
                }}/>
            </div>
            <DataTable
                filters={filters}
                findAndCount={getOrdersData}
                fields={[
                    field("id", true, '_id', res => res._id),
                    field("Order Id", true, 'orderId'),
                    field("Source", true, 'source' ),
                    field("Client ID", true, "clientId"),
                    field("Status", true, "statusState"),
                    field("External status", true, "externalStatus"),
                    field("Updated at", true, "updatedAt", res => datetime(res.updatedAt)),
                ]}
                sortField="createdAt"
                sortDir="desc"
                name="orders"
                additionalButtons={
                    (res) => <ButtonGroup>
                        <Button color="info" size="xs" onClick={() => history.push('/seamless/orders/' + res._id)}><i
                        className="fa fa-eye"/></Button>
                        <Button color="success" size="xs" onClick={() => history.push('/seamless/queues/logs?queue=esb_order&entityId=' + res._id)}><i
                            className="fa fa-book"/></Button>
                    </ButtonGroup>
                }
                tableButtons={dataParameters => <ExportFunButton dataParameters={dataParameters} exportFun={exportData}/> }
            />
        </TableCard>
    )
}

export default OrdersDataTable;
