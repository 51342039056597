import React, {useState, useEffect} from "react";
import DataTable, {field} from "../../generic/DataTable";
import {getEmailDomainsList, deleteEntry, deleteMultiple} from "../../../service/email-domains-list";
import PropTypes from 'prop-types';
import {showDangerToast} from "../../../lib/notify";

function Table(props) {
    const [reload, setReload] = useState(false);
    useEffect(() => setReload(!reload), [props.reload]);

    return (
        <DataTable
            onDelete={(res) => deleteEntry(res.id).then(() => setReload(!reload)).catch(showDangerToast)}
            onChange={(res) => {
                props.onChange(res);
            }}
            reload={reload}
            findAndCount={getEmailDomainsList}
            fields={[
                field("Domain", false, "domain"),
                field("Type", false, "type")
            ]}
            name={"email-domains-list"}
            onMultipleDelete={ids => deleteMultiple(ids).then(() => setReload(!reload)).catch(showDangerToast)}
        >
        </DataTable>
    );
}


Table.propTypes = {
    onChange: PropTypes.func.isRequired,
    reload: PropTypes.any
};

export default Table;