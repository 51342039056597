import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import PropTypes from "prop-types";
import {Field} from "../../generic/DataTable";
import moment from "moment";

class ItemModal extends React.Component {
    render() {
        const {onClose, isOpen, fields, item} = this.props;
        return (
            <Modal isOpen={isOpen} toggle={onClose} className={this.props.className}>
                <ModalHeader>{`Form "${this.props.item.form.label}" item Id "${this.props.item.id}"`}</ModalHeader>
                <ModalBody>
                    <form className="forms-sample">
                        <div className="form-group">
                            <label htmlFor="Key">Created</label>
                            <input
                                type="text"
                                className="form-control"
                                value={moment(item.createdAt).format("YYYY.MM.DD HH:mm:ss Z")}
                                autoComplete="false"
                                disabled={true}
                            />
                        </div>
                        {fields.map(field =>
                            <div className="form-group" key={field.key}>
                                <label htmlFor="Key">{field.name} <small>[{field.key}]</small></label>
                                <input
                                    type="text"
                                    className="form-control"
                                    name={field.key}
                                    value={Field.resolveNested("data." + field.key, item)}
                                    autoComplete="false"
                                    disabled={true}
                                />
                            </div>
                        )}
                    </form>
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={onClose}>Close</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

ItemModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    fields: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
        tableView: PropTypes.bool
    })).isRequired,
    item: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired
};

export default ItemModal;