import React from 'react'
import TableCard from "../../generic/TableCard";
import DataTable, {field} from "../../generic/DataTable";
import {getQueueData} from "../../../service/outbound_queue";

export default class AdministrationOutboundQueue extends React.Component {

    render() {
        return <TableCard title={"Outbound queue"}>
            <DataTable
                findAndCount={getQueueData}
                fields={[
                    field("payload", false, "payload"),
                    field("tries", false, "tries"),
                    field("ack", false, "ack"),
                ]}>
                name={"outbound_queue"}
            </DataTable>
        </TableCard>
    }
};