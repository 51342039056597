import React, {useState} from 'react'
import { useQueryParam, StringParam, DateParam } from 'use-query-params';
import TableCard from "../../generic/TableCard";
import Table from "./Table";
import TextSearch from "../../generic/field/TextSearch";
import ClearButton from "../../generic/field/ClearButton";
import DatePicker from "../../generic/field/DatePicker";
import {Label} from "reactstrap";

export default function Index() {

    const [user, setUser] = useQueryParam('user', StringParam);
    const [entity, setEntity] = useQueryParam('entity', StringParam);
    const [entityId, setEntityId] = useQueryParam('setEntityId', StringParam);
    const [url, setUrl] = useQueryParam('url', StringParam);
    const [dateFrom, setDateFrom] = useQueryParam('dateFrom', DateParam);
    const [dateTo, setDateTo] = useQueryParam('dateTo', DateParam);
    const [reload, setReload] = useState(undefined);

    return (<TableCard title={"Audit journal"}>
        <div className="form-inline">
            <TextSearch value={user} className="mb-2 mr-2 form-control-sm" onSearch={setUser}
                        placeholder="User email"/>
            <TextSearch value={entity} className="mb-2 mr-2 form-control-sm" onSearch={setEntity}
                        placeholder="Entity"/>
            <TextSearch value={entityId} className="mb-2 mr-2 form-control-sm" onSearch={setEntityId}
                        placeholder="Entity Id"/>
            <TextSearch value={url} className="mb-2 mr-2 form-control-sm" onSearch={setUrl}
                        placeholder="URL"/>
            <DatePicker placeholder={'Date from'} classNames={'form-control-sm mb-2'}
                        onDateChange={x => setDateFrom(x)}
                        selectedDay={dateFrom}
            />
            <Label size={'sm'} className={'mb-2 mr-2 ml-2'}> — </Label>
            <DatePicker placeholder={'Date to'} classNames={'form-control-sm mb-2'}
                        onDateChange={x => setDateTo(x)}
                        selectedDay={dateTo}
            />
            <ClearButton className="mb-2 ml-2 btn-sm" onClick={() => {
                [setUser, setEntity, setEntityId, setUrl, setDateFrom, setDateTo].forEach(f => f(() => undefined));
                setReload(reload => !reload)
            }}/>
        </div>
        <Table user={user} entity={entity} entityId={entityId} url={url} dateFrom={dateFrom} dateTo={dateTo} reload={reload}/>
    </TableCard>);
}