import React  from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

function Tags (props) {
    const options = (props.options || []).map(x => ({value: x, label: x}));
    const value = (Array.isArray(props.value) ? props.value : []).map(x => ({value: x, label: x}));

    return <Select isMulti={true} value={value}
                   onChange={x => props.onChange(x.map(v => v.value))}
                   options={options}/>;
}

Tags.propTypes = {
    options: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func.isRequired,
    value: PropTypes.arrayOf(PropTypes.string),
};

export default Tags;