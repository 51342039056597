import React from "react";
import DataTable, {field} from "../../generic/DataTable";
import {findAndCount} from "../../../service/journal";
import PropTypes from "prop-types";
import {datetime} from "../../../lib/format";
import moment from "moment";
import {pickBy} from 'lodash';

const Table = (props) => {
    const {user, url, entity, entityId, dateFrom, dateTo} = props;
    const filters = pickBy({entity, entityId, user_contains: user, url_contains: url, createdAt_gte: dateFrom});
    if(dateTo) filters['createdAt_lte'] = moment(dateTo).clone().endOf('day').toDate();
    return (<DataTable
        filters={filters}
        findAndCount={findAndCount}
        reload={props.reload}
        name="journal"
        fields={[
            field("User", true, "user"),
            field("Action", true, "action"),
            field("URL", true, "url"),
            field("Entity", true, "entity"),
            field("Entity Id", true, "entityId"),
            field("Date", true, "createdAt", res => datetime(res.createdAt)),
        ]}>
    </DataTable>)
};

Table.propTypes = {
    user: PropTypes.string,
    url: PropTypes.string,
    entity: PropTypes.string,
    entityId: PropTypes.string,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    reload: PropTypes.any
};

export default Table;