import React from "react";
import PropTypes from 'prop-types';
import {getEntry, getSMSTemplates} from "../../../service/smsTemplate";
import SearchReference from "./SearchReference";

function SMSTemplate(props) {

    return <SearchReference
        onSearch={x => getSMSTemplates(0, 10, "label", "asc", {"label_contains": x}).then(x => x.data)}
        onChange={x => props.onChange(x.id, x)}
        loadValuePromise={id => getEntry(id)}
        className={"was-validated " + (props.isInvalid && "is-invalid")}
        value={props.value}
    />
}

SMSTemplate.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any,
    isInvalid: PropTypes.bool
};

export default SMSTemplate;