import {Service} from "../lib/strapi";
import {strapi} from "../lib/auth";

const entity = "dte-seller";

const service = new Service(entity);

export const findAndCount = service.findAndCountServerSide;

export const createEntry = (data, dteConfig) => {
  const entry = { ...data, dteConfig };
  return strapi.createEntry('dte-seller', entry);
};



export const updateEntry = async (id, data, dteConfig) => {
  const entry = { ...data, id, dteConfig };
  return strapi.updateEntry('dte-seller', id, entry);
};

export const upsertEntriesFromFile = (id, data) => {
  return service.request('POST', `/dte-seller/${id}/upload`, {data})
}

export const deleteEntry = (entity, id) => strapi.deleteEntry(entity, id);

export const exportData = service.export;
