import React, {Fragment} from 'react'
import PropTypes from "prop-types";
import * as FieldType from "./model/FieldType";
import * as ReferenceType from "./model/ReferenceType";

export default function FieldTypeLabel(props) {
    const fieldType = FieldType.findByKey(props.field.type);
    const referenceType = ReferenceType.findByKey(props.field.referenceType);
    return <Fragment>{fieldType && fieldType.label} {referenceType && <small>[{referenceType.label}]</small>}</Fragment>
}

FieldTypeLabel.propTypes = {
    field: PropTypes.shape({
        type: PropTypes.string,
        referenceType: PropTypes.string
    }).isRequired
};
