import React, {Fragment, useState} from "react";
import TextSearch from "../../../generic/field/TextSearch";
import ClearButton from "../../../generic/field/ClearButton";
import DataTable, {field} from "../../../generic/DataTable";
import ExportFunButton from "../../../generic/ExportFunButton";
import {exportData, getQueuesLogsData} from "../../../../service/queue_logs";
import {datetime} from "../../../../lib/format";
import {Format} from "lead-generation-common";

import DatePicker from "../../../generic/field/DatePicker";
import {Input, Label} from 'reactstrap';
import moment from "moment";
import {StringParam, useQueryParam} from "use-query-params";

const {parseMessage} = Format.queue;

const statusStates = ['processing', 'processed', 'postponed', 'error', 'removed'].sort();

const Index = () => {
    const [entityId, setEntityId] = useQueryParam('entityId', StringParam);
    const [fileLogId, setFileLogId] = useQueryParam('fileLogId', StringParam);
    const [queue, setQueue] = useQueryParam('queue', StringParam);
    const [state, setState] = useQueryParam('state', StringParam);
    const [type, setType] = useState(undefined);
    const [message, setMessage] = useState(undefined);
    const [daysFilter, _setDaysFilter] = useState(
        {
            from: undefined,
            to: undefined
        })
    const setDaysFilter = (type, value) => type === 'from'
        ? _setDaysFilter(filter => ({...filter, from: value}))
        : _setDaysFilter(filter => ({...filter, to: value}))

    const filters = {
        updatedAt_gte: daysFilter.from,
        updatedAt_lte: moment(daysFilter.to).clone().endOf('day').toDate(),
        entityId_contains: entityId,
        fileLogId_contains: fileLogId,
        queue_contains: queue,
        state_eq: state,
        type_contains: type,
        message_contains: message,
    };
    return (
        <Fragment>
            <div className="form-inline mt-2">
                <TextSearch value={type} className="mb-2 mr-2 form-control-sm" onSearch={setType} placeholder="Type"/>
                <TextSearch value={queue} className="mb-2 mr-2 form-control-sm" onSearch={setQueue}
                            placeholder="Queue"/>
                <TextSearch value={message} className="mb-2 mr-2 form-control-sm" onSearch={setMessage}
                            placeholder="Message / Error"/>
                <TextSearch value={entityId} className="mb-2 mr-2 form-control-sm" onSearch={setEntityId}
                            placeholder="Entity id"/>

                <Input type="select" name="state" className="mb-2 mr-2"
                       value={state || ''} onChange={e => setState(e.target.value !== '' ? e.target.value : undefined)}>
                    <option value={''}>State</option>
                    {
                        statusStates.map(type => <option key={type}>{type}</option>)
                    }
                </Input>

                <TextSearch value={fileLogId} className="mb-2 mr-2 form-control-sm" onSearch={setFileLogId}
                            placeholder="File Log Id"/>

                <DatePicker placeholder={'Updated from'} classNames={'form-control-sm mb-2'}
                            onDateChange={(val) => setDaysFilter('from', val)}
                            selectedDay={daysFilter.from}
                />
                <Label size={'sm'} className={'mb-2 mr-2 ml-2'}> — </Label>
                <DatePicker placeholder={'Updated to'} classNames={'form-control-sm mb-2'}
                            onDateChange={(val) => setDaysFilter('to', val)}
                            selectedDay={daysFilter.to}
                />
                <ClearButton className="mb-2 ml-2 btn-sm" onClick={() => {
                    setEntityId(() => undefined);
                    delete filters.entityId_contains;
                    setQueue(() => undefined);
                    delete filters.queue_contains;
                    setState(() => undefined);
                    delete filters.state_eq;
                    setType(() => undefined);
                    delete filters.type_contains;
                    setMessage(() => undefined);
                    delete filters.message_contains;
                    setDaysFilter('from', moment().subtract(5, 'day').toDate())
                    setDaysFilter('to', moment().toDate())
                    setFileLogId(() => undefined)
                    delete filters.fileLogId_contains
                }}/>
            </div>
            <DataTable
                filters={filters}
                findAndCount={getQueuesLogsData}
                fields={[
                    field("Log Type", true, 'type'),
                    field("Queue Name", true, "queue"),
                    field("Message/Error", false, 'message', (res) => parseMessage(res.message).error || parseMessage(res.message).message),
                    field("Entity id", true, "entityId"),
                    field("Submission state", true, 'state'),
                    field("Tries", true, 'tries', (res) =>
                        res.tries ? res.tries : 'no-tries'),
                    field("Updated at", true, "updatedAt", res => datetime(res.updatedAt)),
                    field("Created at", true, "createdAt", res => datetime(res.createdAt)),
                ]}
                sortField="updatedAt"
                sortDir="desc"
                name="queueslogs"
                tableButtons={dataParameters => <ExportFunButton dataParameters={dataParameters} exportFun={exportData}/> }
            />
        </Fragment>
    )
}

export default Index;