import React from "react";
import {Col} from "reactstrap";
import './badge.css'
import PropTypes from "prop-types";

const Badge = ({group, alert, setAlert, className, ...rest}) => {
    const toggleBadge = () => {
        if ((alert.groups || []).includes(group.label)){
            setAlert(alert => ({
                ...alert,
                groups: [...(alert.groups.filter(g => g !== group.label))]
            }))
        }
        else {
            setAlert(alert => ({
                ...alert,
                groups: [group.label, ...(alert.groups || [])]
            }))
        }
    }
    return (
        <Col style={{paddingTop: '0px !important'}}>
            <div className="card-body p-3">
                <div className="media">
                    <i className="fas fa-check-double icon-sm text-success align-self-center mr-3"/>
                    <div className="media-body">
                        <div style={{maxWidth: 'fit-content'}} onClick={toggleBadge}
                             className={
                                 'badge badge-pill badge' + (!(alert.groups || []).includes(group.label)
                                     ? '-outline'
                                     : '')
                                 + `-success${className ? ' ' + className : ''}`
                             }
                        >
                            {group.label}
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    )
}

export default Badge;

Badge.propTypes = {
    group: PropTypes.object.isRequired,
    alert: PropTypes.object.isRequired,
    setAlert: PropTypes.func.isRequired
}