import React from 'react'
import {Route, Switch,} from 'react-router-dom';
import Index from "./Index";
import EditHead from "./edit/EditHead"
import View from "./view/Index";


export default class IndexRoute extends React.Component {
    render() {
        return (<Switch>
            <Route exact path="/forms" component={Index}/>
            <Route path="/forms/edit/:id" component={EditHead}/>
            <Route path="/forms/edit" component={EditHead}/>
            <Route path="/forms/view/:id" component={View}/>
        </Switch>);
    }
};

