import {Metro, City, Gender, Other, DayFirst, MonthFirst, YearFirst} from "./ReferenceType";

class FieldType {
    /**
     *
     * @param {string} key
     * @param {string} label
     * @param {ReferenceType[]} [referenceTypes]
     */
    constructor(key, label, referenceTypes) {
        this.key = key;
        this.label = label;
        this.referenceTypes = referenceTypes || [];
    }
}

export const Text = new FieldType("textfield", "Text"),
    Number = new FieldType("number", "Number"),
    Email = new FieldType("email", "Email"),
    Phone = new FieldType("phoneNumber", "Phone"),
    Radio = new FieldType("radio", "Radio", [Gender, Other]),
    Checkbox = new FieldType("checkbox", "Checkbox"),
    Select = new FieldType("select", "Select", [Metro, City, Gender]),
    Date = new FieldType('date', 'Date', [DayFirst, MonthFirst, YearFirst]),
    DateTime = new FieldType('dateTime', 'ISO DateTime'),
    File = new FieldType('file', 'File - { base64: string, name: string }')

/**
 * All field types
 * @type {FieldType[]}
 */
export const All = [Text, Number, Email, Phone, Radio, Checkbox, Select, Date, DateTime, File];

/**
 * Finds field type object by key
 * @param {string} key
 * @returns {FieldType}
 */
export const findByKey = key => key && All.find(x => x.key === key);

/**
 * Safely checks if FieldType exists and has references
 * @param {string} key
 * @returns {boolean}
 */
export const hasReferences = key => !!(key && findByKey(key) && findByKey(key).referenceTypes.length > 0);
