import React, {useState} from 'react'
import Table from "./Table";
import {Button} from "reactstrap";
import Edit from "./Edit";
import * as PropTypes from "prop-types";

export default function Fields({state, dispatch, ...rest}) {
    const [edit, setEdit] = useState([false, undefined]);
    return (
        <div>
            <h4 className="card-title"> Fields </h4>
            <form className="form-inline mb-2">
                {!edit[0] ?
                    <Button className="btn btn-primary" onClick={() => setEdit([true, undefined])}>
                        <i className="fa fa-plus"/>
                        &nbsp; Add field
                    </Button> :
                    <Button className="btn btn-primary" onClick={() => setEdit([false, undefined])}>
                        <i className="fa fa-plus"/>
                        &nbsp; Show fields
                    </Button>
                }
            </form>
            <div style={{minHeight: "400px"}}>
                {!edit[0] ? <Table data={state} setEdit={setEdit}/> :
                    <Edit
                        state={state}
                        dispatch={dispatch}
                        fieldData={edit[1]}
                        setEdit={setEdit}
                    />
                }
            </div>
        </div>
    )
}

Fields.propTypes = {
    dispatch: PropTypes.func.isRequired,
    state: PropTypes.object.isRequired
};
