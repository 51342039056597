import React, {useEffect, useState, Fragment} from 'react'
import DataTable, {field} from "../../generic/DataTable";
import {findAndCount, deleteEntry, exportData} from "../../../service/dteSeller";
import { pickBy } from 'lodash';
import PropTypes from "prop-types";
import ExportFunButton from '../../generic/ExportFunButton';
import ButtonWithConfirm from '../../generic/ButtonWithConfirm';
import '../../generic/DataTable.css';
import { useQueryParam, StringParam } from 'use-query-params';
import TextSearch from '../../generic/field/TextSearch';
import ClearButton from '../../generic/field/ClearButton';

function Table(props) {
    const [reload, setReload] = useState(false);
    const [boundUserId, setBoundUserId] = useQueryParam('boundUserId', StringParam);
    const [sellerName, setSellerName] = useQueryParam('sellerName', StringParam);
    const [sellerContactId, setSellerContactId] = useQueryParam('sellerContactId', StringParam);

    useEffect(() => setReload(!reload), [props.reload]);
    const filters = pickBy({ ...props.filters, sellerName, contactId: sellerContactId });

    if(boundUserId) filters['userIds_contains'] = boundUserId;

    const userIdDeleteHandler = (res) => {
        return deleteEntry('dte-seller', res).then(() => setReload(!reload))
    }

    return <Fragment>
        <div className="form-inline">
            <TextSearch value={sellerName} className="mb-2 mr-2 form-control-sm" onSearch={setSellerName}
                        placeholder="Seller Name"/>

            <TextSearch value={sellerContactId} className="mb-2 mr-2 form-control-sm" onSearch={setSellerContactId}
                        placeholder="Seller Contact Id"/>

            <TextSearch value={boundUserId} className="mb-2 mr-2 form-control-sm" onSearch={setBoundUserId}
                        placeholder="User Id"/>

            <ClearButton className="mb-2 ml-2 btn-sm" onClick={() => {
                [setBoundUserId, setSellerName, setSellerContactId].forEach(f => f(() => undefined));
                setReload(reload => !reload);
            }}/>
        </div>
        <DataTable
            reload={reload}
            filters={filters}
            tableButtons={dataParameters => <ExportFunButton dataParameters={dataParameters} exportFun={exportData}/> }
            findAndCount={findAndCount}
            fields={[
                field("Seller Name", true, "sellerName"),
                field("First Name", true, "firstName"),
                field("Middle Name", true, "middleName"),
                field("Last Name", true, "lastName"),
                field("Contact Id", true, "contactId"),
                field("Link (Iframe)", true, "link"),
                field("Link (QR)", true, "linkQR"),
                field("Promocode", true, "promocode"),
                field("Promocodes Limit", true, "promocodesLimit"),
                field("UserIds", true, "userIds", res => (
                    res.userIds && res.userIds.map((userId, index) => 
                        <div className="row-additional__group" key={index}>
                            <span className='row-additional__item'>{userId}</span>
                            <ButtonWithConfirm 
                                onClick={() => userIdDeleteHandler(userId)}
                                className="btn-xs"
                                modalButtonText="Delete"
                                color="danger"
                                modalText="Do you want to delete?"
                            >
                                <i className="fa fa-trash"/>
                            </ButtonWithConfirm>
                        </div>
                    )
                )),
            ]}
            name={"dte-seller"}
            onChange={props.handleClick}
            onDelete={(res) => deleteEntry('dte-seller', res.id).then(() => setReload(!reload))}
        />
    </Fragment>
}

Table.propTypes = {
    filters: PropTypes.object.isRequired
};

export default Table;
