import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {Col, Row} from "reactstrap";

function Communication({enabled, form: {register, errors, control}}) {
    return <Fragment>
        <Row>
            <Col md={12} lg={6}>

            </Col>
        </Row>
    </Fragment>
}

Communication.propTypes = {
    form: PropTypes.object.isRequired,
    enabled: PropTypes.bool.isRequired
}

export default Communication;